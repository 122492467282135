<template>
	<div id="five9-settings">

		<div class="column-format gap-3">
			<div><img class="five9-logo" alt="Five9 logo" src="/images/vendors/five9-logo.png"></div>
			<v-text-field label="Username" v-model="five9Settings.username" density="compact" variant="outlined" hide-details persistent-placeholder></v-text-field>
			<v-text-field label="Password" v-model="five9Settings.password" type="password" density="compact" variant="outlined" hide-details persistent-placeholder></v-text-field>
			<div v-if="five9Settings.connected" class="column-format gap-3">
				<v-autocomplete :items="mappingFields" label="Reserved Caller ID Field" v-model="five9Settings.callerIdFieldName" item-value="columnId" item-title="columnName"  density="compact" variant="outlined" hide-details persistent-placeholder></v-autocomplete>
				<v-autocomplete :items="mappingFields" label="Reserved Unique ID Field" v-model="five9Settings.uniqueIdFieldName" item-value="columnId" item-title="columnName"  density="compact" variant="outlined" hide-details persistent-placeholder></v-autocomplete>
			</div>
			<div v-else class="font-gray_70">
				Please set username and password to continue.
			</div>
		</div>
	</div>
</template>

<script>
import {defineComponent} from 'vue';
import DialerService from "@/modules/dialers/DialerService";
import Five9Service from "@/modules/dialers/five9/Five9Service";

export default defineComponent({
	name: 'Five9Settings',

	props: ['workspace'],

	components: {},

	data: function () {
		return {
			dialerService: new DialerService(),
			five9Service: new Five9Service(),
			five9Settings: {... this.workspace.dialerProviderSettings},
			mappingFields: [],
		};
	},

	mounted() {
		this.checkSettings();
	},

	beforeUnmount() {
	},

	methods: {
		checkSettings: function(){
			if(this.five9Settings.connected){
				this.getMappingFields();
			}
		},

		async save(){
			if(this.five9Settings.connected){
				if(!this.five9Settings.callerIdFieldName){
					this.$store.commit('error','Reserved Caller ID mapping is required');
					return false;
				}else if(!this.five9Settings.uniqueIdFieldName){
					this.$store.commit('error','Reserved Unique ID mapping is required');
					return false;
				}else if(this.five9Settings.uniqueIdFieldName === this.five9Settings.callerIdFieldName){
					this.$store.commit('error','Reserved Caller ID and Unique ID cannot be mapped to the same field.');
					return false;
				}
			}

			let result = await this.five9Service.connectFive9(this.workspace.id,this.five9Settings);
			return result.data;
		},

		async getMappingFields(){
			let result = await this.dialerService.getMappingFields(this.workspace.id, false);
			this.mappingFields.replace(result.data);
		}
	},

	computed: {},

	watch: {
		workspace: function(val){
			this.five9Settings = {... val.dialerProviderSettings};
			this.checkSettings();
		}
	}
});
</script>

<style scoped lang="scss">
	#five9-settings {
		text-align: left;
		.five9-logo {
			max-width: 80px;
		}
	}
</style>
