<template>
	<div class="column-format gap-3">
		<v-select
			v-model="item.workflowId"
			:items="computedWorkflowList"
			item-value="id"
			item-title="label"
			hide-details
			density="compact"
			variant="outlined"
			placeholder="Workflow"
			@update:modelValue="updated"
		></v-select>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import WorkflowService from "@/modules/workflow/WorkflowService";

	export default defineComponent({
		name: 'WorkflowTransfer',

		props: ['node','workflowId'],

		components: {

		},

		data: function () {
			return {
				item: { ...this.node },
				workflowService: new WorkflowService(),
				workflows: [],
			};
		},

		mounted() {
			this.getWorkflows();
		},

		beforeUnmount() {},

		methods: {
			getWorkflows: function(){
				this.workflowService.getWorkflows(this.workspaceId).then((res) => {
					this.workflows.replace(res.data.filter(w => w.id !== this.workflowId));
				})
			},

			updated: function () {
				if(this.item.workflowId){
					let workflow = this.workflows.find(c => c.id === this.item.workflowId);
					this.item.label = workflow.name;
				}else{
					this.item.label = '';
				}
				this.$emit('updated', this.item);
			},
		},

		computed: {
			workspaceId: function(){
				return this.$store.state.selectedWorkspace;
			},

			computedWorkflowList: function(){
				let result = [];
				this.workflows.forEach(w => {
					result.push({
						id: w.id,
						label: w.name
					})
				});
				return result;
			}
		},
	});
</script>

<style scoped lang="scss"></style>
