<template>
	<div>
		<v-data-table id="profile-table" :items="brandedCallingProfiles" :headers="headers" :items-per-page="-1" @click:row="rowClick">
		</v-data-table>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import BrandedCallingService from "@/modules/watchTower/BrandedCallingService";
	import BrandedCallingProfileDetail from "@/modules/watchTower/BrandedCallingProfileDetail";

	export default defineComponent({
		name: 'BrandedCallingList',

		props: [],

		components: {},

		data: function () {
			return {
				brandedCallingService: new BrandedCallingService(),
				brandedCallingProfiles: [],
				headers: [
					{ title: 'Label', key: 'label', align: 'start' },
					{ title: 'Legal name', key: 'legalName', align: 'start' },
					{ title: 'Calling purpose', key: 'callPurpose', align: 'start' }
				]
			};
		},

		mounted() {
			this.getBrandedCallingList();
		},

		beforeUnmount() {},

		methods: {
			async addNew(){
				await this.editBrandedCallingProfile();
			},

			async rowClick(event,row){
				await this.editBrandedCallingProfile(row.item.id);
			},

			async editBrandedCallingProfile(id = null){
				let binding = {
					id:id
				}

				this.$store.state.globalModalController.openModal(BrandedCallingProfileDetail,binding).then((profile) => {
					if(!profile){
						return;
					}

					let ix = this.brandedCallingProfiles.findIndex(f => f.id === profile.id);

					if(profile.deleted && ix > -1){
						this.brandedCallingProfiles.splice(ix,1);
					}else if(ix > -1){
						this.brandedCallingProfiles.splice(ix,1, profile)
					}else{
						this.brandedCallingProfiles.push(profile);
					}
				})
			},

			async getBrandedCallingList(){
				let result = await this.brandedCallingService.getBrandedCallingProfileList(this.workspaceId);
				this.brandedCallingProfiles.replace(result.data);
			}
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#profile-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
