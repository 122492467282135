<template>
	<div class="column-format gap-3">
		<v-text-field
			v-model="item.label"
			variant="outlined"
			density="compact"
			hide-details
			persistent-placeholder
			label="Label"
			@update:modelValue="updated()"
		></v-text-field>
		<div class="row-format gap-2 align-center">
			<v-text-field
					style="flex: 1 1 0"
					v-model="item.maxCalls"
					numeric
					variant="outlined"
					density="compact"
					hide-details
					persistent-placeholder
					label="Max calls"
					@update:modelValue="updated()"
			></v-text-field>
			<v-text-field
					style="flex: 1 1 0"
					v-model="item.inDays"
					numeric
					variant="outlined"
					density="compact"
					hide-details
					persistent-placeholder
					label="In days"
					@update:modelValue="updated()"
			></v-text-field>
			<v-text-field
					style="flex: 1 1 0"
					v-model="item.suppressForDays"
					numeric
					variant="outlined"
					density="compact"
					hide-details
					persistent-placeholder
					label="Suppress for (days)"
					@update:modelValue="updated()"
			></v-text-field>
		</div>

	</div>
</template>

<script>
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'Velocity',

		props: ['node'],

		components: {},

		data: function () {
			return {
				item: { ...this.node },
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			updated: function () {
				this.$emit('updated', this.item);
			},
		},

		computed: {},
	});
</script>

<style scoped lang="scss"></style>
