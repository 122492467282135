export const PERMISSION_STATES = {
	UNKNOWN: 0,
	GRANTED: 1,
	DENIED: 2,
};

export async function askForPushPermission() {
	// https://developers.google.com/web/fundamentals/push-notifications/subscribing-a-user
	return new Promise((resolve, reject) => {
		const permissionResult = Notification.requestPermission((result) => {
			resolve(result);
		});

		if (permissionResult) {
			permissionResult.then(resolve, reject);
		}
	}).then((permissionResult) => {
		if (permissionResult !== 'granted') {
			return PERMISSION_STATES.DENIED;
		}
		return PERMISSION_STATES.GRANTED;
	});
}

export async function getServiceWorkerSubscription() {
	return navigator.serviceWorker.register('/sw.js').then((registration) => {
		console.log('Service worker registered! Waiting for it to become active...');
		const serviceWorker = registration.installing || registration.waiting || registration.active;
		let whenRegistrationActive = Promise.resolve(registration);
		if (!registration.active || registration.active.state !== 'activated') {
			whenRegistrationActive = new Promise((resolve) => {
				serviceWorker.addEventListener('statechange', (e) => {
					if (e.target.state === 'activated') {
						resolve(registration);
					}
				});
			});
		}
		return whenRegistrationActive;
	});
}

/**
 * urlBase64ToUint8Array
 *
 * @param {string} base64String a public vapid key
 */
export function urlB64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; i += 1) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}


export async function subscribeUserToPush() {
	return getServiceWorkerSubscription().then((registration) => {
		console.log('Service worker active! Ready to go.');

		const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC;

		const subscribeOptions = {
			userVisibleOnly: true,
			applicationServerKey: urlB64ToUint8Array(vapidPublicKey),
		};

		return registration.pushManager.subscribe(subscribeOptions);
	});
}

export async function getExistingPushSubscription() {
	const registration = await getServiceWorkerSubscription();
	const existingPushSubscription = await registration.pushManager.getSubscription();

	return existingPushSubscription;
}

export async function unsubscribeUserToPush() {
	const pushSubscription = await getExistingPushSubscription();
	const result = await pushSubscription.unsubscribe();
	return result;
}
