<template>
	<div>
		<v-data-table id="rnd-connection-table" :items="rndConnections" :headers="headers" :items-per-page="-1" @click:row="rowClick">
			<template v-slot:[`item.tokenValid`]="{ item }">
				{{item.tokenValid || item.refreshTokenValid ? 'OK' : 'Expired!'}}
			</template>
			<template v-slot:[`item.refreshTokenValid`]="{ item }">
				{{item.refreshTokenValid ? 'OK' : 'Expired!'}}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import RndConnectionService from "@/modules/watchTower/RndService";
	import RndConnectionDetail from "@/modules/watchTower/RndConnectionDetail";

	export default defineComponent({
		name: 'RndConnectionList',

		props: [],

		components: {},

		data: function () {
			return {
				rndConnectionService: new RndConnectionService(),
				rndConnections: [],
				headers: [
					{ title: 'Company Id', key: 'companyNumber', align: 'start' },
					{ title: 'Company name', key: 'companyName', align: 'start' },
					{ title: 'Status', key: 'resourceStatus', align: 'start' },
					{ title: 'Token status', key: 'tokenValid', align: 'start' },
					{ title: 'Refresh status', key: 'refreshTokenValid', align: 'start' }
				]
			};
		},

		mounted() {
			this.getRndConnections();
		},

		beforeUnmount() {},

		methods: {
			async addNew(){
				await this.editRndConnection();
			},

			async rowClick(event,row){
				await this.editRndConnection(row.item.id);
			},

			async editRndConnection(id = null){
				let binding = {
					id:id
				}

				this.$store.state.globalModalController.openModal(RndConnectionDetail,binding).then((rndConnection) => {
					if(!rndConnection){
						return;
					}

					let ix = this.rndConnections.findIndex(f => f.id === rndConnection.id);

					if(rndConnection.deleted && ix > -1){
						this.rndConnections.splice(ix,1);
					}else if(ix > -1){
						this.rndConnections.splice(ix,1, rndConnection)
					}else{
						this.rndConnections.push(rndConnection);
					}
				})
			},

			async getRndConnections(){
				let result = await this.rndConnectionService.getRndConnectionList(this.workspaceId);
				this.rndConnections.replace(result.data);
			}
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#rnd-connection-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
