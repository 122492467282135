import HttpClient from "@/services/HttpClient";

export default class PinpointService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getWorkspaces() {
		return await this.client.get(`/workspaces`);
	}

	async connectPinpoint(workspaceId, settings) {
		return await this.client.post(`/workspaces/${workspaceId}/dialers/pinpoint/connect`,settings);
	}
}