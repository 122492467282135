import IdentityList from "@/modules/identities/IdentityList";

export default [
    {
        path: '/identities',
        name: 'identities',
        component: IdentityList,
        title: 'Identities',
        props: true
    },
]