import HttpClient from "@/services/HttpClient";

export default class IdentityService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getIdentities(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/identities`);
    }

    async getIdentity(workspaceId,id) {
        return await this.client.get(`/workspaces/${workspaceId}/identities/${id}`);
    }
}