import HttpClient from '@/services/HttpClient';

export default class UserService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getUsers() {
		return this.client.get('/users');

	}

	async getUser(id) {
		return this.client.get(`/users/${id}`);
	}

	async addUser(user) {
		return this.client.post(`/users`, user);
	}

	async updateUser(id, user) {
		return this.client.put(`/users/${id}`, user);
	}

	async deleteUser(id) {
		return this.client.delete(`/users/${id}`);
	}
}
