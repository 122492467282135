<template>
	<notification-layout :id="id" action-text="" :sent-at="sentAt" @action="download()">
		<template v-slot:header>
			<p-icon color="primary" small class="mr-1">download</p-icon>
			<div>{{ notificationPayload.heading }}</div>
		</template>

		<template v-slot:content>
			<div class="font-12">
				<span> {{ notificationPayload.message }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>

	import NotificationLayout from "@/modules/notifications/NotificationLayout";
	import DownloadService from "@/modules/downloads/DownloadService";

	export default {
		name: 'ReportReadyNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { NotificationLayout },

		data: function() {
			return {
				downloadService: new DownloadService(),
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			async download() {
				let result = await this.downloadService.getDownloadUrl(this.notificationPayload.workspaceId, this.notificationPayload.id);
				await this.downloadS3File(result.data,this.notificationPayload.reportName);
			},

			async downloadS3File(signedUrl) {
				window.open(signedUrl,'_blank');
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
</style>
