import DrawFlow from 'drawflow';

export default class DrawFlowExtended extends DrawFlow {

    constructor(container, render = null, parent = null) {
        super(container,render,parent);
    }

    removeNodeId(id) {
        let moduleName = this.getModuleFromNodeId(id.slice(5));
        let node = this.drawflow.drawflow[moduleName].data[id.slice(5)];

        if(node.class === 'Start'){
            return;
        }

        this.removeConnectionNodeId(id);
        if(this.module === moduleName) {
            this.container.querySelector(`#${id}`).remove();
        }
        delete this.drawflow.drawflow[moduleName].data[id.slice(5)];
        this.dispatch('nodeRemoved', id.slice(5));
    }
}