<template>
	<div id="apn-settings">
		<div class="column-format gap-3">
			<div><img class="apn-logo" alt="APN logo" src="/images/vendors/apn.png" /></div>
			<v-text-field
				label="Username"
				v-model="apnSettings.username"
				density="compact"
				variant="outlined"
				hide-details
				persistent-placeholder
			></v-text-field>
			<v-text-field
				label="Password"
				v-model="apnSettings.password"
				type="password"
				density="compact"
				variant="outlined"
				hide-details
				persistent-placeholder
			></v-text-field>
			<v-text-field
				label="Domain"
				v-model="apnSettings.domain"
				density="compact"
				variant="outlined"
				hide-details
				persistent-placeholder
			></v-text-field>
			<div v-if="apnSettings.connected && !error"><v-icon color="success">check</v-icon> Successfully connected</div>
			<div v-else class="font-gray_70">Please update username, password, and domain to continue.</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import DialerService from '@/modules/dialers/DialerService';
	import APNService from '@/modules/dialers/apn/APNService';

	export default defineComponent({
		name: 'ApnSettings',

		props: ['workspace'],

		components: {},

		data: function () {
			return {
				dialerService: new DialerService(),
				apnService: new APNService(),
				apnSettings: { ...this.workspace.dialerProviderSettings },
				mappingFields: [],
				error: false,
			};
		},

		mounted() {
			this.checkSettings();
		},

		beforeUnmount() {},

		methods: {
			checkSettings: function () {
				if (this.apnSettings.connected) {
					this.getMappingFields();
				}
			},

			async save() {
				try {
					let result = await this.apnService.connect(this.workspace.id, this.apnSettings);
					return result.data;
				}catch(err){
					this.error = true;
					console.log(err);
					this.$store.commit('error','Error connecting APN account');
				}
			},

			async getMappingFields() {
				try {
					let result = await this.dialerService.getMappingFields(this.workspace.id, false);
					this.mappingFields.replace(result.data);
				}catch(err){
					this.error = true;
					console.log(err);
					this.$store.commit('error','Error connecting APN account');
				}
			},
		},

		computed: {},

		watch: {
			workspace: function (val) {
				this.apnSettings = { ...val.dialerProviderSettings };
				this.checkSettings();
			},
		},
	});
</script>

<style scoped lang="scss">
	#apn-settings {
		text-align: left;
		.apn-logo {
			max-width: 200px;
		}
	}
</style>
