<template>
	<div class="column-format gap-3">
		<v-text-field
			v-model="item.label"
			variant="outlined"
			density="compact"
			hide-details
			persistent-placeholder
			label="Label"
			@change="updated()"
		></v-text-field>

		<div v-for="condition in item.conditions" :key="condition.id" class="row-format gap-2 align-center">
			<v-select
				style="flex: 1 1 0"
				v-model="condition.scope"
				variant="outlined"
				density="compact"
				hide-details
				persistent-placeholder
				label="Scope"
				:items="scopesAndProps"
				:return-object="false"
				item-value="value"
				item-title="label"
				@update:modelValue="resetProperty(condition)"
			>
			</v-select>
			<v-text-field
				style="flex: 1 1 0"
				v-if="condition.scope && getPropsForScope(condition.scope) == null"
				v-model="condition.property"
				variant="outlined"
				density="compact"
				hide-details
				persistent-placeholder
				label="Property"
			></v-text-field>
			<v-select
				style="flex: 1 1 0"
				v-else
				v-model="condition.property"
				variant="outlined"
				density="compact"
				hide-details
				persistent-placeholder
				label="Property"
				:items="getPropsForScope(condition.scope)"
			></v-select>
			<v-select
				style="flex: 1 1 0"
				v-model="condition.operation"
				variant="outlined"
				density="compact"
				hide-details
				persistent-placeholder
				label="Property"
				:items="operations"
				item-value="value"
				item-title="label"
			></v-select>

			<v-text-field
				v-if="condition.operation !== 'IS_SET' && condition.operation !== 'IS_NOT_SET'"
				style="flex: 1 1 0"
				v-model="condition.value"
				variant="outlined"
				density="compact"
				hide-details
				persistent-placeholder
				label="Value"
			></v-text-field>
			<div v-else style="flex: 1 1 0" class="text-center">--</div>
			<p-icon class="material-symbols-outlined pointer" color="gray_70" @click="deleteCondition(condition)">delete</p-icon>
		</div>
		<div class="row-format align-center">
			<v-btn variant="outlined" small color="primary" @click="addCondition">+ Add condition</v-btn>

			<v-select
				class="ml-auto mr-8"
				v-if="item.conditions.length > 1"
				:items="conditionTypes"
				v-model="item.conditionType"
				hide-details
				variant="outlined"
				density="compact"
				label="Condition type"
				item-title="label"
				item-value="value"
				style="max-width: 320px"
				@update:modelValue="updated()"
			></v-select>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { v4 } from 'uuid';
	import RecordSetService from '@/modules/recordSets/RecordSetService';

	export default defineComponent({
		name: 'Decision',

		props: ['node'],

		components: {},

		data: function () {
			return {
				item: { ...this.node },
				recordSets: [],
				recordSetService: new RecordSetService(),
			};
		},

		mounted() {
			this.getRecordSets();
		},

		beforeUnmount() {},

		methods: {
			async getRecordSets() {
				let result = await this.recordSetService.getRecordSets(this.workspaceId);
				this.recordSets.replace(result.data.filter((r) => r.status === 'ACTIVE'));
			},

			addCondition: function () {
				let condition = {
					id: v4(),
					scope: null,
					property: null,
					operation: 'EQUALS',
					value: null,
				};
				this.item.conditions.push(condition);
			},

			deleteCondition: function (condition) {
				let ix = this.item.conditions.findIndex((c) => c.id === condition.id);
				if (ix > -1) {
					this.item.conditions.splice(ix, 1);
				}
			},

			resetProperty: function (condition) {
				condition.property = null;
			},

			updated: function () {
				this.$emit('updated', this.item);
			},

			getPropsForScope: function (scopeValue) {
				let scope = this.scopesAndProps.find((s) => s.value === scopeValue);
				if (scope) {
					return scope.properties;
				} else {
					return [];
				}
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			conditionTypes: function () {
				let result = [];

				result.push({ value: 'AND', label: 'All conditions must be met' });
				result.push({ value: 'OR', label: 'Any of the conditions can be met' });

				return result;
			},

			operations: function () {
				let result = [];

				result.push({ label: 'Equals', value: 'EQUALS' });
				result.push({ label: 'Not equals', value: 'NOT_EQUALS' });
				result.push({ label: 'Is set', value: 'IS_SET' });
				result.push({ label: 'Is not set', value: 'IS_NOT_SET' });
				result.push({ label: 'Contains', value: 'CONTAINS' });
				result.push({ label: 'Does not contain', value: 'DOES_NOT_CONTAIN' });
				result.push({ label: 'Greater than', value: 'GREATER_THAN' });
				result.push({ label: 'Less than', value: 'LESS_THAN' });

				return result;
			},

			scopesAndProps: function () {
				let result = [];

				result.push({ label: 'Call data', value: 'Call', properties: ['To', 'From', 'City', 'State', 'Zip','InputZip'] });
				result.push({ label: 'Meta data', value: 'Meta', properties: null });
				result.push({
					label: 'DataBoost',
					value: 'DataBoost',
					properties: ['isValid', 'ActivityScore', 'LineType', 'Carrier', 'isPrepaid'],
				});

				this.recordSets.forEach((r) => {
					result.push({
						label: r.name,
						value: `RecordSet:${r.id}`,
						properties: r.attributes.map((a) => a.name),
					});
				});

				result.push({ label: 'Federal DNC', value: 'FedDNC', properties: ['Blocked'], isBooleanType: true });
				result.push({ label: 'Reassigned Number', value: 'RND', properties: ['Reassigned','Failure'], isBooleanType: true });
				result.push({ label: 'Litigator', value: 'Litigator', properties: ['Blocked'], isBooleanType: true });
				result.push({ label: 'State dialing', value: 'StateDialing', properties: ['Blocked'], isBooleanType: true });
				result.push({ label: 'Time of day', value: 'TimeOfDay', properties: ['Blocked'], isBooleanType: true });
				result.push({ label: 'Velocity check', value: 'Velocity', properties: ['Blocked'], isBooleanType: true });
				result.push({ label: 'Active prospect', value: 'ActiveProspect', properties: ['Blocked'], isBooleanType: true });
				result.push({ label: 'Verisk', value: 'Verisk', properties: ['Blocked'], isBooleanType: true });
				return result;
			},
		},
	});
</script>

<style scoped lang="scss"></style>
