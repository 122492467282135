<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header
					name="Processed lists"
					:workspace-selector="true"
				>
					<template v-slot:filter>
						<v-text-field v-model="textFilter" density="compact" hide-details placeholder="Filter..." variant="outlined" style="min-width: 150px"></v-text-field>
					</template>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="pa-4" style="max-height: calc(100vh - 140px); overflow-y: auto">
				<v-container fluid class="ma-0 pa-0 text-left font-14">
					<v-row dense class="list-row" style="cursor: unset !important">
						<v-col cols="2" class="font-weight-bold">Campaign</v-col>
						<v-col cols="2" class="font-weight-bold">List</v-col>
						<v-col cols="2" class="font-weight-bold">Status</v-col>
						<v-col cols="2" class="font-weight-bold">List type</v-col>
						<v-col cols="2" class="font-weight-bold">Date</v-col>
						<v-col cols="2" class="text-right font-weight-bold">Leads</v-col>
					</v-row>
					<v-row
							@click="openList(list)"
							v-for="list in lists"
							:key="list.id"
							dense
							class="py-2 list-row"
					>
						<v-col cols="2" class="font-weight-medium" @click="editCampaign(list.campaignId)" style="color: rgb(var(--v-theme-secondary))">{{ list.campaignName }}</v-col>
						<v-col cols="2" class="truncate" v-tippy="`${list.name}`">{{ list.name }}</v-col>
						<v-col cols="2" style="text-transform: capitalize">{{ formatStatus(list) }}</v-col>
						<v-col cols="2" style="text-transform: capitalize">{{ formatType(list) }}</v-col>
						<v-col cols="2">{{ DateTime.fromISO(list.uploaded).toLocaleString(DateTime.DATETIME_SHORT) }}</v-col>
						<v-col cols="2" class="text-right" v-tippy="formatTippy(list)"
						>{{ list.metrics.valid }}/{{ getTotal(list.metrics) }}</v-col
						>
						<template v-if="list.expanded">
							<v-col cols="9">
								<template v-if="list.processedBy">
									Processed by:
									<span class="medium">{{ list.processedBy.firstName }} {{ list.processedBy.lastName }}</span
									><br />
									Started:
									<span class="medium">{{
											DateTime.fromISO(list.processingStart).toLocaleString(DateTime.DATETIME_SHORT)
										}}</span
									><br />
									Finish:
									<span class="medium" v-if="list.processingFinish">{{
											DateTime.fromISO(list.processingFinish).toLocaleString(DateTime.DATETIME_SHORT)
										}}</span
									><span v-else style="font-style: italic">In process</span><br />
									Total time: <span class="medium">{{ formatSeconds(list.processingDuration) }}</span>
								</template>
							</v-col>
							<v-col cols="3" class="text-right">
								Supplied: <span class="medium">{{ getTotal(list.metrics) }}</span
							><br />
								Landline: <span class="medium">{{ list.metrics.landline }}</span
							><br />
								Mobile: <span class="medium">{{ list.metrics.mobile }}</span
							><br />
								Inactive: <span class="medium">{{ list.metrics.inactive }}</span
							><br />
								Invalid: <span class="medium">{{ list.metrics.invalid }}</span>
							</v-col>
						</template>
					</v-row>
				</v-container>
				<v-pagination class="my-3" :length="totalPages" :total-visible="10"  start="1" v-model="currentPage"></v-pagination>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import CampaignListService from "@/modules/campaigns/CampaignListService";
	import PDateTime from "@/modules/utils/PDateTime";
	import CampaignService from "@/modules/campaigns/CampaignService";


	export default defineComponent({
		name: 'ListOverview',

		props: [],

		components: { ModuleHeader },

		data: function () {
			return {
				DateTime: PDateTime,
				lists: [],
				campaigns: [],
				campaignListService: new CampaignListService(),
				campaignService: new CampaignService(),
				currentPage: 1,
				itemsPerPage: 50,
				textFilter: null,
				totalPages: 0,
				totalElements: 0,
				pageNumber: 0,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.getAllLists);
			this.$store.state.eventBus.$on(`o-${this.orgId}.w-${this.workspaceId}`,this.handleWorkspaceEvent);
			this.getAllLists();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.getAllLists);
			this.$store.state.eventBus.$on(`o-${this.orgId}.w-${this.workspaceId}`,this.handleWorkspaceEvent);
		},

		methods: {
			async getAllLists() {
				this.$store.commit('startLoading');
				let result = await this.campaignListService.getAllLists(this.workspaceId,this.currentPage-1,this.itemsPerPage);
				let campaignResult = await this.campaignService.getCampaigns(this.workspaceId);
				this.campaigns.replace(campaignResult.data);

				this.pageNumber = result.data.number;
				this.totalPages = result.data.totalPages;
				this.totalElements = result.data.totalElements;
				this.lists.replace(result.data.content);

				this.lists.forEach((l) => {
					let campaign = this.campaigns.find(c => c.id === l.campaignId);
					l.expanded = false;
					l.campaignName = campaign ? campaign.name : '?'
				});
				this.$store.commit('stopLoading');
			},

			editCampaign: function (campaignId) {
				this.$router.push(`/campaigns/${campaignId}`);
			},

			handleWorkspaceEvent: function(event){
				if (event.userMetadata === 'CampaignList') {
					let list = event.message;
					let campaign = this.campaigns.find(c => c.id === list.campaignId);
					list.expanded = false;
					list.campaignName = campaign ? campaign.name : '?'

					let ix = this.lists.findIndex((l) => l.id === list.id);
					if (ix > -1) {
						this.lists.splice(ix, 1, list);
					}else{
						this.lists.splice(0,0,list);
					}
				}
			},

			getTotal: function (metrics) {
				return metrics.valid + metrics.invalid + metrics.dnc + metrics.inactive;
			},

			openList: function (list) {
				list.expanded = !list.expanded;
			},

			formatTippy(list) {
				return `Loaded: ${list.metrics.valid}\nSupplied: ${this.getTotal(list.metrics)}\nInactive: ${
						list.metrics.inactive
				}\nInvalid: ${list.metrics.invalid}`;
			},

			formatStatus(list){
					return list.status.toLowerCase();
			},

			formatType(list){
				if(list.type === 'DIALER'){
					return 'Dialer'
				}else if(list.type === 'MESSAGING'){
					return 'Messaging'
				}else{
					return 'Combined'
				}
			},

			formatSeconds(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				return hours + ':' + minutes + ':' + seconds;
			},

		},

		watch: {
			currentPage: function(){
				this.getAllLists();
			}
		},

		computed: {
			orgId: function(){
				return this.$store.getters.getOrgId;
			},

			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			}
		},
	});
</script>

<style scoped lang="scss">
	.list-row {
		border-bottom: 1px solid rgb(var(--v-theme-gray_50));
		cursor: pointer;
	}
</style>
