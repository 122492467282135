<template>
	<div>
		<div v-bind="getRootProps()" class="drop-box row-format centered pa-3">
			<input v-bind="getInputProps()" />
			<p-icon>upload</p-icon>
			<div>Upload a new CSV file by dropping here or click to upload</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { useDropzone } from 'vue3-dropzone';

	export default defineComponent({
		name: 'CampaignListUploader',

		setup(props, {emit}) {
			function onDrop(acceptFiles, rejectReasons) {
				if(rejectReasons.length){
					let message = '';
					for(let i=0; i < rejectReasons.length; i++){
						message = message + `${rejectReasons[i].file.name} rejected because: ${rejectReasons[i].errors[0].message}\n`
					}
					emit('error',message);
					return;
				}

				if (acceptFiles.length) {
					emit('files-dropped',acceptFiles);
				}
			}

			const { getRootProps, getInputProps, ...rest } = useDropzone({
				onDrop: onDrop,
				accept: '.csv',
			});

			return {
				getRootProps,
				getInputProps,
				...rest,
			};
		},
	});
</script>

<style scoped lang="scss">
	.drop-box {
		font-size: 14px;
		cursor: pointer;
		border: 1px dashed rgb(var(--v-theme-gray_50));
	}
</style>
