<template>
	<div>
		<v-data-table id="ftc-san-table" :items="ftcSanList" :headers="headers" :items-per-page="-1" @click:row="rowClick">
			<template v-slot:[`item.coverage`]="{ item }">
				<span v-if="item.fullCoverage">Full coverage</span>
				<span v-else>{{item.areaCodes.length}} area codes</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import FtcSanService from "@/modules/watchTower/FtcSanService";
	import FtcSanDetail from "@/modules/watchTower/FtcSanDetail";

	export default defineComponent({
		name: 'FtcSanList',

		props: [],

		components: {},

		data: function () {
			return {
				ftcSanService: new FtcSanService(),
				ftcSanList: [],
				headers: [
					{ title: 'Org name', key: 'ftcOrgName', align: 'start' },
					{ title: 'Org Id', key: 'ftcOrgId', align: 'start' },
					{ title: 'SAN', key: 'ftcSan', align: 'start' },
					{ title: 'Expires', key: 'expires', align: 'start' },
					{ title: 'Coverage', key: 'coverage', align: 'start' }
				]
			};
		},

		mounted() {
			this.getFtcSanList();
		},

		beforeUnmount() {},

		methods: {
			async addNew(){
				await this.editFtcSan();
			},

			async rowClick(event,row){
				await this.editFtcSan(row.item.id);
			},

			async editFtcSan(id = null){
				let binding = {
					id:id
				}

				this.$store.state.globalModalController.openModal(FtcSanDetail,binding).then((ftcSan) => {
					if(!ftcSan){
						return;
					}

					let ix = this.ftcSanList.findIndex(f => f.id === ftcSan.id);

					if(ftcSan.deleted && ix > -1){
						this.ftcSanList.splice(ix,1);
					}else if(ix > -1){
						this.ftcSanList.splice(ix,1, ftcSan)
					}else{
						this.ftcSanList.push(ftcSan);
					}
				})
			},

			async getFtcSanList(){
				let result = await this.ftcSanService.getFtcSanList(this.workspaceId);
				this.ftcSanList.replace(result.data);
			}
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#ftc-san-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
