<template>
	<div id="workspace-edit" :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`" v-if="recordSet">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result', recordSet)">close</p-icon>
				<div class="brand-medium font-18">{{ recordSet.id ? 'Edit' : 'Create' }} record set</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="save()" :disabled="!isValid"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()">delete</p-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body" v-if="recordSet">
			<v-form ref="form" v-model="isValid" @submit.prevent>
				<div class="column-format gap-3 pt-3">
					<v-text-field
						label="Record set name"
						v-model="recordSet.name"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<div class="row-format">
						<v-switch
							color="secondary"
							v-model="recordSet.ttlEnabled"
							hide-details
							dense
							label="TTL enabled"
							@change="showAlert"
						></v-switch>
						<v-text-field
							:disabled="!recordSet.ttlEnabled"
							label="Days to retain records"
							v-model="recordSet.ttl.duration"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							@change="showAlert"
						></v-text-field>
					</div>
					<div
						class="text-left font-12 row-format align-center gap-1"
						style="color: rgb(var(--v-theme-error))"
						v-if="showWarn"
					>
						<v-icon size="20">warning</v-icon>
						<div>Changing the TTL value will not impact records already contained in the set.</div>
					</div>
					<div class="row-format">
						<div class="brand-medium text-left">Attributes</div>
						<div class="ml-auto font-secondary pointer font-14" @click="downloadCSVTemplate">
							Download CSV template
						</div>
					</div>

					<div v-for="(attribute, index) in recordSet.attributes" :key="index" class="row-format gap-2 align-center">
						<v-text-field
							style="flex: 1 1 0"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							label="Attribute name"
							v-model="attribute.name"
							:disabled="attribute.locked"
							@input="removeNonAlphanumeric(attribute)"
						></v-text-field>
						<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="removeAttribute(attribute, index)"
							>delete</p-icon
						>
					</div>
					<v-btn variant="outlined" color="secondary" @click="addAttribute()">+ Add attribute</v-btn>
				</div>
			</v-form>
			<div class="my-4" style="border-top: 1px solid rgb(var(--v-theme-gray_50))"></div>
			<div class="row-format align-center">
				<div class="text-left">
					<div class="brand-medium">Record count: ~{{ recordSet.recordCount }}</div>
					<div class="font-12 font-italic">(This value is updated approximately every 6 hours)</div>
				</div>
				<input type="file" ref="fileInput" style="display: none" accept=".csv" @input="handleFileUpload" />
				<v-btn class="ml-auto" variant="outlined" @click="triggerFileInput"><v-icon>upload</v-icon>Upload records</v-btn>
			</div>

			<div class="my-4" style="border-top: 1px solid rgb(var(--v-theme-gray_50))"></div>
			<div class="column-format gap-2" v-if="recordSet.id">
				<div class="row-format gap-2">
					<v-text-field
						v-model="search"
						density="compact"
						hide-details
						type="number"
						placeholder="Search by phone ex: 5555551212"
						append-inner-icon="filter_list"
					></v-text-field>
					<v-btn
						elevation="0"
						color="secondary"
						@click="searchRecords()"
						:disabled="!search || search.length < 3"
						style="min-height: 40px"
						>Search</v-btn
					>
				</div>
				<div v-if="foundRecord" class="text-left">
					<div><span class="font-weight-bold">Phone:</span> {{ foundRecord.phone }}</div>
					<div>
						<span class="font-weight-bold">Expires:</span>
						{{
							foundRecord.attributes.ttl
								? DateTime.fromSeconds(Number(foundRecord.attributes.ttl)).toLocaleString(DateTime.DATETIME_FULL)
								: 'N/A'
						}}
					</div>
					<div v-for="(attribute, index) in recordSet.attributes.filter(a => a.name !== 'phone')" :key="index">
						<span class="font-weight-bold">{{ attribute.name }}:</span> {{ foundRecord.attributes[attribute.name] }}
					</div>
					<v-icon class="material-symbols-outlined" @click="confirmDeleteRecord(foundRecord)">delete</v-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import RecordSetService from '@/modules/recordSets/RecordSetService';
	import ConfirmModal from '@/components/ConfirmModal';
	import { DateTime } from 'luxon';

	export default defineComponent({
		name: 'EditRecordSet',
		isRightModal: true,

		props: ['id'],

		components: {},

		data: function () {
			return {
				DateTime: DateTime,
				search: null,
				foundRecord: null,
				isValid: false,
				showWarn: false,
				recordSetService: new RecordSetService(),
				recordSet: null,
				required: [(v) => !!v || 'Name is required'],
				attributeTypes: [
					{ label: 'String', value: 'S' },
					{ label: 'Number', value: 'N' },
					{ label: 'Boolean', value: 'B' },
				],
			};
		},

		mounted() {
			if (this.id) {
				this.getRecordSet();
			} else {
				this.getDefaultRecordSet();
			}
		},

		beforeUnmount() {},

		methods: {
			async save() {
				try {
					if (this.recordSet.id) {
						let result = await this.recordSetService.updateRecordSet(this.workspaceId, this.recordSet.id, this.recordSet);
						this.recordSet = result.data;
					} else {
						let result = await this.recordSetService.createRecordSet(this.workspaceId, this.recordSet);
						this.recordSet = result.data;
					}
					this.$store.commit('success', 'Saved');
				}catch(err){
					this.$store.commit('error', err.response?.data?.message);
				}
			},

			confirmDelete() {
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete this record set?  All records will be immediately deleted. Type the record set name (${this.recordSet.name}) to confirm the action.`,
					confirmText: this.recordSet.name,
					confirmHint: `Enter: "${this.recordSet.name}" in the field above`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.recordSetService.deleteRecordSet(this.workspaceId, this.recordSet.id).then(() => {
							this.$emit('result');
						});
					}
				});
			},

			confirmDeleteRecord(foundRecord){
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete this record?`
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.recordSetService.removeNumberFromRecordSet(this.workspaceId, this.recordSet.id, foundRecord.phone).then(() => {
							this.$store.commit('success','Record has been removed');
							this.foundRecord = null;
						});
					}
				});
			},

			triggerFileInput() {
				this.$refs.fileInput.click();
			},

			async handleFileUpload(event) {
				try {
					this.$store.commit('startLoading');
					const file = event.target.files[0];
					let result = await this.recordSetService.uploadRecords(this.workspaceId, this.recordSet.id, file);
					event.target.value = '';
					this.$store.commit(
							'success',
							`Successfully uploaded ${result.data.successfulRecords} records; ${result.data.failureRecords} records failed to upload.`
					);
				}catch(err){
					this.$store.commit(
							'error',
							err.response.data.message
					);
				}finally{
					this.$store.commit('stopLoading');
				}
			},

			async searchRecords() {
				let result = await this.recordSetService.searchNumberInRecordSet(
					this.workspaceId,
					this.recordSet.id,
					this.search
				);
				this.foundRecord = result.data;
				if(!this.foundRecord){
					this.$store.commit('warn',`No records found for ${this.search}`);
				}
			},

			showAlert() {
				if (this.recordSet.id) {
					this.showWarn = true;
				}
			},

			async getRecordSet() {
				let result = await this.recordSetService.getRecordSet(this.workspaceId, this.id);
				this.recordSet = result.data;
			},

			getDefaultRecordSet: function () {
				this.recordSet = {
					id: null,
					status: null,
					name: null,
					ttlEnabled: true,
					ttl: { duration: 365, timeUnit: 'DAYS' },
					recordCount: 0,
					attributes: [{ name: 'phone', type: 'S', locked: true }],
				};
			},

			addAttribute: function () {
				this.recordSet.attributes.push({ name: null, type: 'S', locked: false });
			},

			removeAttribute: function (attribute, index) {
				if (attribute.locked) {
					return;
				}
				this.recordSet.attributes.splice(index, 1);
			},

			removeNonAlphanumeric(attribute) {
				attribute.name = attribute.name.replace(/[^a-zA-Z0-9]/g, '');
			},

			downloadCSVTemplate() {
				const csvString = this.recordSet.attributes.map((a) => a.name).join(',') + '\n';
				const blob = new Blob([csvString], { type: 'text/csv' });
				const url = URL.createObjectURL(blob);

				// Create a temporary link to trigger the download
				const link = document.createElement('a');
				link.href = url;
				link.download = this.recordSet.name + '_template.csv';
				document.body.appendChild(link); // Required for Firefox
				link.click();
				document.body.removeChild(link);
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss"></style>
