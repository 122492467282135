import HttpClient from "@/services/HttpClient";

export default class DialerService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getMappingFields(workspaceId, filterReserved = true) {
        return await this.client.get(`/workspaces/${workspaceId}/dialers/mappingFields?filterReserved=${filterReserved}`);
    }

    async getMappingFieldsForCampaign(workspaceId, campaignId, filterReserved = true) {
        return await this.client.get(`/workspaces/${workspaceId}/dialers/mappingFields?campaignId=${campaignId}&filterReserved=${filterReserved}`);
    }

    async getCampaigns(workspaceId,includeLists=false) {
        return await this.client.get(`/workspaces/${workspaceId}/dialers/campaigns?includeLists=${includeLists}`);
    }

    async getCampaign(workspaceId,campaignId) {
        return await this.client.get(`/workspaces/${workspaceId}/dialers/campaigns/${campaignId}`);
    }

}