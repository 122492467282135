<template>
	<div :style="`max-width:calc(100vw - ${$store.state.leftNavExpanded ? '200px' : '50px'}); width: 100%; overflow-x: auto`">
		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<module-header name="Aegis call search" :workspace-selector="true"> </module-header>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="row-format gap-2">
					<maz-phone-number-input
						:no-country-selector="true"
						:translations="{ phoneInput: { placeholder: 'To', example: 'To' } }"
						:only-countries="['US', 'CA']"
						size="sm"
						v-model="to"
					>
					</maz-phone-number-input>
					<maz-phone-number-input
						:no-country-selector="true"
						:translations="{ phoneInput: { placeholder: 'From', example: 'From' } }"
						:only-countries="['US', 'CA']"
						size="sm"
						v-model="from"
					>
					</maz-phone-number-input>
					<v-text-field
						v-model="states"
						style="max-width: 200px"
						density="compact"
						variant="outlined"
						persistent-placeholder
						hide-details
						label="State(s)"
						placeholder="NY,NJ,CA"
					></v-text-field>
					<v-select
						v-model="result"
						style="max-width: 200px"
						density="compact"
						variant="outlined"
						persistent-placeholder
						hide-details
						:items="['All', 'Rejected', 'Accepted']"
						label="Result"
					></v-select>
					<v-select
						id="workflow-select"
						:items="workflows"
						v-model="workflowIds"
						style="max-width: 200px"
						item-value="id"
						item-title="name"
						label="Workflow"
						density="compact"
						variant="outlined"
						persistent-placeholder
						placeholder="All workflows"
						hide-details
						:multiple="true"
					>
						<template v-slot:selection="{ item, index }">
							<div v-if="workflowIds.length === 1" class="truncate-column">{{ item.title }}</div>
							<div v-else-if="index === workflowIds.length - 1" class="truncate-column">
								{{ workflowIds.length }} workflows
							</div>
						</template>
					</v-select>
					<v-btn class="super-action" style="min-height: 40px" @click="doSearch">Search</v-btn>
					<v-btn elevation="0" class="" style="min-height: 40px" @click="downloadInteractions()"
						><p-icon :size="30">download</p-icon></v-btn
					>
				</v-col>
				<v-col cols="12" class="row-format gap-1" style="flex-wrap: wrap">
					<scope-button
						v-for="(dateOption, index) in dateOptions"
						:scope="dateOption.value"
						:active-scope="datePeriod"
						:label="dateOption.text"
						@scope-update="scopeUpdated"
						:key="index"
					></scope-button>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table-server
						id="search-table"
						:items="processedInteractions"
						:headers="headers"
						:fixed-header="true"
						:multi-sort="false"
						:must-sort="true"
						v-model:items-per-page="itemsPerPage"
						:items-length="totalItems"
						:loading="loading"
						v-model:page="page"
						v-model:sort-by="sortBy"
						@update:options="doSearch"
						:items-per-page-options="[25, 100, 250, 500]"
						style="max-height: calc(100vh - 250px)"
						class="pointer"
						@click:row="handleRowClick"
					>
						<template v-slot:[`item.timestamp`]="{ item }">
							<span style="white-space: nowrap">{{
								DateTime.fromISO(item.timestamp).toLocaleString(DateTime.DATETIME_SHORT)
							}}</span>
						</template>
						<template v-slot:[`item.workflow`]="{ item }">
							<div class="truncate-column" v-tippy :content="item.workflow">{{ item.workflow }}</div>
						</template>
						<template v-slot:[`item.reason`]="{ item }">
							<div class="truncate-column" v-tippy :content="item.reason">{{ item.reason }}</div>
						</template>
						<template v-slot:[`item.result`]="{ item }">
							<p-icon :size="20" color="success" v-if="item.result === 'Accepted'" v-tippy="{ content: 'Accepted' }"
								>verified</p-icon
							>
							<p-icon v-else :size="20" color="error" v-tippy="{ content: 'Rejected' }">report</p-icon>
							<p-icon :size="20" v-if="item.isTest" color="warning" v-tippy="{ content: 'Test Call' }"
								>construction</p-icon
							>
							<p-icon :size="20" v-if="item.isSipTrunk" color="secondary" v-tippy="{ content: 'SIP Call' }"
								>phone</p-icon
							>
						</template>
					</v-data-table-server>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import DashboardService from '@/modules/aegis/DashboardService';
	import FilterHelpers from '@/modules/utils/FilterHelpers';
	import ScopeButton from '@/components/ScopeButton';
	import ModuleHeader from '@/components/ModuleHeader';
	import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
	import { DateTime } from 'luxon';
	import InteractionDetailModal from '@/modules/aegis/InteractionDetailModal';
	import WorkflowService from '@/modules/workflow/WorkflowService';
	import PIcon from '@/components/PIcon';

	export default defineComponent({
		name: 'CallSearch',

		props: [],

		components: { PIcon, ScopeButton, ModuleHeader, MazPhoneNumberInput },

		data: function () {
			return {
				DateTime: DateTime,
				interactions: [],
				workflows: [],
				to: null,
				from: null,
				states: null,
				result: 'All',
				workflowIds: [],
				includeTestData: true,
				datePeriod: 'today',
				dashboardData: null,
				dashboardService: new DashboardService(),
				dateOptions: FilterHelpers.getDateOptions([
					'today',
					'yesterday',
					'this-week',
					'last-week',
					'this-month',
					'last-month',
					'this-year',
					'last-year',
				]),

				page: 1,
				itemsPerPage: 25,
				sortBy: [{ key: 'timestamp', order: 'desc' }],
				totalItems: 0,
				loading: true,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.initialize);
			this.initialize();
		},

		beforeUnmount() {},

		methods: {
			initialize: function(){
				this.getWorkflows();
				this.interactions.splice(0);
			},

			getWorkflows: function () {
				new WorkflowService().getWorkflows(this.workspaceId).then((res) => {
					this.workflows.splice(0);
					res.data.forEach((d) => {
						this.workflows.push({ id: d.id, name: d.name });
					});
				});
			},

			scopeUpdated: function (scope) {
				this.datePeriod = scope;
				this.doSearch();
			},

			handleRowClick: function (event, row) {
				let binding = {
					interaction: row.item,
				};
				this.$store.state.globalModalController.openModal(
					InteractionDetailModal,
					binding,
					true,
					false,
					false,
					false,
					800
				);
			},

			getSearchObject() {
				let stateArray = [];
				let results = [];

				if (this.result !== 'All') {
					results.push(this.result);
				}

				if (this.states) {
					this.states.split(',').forEach((s) => stateArray.push(s.toUpperCase().trim()));
				}

				return {
					earliest: this.earliest,
					latest: this.latest,
					workflowId: this.workflowIds,
					to: this.to,
					from: this.from,
					states: stateArray,
					results: results,
					includeTestData: true,
					page: this.page - 1,
					size: this.itemsPerPage,
					sortBy: this.sortBy[0].key,
					sortOrder: this.sortBy[0].order,
				};
			},

			async doSearch() {
				try {
					this.$store.commit('startLoading');
					let search = this.getSearchObject();
					let result = await this.dashboardService.getInteractions(this.workspaceId, search);
					this.interactions.replace(result.data.content);
					this.totalItems = result.data.totalElements;
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
					this.loading = false;
				}
			},

			async downloadInteractions() {
				try {
					this.$store.commit('startLoading');
					await this.dashboardService.downloadInteractions(this.workspaceId, this.getSearchObject());
					this.$store.commit('success', 'Your request is processing.  You will be notified when the file is ready to download.');
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},
		},

		computed: {
			processedInteractions: function () {
				let result = [...this.interactions];
				const workflowMap = new Map(this.workflows.map((item) => [item.id, item.name]));

				result.forEach((r) => {
					r.workflow = workflowMap.get(r.workflowId);
				});

				return result;
			},

			headers: function () {
				return [
					{ title: 'Time', key: 'timestamp', align: 'start' },
					{ title: 'Workflow', key: 'workflow', align: 'start', sortable: false },
					{ title: 'From', key: 'from', align: 'start' },
					{ title: 'To', key: 'to', align: 'start' },
					{ title: 'Result', key: 'result', align: 'start' },
					{ title: 'Reason', key: 'reason', align: 'start' },
					{ title: 'City', key: 'city', align: 'start' },
					{ title: 'State', key: 'state', align: 'start' },
					{ title: 'Zip', key: 'zip', align: 'start' },
					{ title: 'Timezone', key: 'timezone', align: 'start' },
				];
			},

			earliest: function () {
				return FilterHelpers.getEarliestAndLatest(this.datePeriod).earliest;
			},

			latest: function () {
				return FilterHelpers.getEarliestAndLatest(this.datePeriod).latest;
			},

			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	.metric {
		text-align: left;
		border: 1px solid rgb(var(--v-theme-gray_30));
		background-color: rgb(var(--v-theme-gray_10));
		padding: 18px;
		border-radius: 4px;
		min-width: 200px;
		flex: 1 1 auto;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		.label {
			font-weight: 500;
		}
		.value {
			font-size: 48px;
			font-weight: 500;
		}
		.error {
			color: red;
		}
		.success {
			color: green;
		}
	}

	.truncate-column {
		max-width: 150px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	//#search-table :deep(.v-data-table-footer) {
	//	display: none;
	//}
</style>