import HttpClient from '@/services/HttpClient';

export default class WorkflowService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	async getWorkflows(workspaceId) {
		return await this.httpClient.get(`/workspaces/${workspaceId}/aegis/workflows`);
	}

	async getWorkflow(workspaceId,id) {
		return await this.httpClient.get(`/workspaces/${workspaceId}/aegis/workflows/${id}`);
	}

	async duplicateWorkflow(workspaceId,id) {
		return await this.httpClient.post(`/workspaces/${workspaceId}/aegis/workflows/${id}/duplicate`);
	}

	async createWorkflow(workspaceId,workflow) {
		return await this.httpClient.post(`/workspaces/${workspaceId}/aegis/workflows`, workflow);
	}

	async updateWorkflow(workspaceId, id, workflow) {
		return await this.httpClient.put(`/workspaces/${workspaceId}/aegis/workflows/${id}`, workflow);
	}

	async publishWorkflow(workspaceId,id) {
		return await this.httpClient.post(`/workspaces/${workspaceId}/aegis/workflows/${id}/publish`);
	}

	async deleteWorkflow(workspaceId,id) {
		return await this.httpClient.delete(`/workspaces/${workspaceId}/aegis/workflows/${id}`);
	}

	async testWorkflow(workspaceId,id,request) {
		return await this.httpClient.post(`/workspaces/${workspaceId}/aegis/workflows/${id}/test`,request);
	}
}
