<template>
	<div>
		<div class="event-box" v-if="campaign.events.length">
			<v-container fluid class="text-left pa-0 ma-0 font-14">
				<v-row dense style="border-bottom: 1px solid rgb(var(--v-theme-gray_20))">
					<v-col cols="2" class="font-weight-bold">Date</v-col>
					<v-col cols="2" class="font-weight-bold">Category</v-col>
					<v-col cols="8" class="font-weight-bold">Message</v-col>
				</v-row>
				<v-row v-for="(event, index) in campaign.events" :key="index" dense style="border-bottom: 1px solid rgb(var(--v-theme-gray_20))">
					<v-col cols="2">{{ DateTime.fromISO(event.timestamp).toLocaleString(DateTime.DATETIME_SHORT) }}</v-col>
					<v-col cols="2">{{ event.type }}</v-col>
					<v-col cols="8">{{ event.message }}</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import PDateTime from "@/modules/utils/PDateTime";

	export default defineComponent({
		name: 'CampaignEvents',

		props: ['campaign'],

		components: {},

		data: function () {
			return {
				DateTime: PDateTime
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {},

		computed: {},
	});
</script>

<style scoped lang="scss"></style>
