<template>
	<div class="row-format gap-2 my-4 d-flex align-center">
		<v-select
			label="CRM Add Config"
			:items="crmAddConfig"
			v-model="crmAddChosen"
			@update:modelValue="emitCrmAddChosen"
			variant="outlined"
			density="compact"
			hide-details
		>
			<template v-slot:item="{ props, item }">
				<v-list-item
					v-bind="props"
					:disabled="item.raw.disabled"
				></v-list-item>
			</template>
		</v-select>
		<v-select
			label="CRM Update Config"
			:items="crmUpdateConfig"
			v-model="crmUpdateChosen"
			@update:modelValue="emitCrmUpdateChosen"
			variant="outlined"
			density="compact"
			hide-details
		>
			<template v-slot:item="{ props, item }">
				<v-list-item
					v-bind="props"
					:disabled="item.raw.disabled"
				></v-list-item>
			</template>
		</v-select>
		<v-select
			label="List Add Config"
			:items="listAddConfig"
			v-model="listAddChosen"
			@update:modelValue="emitListAddChosen"
			variant="outlined"
			density="compact"
			hide-details
		>
			<template v-slot:item="{ props, item }">
				<v-list-item
					v-bind="props"
					:disabled="item.raw.disabled"
				></v-list-item>
			</template>
		</v-select>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'Five9Config',

		props: ["single"],

		components: {},

		data: function () {
			return {
				crmUpdateConfig: [],
				crmAddConfig: [],
				listAddConfig: [],
				crmUpdateChosen: "UPDATE_FIRST",
				crmAddChosen: "ADD_NEW",
				listAddChosen: "ADD_FIRST",
			};
		},

		mounted() {
			this.buildList()
			this.emitCrmAddChosen()
			this.emitListAddChosen()
			this.emitCrmUpdateChosen()
		},

		beforeUnmount() {},

		methods: {
			buildList: function() {
				this.crmAddConfig = [
					{ title: "ADD_NEW", value: "ADD_NEW", disabled: false },
					{ title: "DONT_ADD", value: "DONT_ADD", disabled: false }
				];

				this.crmUpdateConfig = [
					{ title: "UPDATE_FIRST", value: "UPDATE_FIRST", disabled: false },
					{ title: "UPDATE_ALL", value: "UPDATE_ALL", disabled: this.single },
					{ title: "UPDATE_SOLE_MATCHES", value: "UPDATE_SOLE_MATCHES", disabled: false },
					{ title: "DONT_UPDATE", value: "DONT_UPDATE", disabled: false }
				];

				this.listAddConfig = [
					{ title: "ADD_FIRST", value: "ADD_FIRST", disabled: false },
					{ title: "ADD_ALL", value: "ADD_ALL", disabled: this.single },
					{ title: "ADD_IF_SOLE_CRM_MATCH", value: "ADD_IF_SOLE_CRM_MATCH", disabled: false }
				];
			},

			updateOptions: function() {
				this.crmUpdateConfig.forEach(option => {
					if(this.crmAddChosen === "DONT_ADD" && option.value === "UPDATE_ALL") {
						option.disabled = true
					} else {
						option.disabled = false
					}
				});

				if(this.crmAddChosen === "DONT_ADD" && this.crmUpdateChosen === "UPDATE_ALL") {
					this.crmUpdateChosen = "UPDATE_FIRST"
					this.emitCrmUpdateChosen()
				}

				this.listAddConfig.forEach(option => {
					if(this.crmAddChosen === "DONT_ADD" && option.value === "ADD_ALL") {
						option.disabled = true
					} else {
						option.disabled = false
					}
				});

				if(this.crmAddChosen === "DONT_ADD" && this.listAddChosen === "ADD_ALL") {
					this.listAddChosen = "ADD_FIRST"
					this.emitListAddChosen()
				}
			},

			emitCrmAddChosen() {
				this.$emit('crm-add-chosen', this.crmAddChosen)
			},
			emitCrmUpdateChosen() {
				this.$emit('crm-update-chosen', this.crmUpdateChosen)
			},
			emitListAddChosen() {
				this.$emit('list-add-chosen', this.listAddChosen)
			},
		},

		computed: {

		},

		watch: {
			crmAddChosen() {
				this.updateOptions()
			},
			crmUpdateChosen() {
				this.updateOptions()
			},
			listAddChosen() {
				this.updateOptions()
			}
		},
	});
</script>

<style scoped lang="scss">

</style>
