import HttpClient from "@/services/HttpClient";

export default class Five9Service {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getWorkspaces() {
        return await this.client.get(`/workspaces`);
    }

    async connectFive9(workspaceId,settings) {
        return await this.client.post(`/workspaces/${workspaceId}/dialers/five9/connect`,settings);
    }
}