<template>
	<div style="width: 100%; height: 100%">
		<input type="hidden" id="copyInput">
		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<module-header
						name="Workflows"
						add-label="Create workflow"
						@add-new="addWorkflow()"
						:workspace-selector="true"
					>
						<template v-slot:filter>
							<v-text-field
								v-model="textFilter"
								density="compact"
								hide-details
								placeholder="Filter..."
								variant="outlined"
								style="min-width: 150px"
							></v-text-field>
							<v-select
								v-model="filter"
								:items="['Active', 'Inactive', 'All']"
								density="compact"
								hide-details
								variant="outlined"
							></v-select>
						</template>
					</module-header>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table
						id="workflow-table"
						:items="filteredWorkflows"
						:items-per-page="-1"
						:headers="headers"
						class="pointer"
						fixed-header
						height="calc(100vh - 150px)"
						:style="`max-width: calc(${width} - 40px)`"
						@click:row="workflowRowClicked"
					>
						<template v-slot:[`item.id`]="{ item }">
							<div v-tippy="{content:'Click to copy'}" style="width:fit-content" @click.stop="copyItemId(item)">{{item.id}}</div>
						</template>
						<template v-slot:[`item.publishedTimestamp`]="{ item }">
							{{item.publishedTimestamp ? DateTime.fromISO(item.publishedTimestamp).toLocaleString(DateTime.DATETIME_FULL) : '--'}}
						</template>
						<template v-slot:[`item.action`]="{ item  }">
							<p-icon @click.stop="duplicate(item)" v-tippy content="Duplicate workflow" :size="20" color="secondary">content_copy</p-icon>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
		<!--v-dialog  v-if="editWorkflowId" :fullscreen="true" v-model="showDialog">
			<workflow-editor :id="editWorkflowId" @result="workflowUpdated($event)"></workflow-editor>
		</v-dialog-->
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import { useDisplay } from 'vuetify';
	import WorkflowService from '@/modules/workflow/WorkflowService';
	import WorkflowEditor from '@/modules/workflow/WorkflowEditor';
	import {DateTime} from 'luxon';

	export default defineComponent({
		name: 'WorkflowList',

		props: [],

		components: {ModuleHeader },

		data: function () {
			return {
				DateTime: DateTime,
				showDialog: false,
				editWorkflowId: null,
				workflows: [],
				workflowService: new WorkflowService(),
				filter: 'Active',
				textFilter: null,
				headers: [
					{ title: 'Name', key: 'name', align: 'start' },
					{ title: 'ID', key: 'id', align: 'start' },
					{ title: 'Published', key: 'publishedTimestamp', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: '', key: 'action', align: 'end' },
				],
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.getWorkflows);
			this.getWorkflows();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.getWorkflows);
		},

		methods: {
			async getWorkflows() {
				let result = await this.workflowService.getWorkflows(this.workspaceId);
				this.workflows.replace(result.data);
			},

			async duplicate(item){
				let result = await this.workflowService.duplicateWorkflow(this.workspaceId,item.id);
				this.workflows.push(result.data);
				this.editWorkflow(result.data.id);
			},

			async addWorkflow() {
				let result = await this.workflowService.createWorkflow(this.workspaceId);
				this.workflows.push(result.data);
				this.editWorkflow(result.data.id);
			},

			workflowRowClicked: function (event, row) {
				this.editWorkflow(row.item.id);
			},

			editWorkflow: function (id) {
				this.$store.state.globalModalController.openModal(WorkflowEditor,{id:id},false,true).then((res) => {
					if(res){
						this.workflowUpdated(res);
					}
				}).catch((err) => console.log(err));
			},

			workflowUpdated: function(workflow){
				let ix = this.workflows.findIndex(w => w.id === workflow.id);
				if(ix > -1){
					this.workflows.splice(ix,1,workflow);
				}
			},

			copyItemId: function(item){
				let copyText = document.getElementById('copyInput');
				copyText.type = 'text';
				copyText.value = item.id;
				copyText.select();
				document.execCommand('copy');
				copyText.type = 'hidden';

				this.$store.commit('info', 'Copied to clipboard');
			}
		},

		computed: {
			workspace: function(){
				return this.$store.getters.getSelectedWorkspace;
			},

			width: () => {
				return `${useDisplay().width.value}px`;
			},

			orgId: function () {
				return this.$store.getters.getOrgId;
			},

			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			filteredWorkflows: function () {
				let result = [...this.workflows];

				if (this.filter === 'Active') {
					result = result.filter((c) => c.status !== 'DEACTIVATED' && c.status !== 'DELETED');
				} else if (this.filter === 'Inactive') {
					result = result.filter((c) => c.status === 'DEACTIVATED' || c.status === 'DELETED');
				}

				if (this.textFilter) {
					return result.filter((c) => c.name.toLowerCase().includes(this.textFilter.toLowerCase()));
				} else {
					return result;
				}
			},
		},
	});
</script>

<style scoped lang="scss">
	#workflow-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
