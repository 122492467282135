import HttpClient from "@/services/HttpClient";

export default class ApiKeyService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getApiKeys(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/apiKeys`);
    }

    async createApiKey(workspaceId,name){
        return await this.client.post(`/workspaces/${workspaceId}/apiKeys?name=${encodeURIComponent(name)}`);
    }

    async deleteApiKey(workspaceId,id){
        return await this.client.delete(`/workspaces/${workspaceId}/apiKeys/${id}`);
    }
}