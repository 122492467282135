<template>
	<v-container>
		<v-row align="center" justify="center">
			<v-col cols="12" >
				<div class="row-format centered">
					<div class="auth-logo"><img alt="logo" src="/images/logo.png" /></div>
				</div>
			</v-col>
			<v-col cols="12" sm="6" md="6" v-if="!showConfirm">
				<v-card class="elevation-12">
					<v-toolbar>
						<v-toolbar-title>
							<h2>Password Reset</h2>
							<span>Please enter your email address and we will send a link to your inbox to reset your password.</span>
						</v-toolbar-title>
					</v-toolbar>
					<v-form ref="form" v-model="valid" lazy-validation>
						<v-card-text>
							<v-text-field autofocus v-model="email" :rules="emailRules" label="Email" required hide-details variant="outlined"></v-text-field>
						</v-card-text>
						<v-card-actions class="v-col-12">
							<v-btn block  color="primary" variant="flat" @click="resetPassword" :disabled="!valid">Request reset link</v-btn>
						</v-card-actions>
						<v-card-actions class="v-col-12">
							<v-btn block  color="secondary" variant="outlined" @click="$router.push('/sign-in')">Back to sign-in</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="6" v-if="showConfirm">
				<v-card class="elevation-12">
					<v-toolbar>
						<v-toolbar-title>
							<h2>Password Reset</h2>
							<span>Please check your email for your password reset link.</span>
						</v-toolbar-title>
					</v-toolbar>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';

	export default {
		name: 'RequestReset',
		data() {
			return {
				email: '',
				emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+\..+/.test(v) || 'Email must be valid'],
				valid: false,
				showConfirm: false,
			};
		},
		methods: {
			resetPassword() {
				if (this.$refs.form.validate()) {
					window.grecaptcha.ready(() => {
						window.grecaptcha
							.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'submit' })
							.then((reCaptchaToken) => {
								this.$store.commit('startLoading');
								AuthService.requestPasswordRest(this.email, reCaptchaToken)
									.then(() => {
										this.showConfirm = true;
									})
									.catch((err) => {
										console.log(err);
										this.$store.commit('error', err.response.data.message);
									})
									.finally(() => this.$store.commit('stopLoading'));
							});
					});
				}
			},
		},
		computed: {
			alertMessage() {
				return this.$store.state.alertMessage;
			},
		},
	};
</script>
