<template>
	<div class="mt-0" style="max-width: 95%">
		<v-container fluid class="ma-0 pa-0 text-left font-14">
			<v-row>
				<v-col cols="12" class="column-format gap-3">
					<div>
						<div>
							Databoost results can optionally be sent to to the dialer along with your uploaded leads. The mapping
							settings below will determine which columns the Databoost information is inserted into.
						</div>
					</div>
					<v-checkbox
							v-model="features.dataBoostEnabled"
							label="DataBoost enabled"
							hide-details :true-value="true" :false-value="false"
							density="compact"
							v-tippy="{ content: 'Enable DataBoost validation on list processing.' }"
					></v-checkbox>

					<div class="column-format gap-3" v-if="features.dataBoostEnabled">
						<v-autocomplete
							:items="mappingFields"
							label="Is valid"
							v-model="validationDataMapping.is_valid"
							item-value="columnId"
							item-title="columnName"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							clearable
							:disabled="isPremWorkspace"
							v-tippy="{ content: 'Boolean value - true/false if the phone number is valid.' }"
						></v-autocomplete>
						<v-autocomplete
							:items="mappingFields"
							label="Activity score"
							v-model="validationDataMapping.activity_score"
							item-value="columnId"
							item-title="columnName"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							clearable
							:disabled="isPremWorkspace"
							v-tippy="{ content: 'Numeric value between 0 and 100, with 100 being consistent activity in previous 12 months and 0 being a disconnected number.' }"
						></v-autocomplete>
						<v-autocomplete
							:items="mappingFields"
							label="Line type"
							v-model="validationDataMapping.line_type"
							item-value="columnId"
							item-title="columnName"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							clearable
							:disabled="isPremWorkspace"
							v-tippy="{ content: 'One of: Landline, Premium, NonFixedVOIP, Mobile, FixedVOIP, TollFree, Other, Voicemail.' }"
						></v-autocomplete>
						<v-autocomplete
							:items="mappingFields"
							label="Carrier"
							v-model="validationDataMapping.carrier"
							item-value="columnId"
							item-title="columnName"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							clearable
							:disabled="isPremWorkspace"
							v-tippy="{ content: 'The company that provides voice and/or data services for the phone number.' }"

						></v-autocomplete>
						<v-autocomplete
							:items="mappingFields"
							label="Prepaid"
							v-model="validationDataMapping.is_prepaid"
							item-value="columnId"
							item-title="columnName"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							clearable
							:disabled="isPremWorkspace"
							v-tippy="{ content: 'True if the phone is associated with a prepaid account.' }"
						></v-autocomplete>
						<v-autocomplete
							:items="mappingFields"
							label="Active"
							v-model="validationDataMapping.active"
							item-value="columnId"
							item-title="columnName"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							clearable
							:disabled="isPremWorkspace"
							v-tippy="{ content: 'True if the activity score is greater than 30 and the phone number is valid.' }"
						></v-autocomplete>
						<v-checkbox
							v-model="validationDataMapping.sendOnlyActiveLeads"
							label="Send only active phone numbers to the dialer"
							hide-details :true-value="true" :false-value="false"
							density="compact"
							:disabled="isPremWorkspace"
							v-tippy="{ content: 'Optionally deliver all phone numbers to the dialer regardless of the active status.' }"
						></v-checkbox>
						</div>
					<v-btn elevation="0" style="min-height: 40px" color="secondary" @click="save()">Save</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import DialerService from '@/modules/dialers/DialerService';

	export default defineComponent({
		name: 'CampaignDataBoost',

		props: ['workspaceId', 'campaign'],

		components: {},

		data: function () {
			return {
				validationDataMapping: { ...this.campaign.validationDataMapping },
				features:  { ...this.campaign.features },
				dialerService: new DialerService(),
				mappingFields: [],
			};
		},

		mounted() {
			this.getMappingFields();
		},

		beforeUnmount() {},

		methods: {
			save: function(){
				this.$emit('save',{
					features: this.features,
					validationDataMapping: this.validationDataMapping
				});
			},

			async getMappingFields() {
				let result = await this.dialerService.getMappingFieldsForCampaign(this.workspaceId, this.campaign.dialerCampaignId);
				this.mappingFields.replace(result.data);
			},
		},

		computed: {
			isPremWorkspace(){
				let workspace = this.$store.getters.getSelectedWorkspace;
				return workspace && workspace.dialerProviderType === 'PREM';
			}
		},
	});
</script>

<style scoped lang="scss"></style>
