import HttpClient from "@/services/HttpClient";

export default class NiceService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async authorize(workspaceId){
        return await this.client.post(`/workspaces/${workspaceId}/dialers/nice/authorize?redirectUrl=${encodeURIComponent(this.redirectUrl())}`);
    }

    async connect(workspaceId,code) {
        return await this.client.post(`/workspaces/${workspaceId}/dialers/nice/connect?authCode=${encodeURIComponent(code)}&redirectUrl=${encodeURIComponent(this.redirectUrl())}`);
    }

    redirectUrl(){
        return document.location.protocol + '//' + document.location.host + '/oauth/cxone';
    }
}