import HttpClient from "@/services/HttpClient";

export default class APNService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getWorkspaces() {
        return await this.client.get(`/workspaces`);
    }

    async connect(workspaceId,settings) {
        return await this.client.post(`/workspaces/${workspaceId}/dialers/apn/connect`,settings);
    }
}