<template>
	<div>
		<v-menu offset-x content-class="workspace-sub-menu" v-if="children.length">
			<template v-slot:activator="{ on }">
				<div class="settings-link row-format align-center pointer" v-on="on">
					<p-icon :size="20" color="secondary">{{ icon }}</p-icon>
					<div class="ml-1">{{ label }}</div>
					<v-icon small class="ml-auto" v-if="children.length">$chevronRight</v-icon>
				</div>
			</template>
			<div class="column-format">
				<settings-link
					v-for="child in children"
					:key="child.route"
					:label="child.label"
					:route="child.route"
					:icon="child.icon"
					:select-value="child.selectValue"
					@selected="$emit('selected', $event)"
				></settings-link>
			</div>
		</v-menu>

		<div class="settings-link row-format align-center pointer" v-else @click="handleClick">
			<p-icon :size="20" color="secondary">{{ icon }}</p-icon>
			<div class="ml-1 nowrap">{{ label }}</div>
		</div>
	</div>
</template>

<script>
	import PIcon from "@/components/PIcon";

	export default {
		name: 'SettingsLink',

		props: {
			pro: { type: Boolean, required: false, default: false },
			icon: { type: String, required: false },
			label: { type: String, required: true },
			route: { type: String, required: false },
			selectValue: { type: String, required: false },
			children: { type: Array, required: false, default: () => [] },
			beta: { type: Boolean, required: false, default: false },
		},

		components: { PIcon },

		data: function () {
			return {};
		},

		mounted() {},

		methods: {
			handleClick: function () {
				if (this.route) {
					this.$router.push(this.route);
				}
				if (this.selectValue) {
					this.$emit('selected', this.selectValue);
				}
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	.workspace-sub-menu {
		filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
		box-shadow: none !important;
		border-radius: 4px !important;
		background-color: rgb(var(--v-theme-white));
		padding: 4px 12px;
	}
</style>

<style scoped lang="scss">
	.settings-link {
		font-weight: 500;
		width: 100%;
		font-size: 14px;
		line-height: 14px;

		i {
			color: rgb(var(--v-theme-secondary)) !important
		}

		padding: 8px 0px;
		color: rgb(var(--v-theme-secondary));

		&:hover {
			color: rgb(var(--v-theme-primary));

			i {
				color: rgb(var(--v-theme-primary)) !important
			}
		}
	}
</style>
