<template>
	<div class="mt-4" style="width: 100%">
		<div class="row-format gap-2">
			<v-text-field
				v-model="search"
				density="compact"
				hide-details
				type="number"
				placeholder="Search by number ex: 5555551212"
				class="mb-2"
				append-inner-icon="filter_list"
			></v-text-field>
			<v-btn elevation="0" color="secondary" @click="getCampaignLeads()" :disabled="!search || search.length < 3" style="min-height: 40px">Search</v-btn>

			<v-btn @click="downloadCampaignLeads" icon elevation="0" style="max-height: 40px" color="gray_50" v-tippy="{content: 'Download all leads in campaign'}"><v-icon>download</v-icon></v-btn>
		</div>

		<v-data-table :items="leads" :items-per-page="-1" :headers="headers" id="lead-table">
			<template v-slot:[`item.loaded`]="{ item }">
				<span :class="item.status === 'AGING' ? 'aging' : ''">{{
						DateTime.fromISO(item.loaded).toLocaleString(DateTime.DATE_FULL)
					}}</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import PDateTime from '@/modules/utils/PDateTime';
	import CampaignLeadService from '@/modules/leads/CampaignLeadService';
	import ConfirmModal from "@/components/ConfirmModal";

	export default defineComponent({
		name: 'CampaignLeads',

		props: ['workspaceId', 'campaign'],

		components: {},

		data: function () {
			return {
				campaignLeadService: new CampaignLeadService(),
				search: null,
				leads: [],
				DateTime: PDateTime,

				headers: [
					{ title: 'Phone', key: 'phone', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'Zip', key: 'zip', align: 'start' },
					{ title: 'Caller ID', key: 'callerId', align: 'start' },
					{ title: 'Loaded', key: 'loaded', align: 'start' },
				],
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			downloadCampaignLeads: function(){
				let binding = {
					bodyText: 'Would you like to download the leads in this campaign?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.$store.commit('startLoading');
						this.campaignLeadService.downloadCampaignLeads(this.workspaceId,this.campaign.id).then((res) => {
							let fileURL = window.URL.createObjectURL(res.data);
							let fileLink = document.createElement('a');
							fileLink.href = fileURL;
							fileLink.setAttribute(
									'download',
									`${this.campaign.name}.csv`
							);
							document.body.appendChild(fileLink);
							fileLink.click();
						}).finally(() => {
							this.$store.commit('stopLoading');
						})
					}
				})
			},

			async getCampaignLeads() {
				this.$store.commit('startLoading');
				try {
					let result = await this.campaignLeadService.getCampaignLeads(this.workspaceId, this.campaign.id, this.search);
					this.leads.replace(result.data);
					this.sortList();
				}catch(err){
					this.$store.commit('error',err.response.data.message);
				}finally{
					this.$store.commit('stopLoading');
				}
			},

			sortList: function () {
				this.leads.sort((a, b) => b.phone.localeCompare(a.phone));
			},
		},

		computed: {},
	});
</script>

<style scoped lang="scss">
	.scrollable {
		max-height: calc(100vh - 200px);
		overflow-y: auto;

		.highlight {
			background-color: rgb(var(--v-theme-gray_10));
		}
	}

	#lead-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
