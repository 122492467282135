<template>
	<div style="background-color: rgb(var(--v-theme-white)); border-radius: 4px" class="pa-4">
		<div class="row-format align-center pb-2" style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))">
			<div class="brand-medium font-18">Enable MFA</div>
			<div class="ml-auto row-format align-center">
				<p-icon class="pointer" @click="$emit('result')">close</p-icon>
			</div>
		</div>
		<div class="pt-3 pb-2">
			<v-form ref="form" v-model="valid" lazy-validation @keydown.enter.exact.prevent="verifyMfaCode()">
				<div v-if="qrCode" class="column-format gap-2 align-center">
					<div>Scan the image below using Google Authenticator or Twilio Authy and enter the the code to continue.</div>
					<img :src="qrCode" style="width: 300px; height: 300px" alt="MFA Code" />
					<v-text-field
						autofocus
						type="number"
						v-model="mfaCode"
						:rules="mfaCodeRules"
						label="6-digit code"
						required
						hide-details
						variant="outlined"
						maxlength="6"
						@input="checkMfaSubmit" style="width:100%"
					></v-text-field>
				</div>
				<div v-else>Generating code...</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import AuthService from '@/modules/auth/AuthService';
	import UtilityService from "@/modules/utilities/UtilityService";

	export default defineComponent({
		name: 'EnableMFA',

		props: [],

		components: {},

		data: function () {
			return {
				utilityService: new UtilityService(),
				valid: false,
				mfaCode: null,
				mfaCodeRules: [(v) => !!v || 'MFA code is required', (v) => /^\d{6}$/.test(v) || 'MFA code must be 6 digits'],
				qrCode: null,
			};
		},

		mounted() {
			this.generateQRCode();
		},

		beforeUnmount() {},

		methods: {
			async checkMfaSubmit() {
				if (this.mfaCode.length === 6) {
					await this.verifyMfaCode();
				}
			},

			async verifyMfaCode() {
				try {
					await this.utilityService.ping(); //ensure good auth token
					await AuthService.verifyMfa(this.mfaCode);
					this.$store.commit('success','Two factor authentication enabled with MFA Device');
					this.$emit('result', true);
				} catch (err) {
					this.$store.commit('error', `MFA Code Is Not Valid: ${err.response?.data?.message}`);
				}
			},

			async generateQRCode() {
				try {
					await this.utilityService.ping(); //ensure good auth token
					this.qrCode = await AuthService.generateMfa();
				} catch (err) {
					this.$store.commit('error', `Error generating MFA QR Code: ${err.response?.data?.message}`);
				}
			},
		},

		computed: {},
	});
</script>

<style scoped lang="scss"></style>
