import HttpClient from '@/services/HttpClient';

export default class RecordSetService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getRecordSets(workspaceId) {
		return await this.client.get(`/workspaces/${workspaceId}/aegis/recordSets`);
	}

	async getRecordSet(workspaceId, id) {
		return await this.client.get(`/workspaces/${workspaceId}/aegis/recordSets/${id}`);
	}

	async createRecordSet(workspaceId, recordSet) {
		return await this.client.post(`/workspaces/${workspaceId}/aegis/recordSets`, recordSet);
	}

	async updateRecordSet(workspaceId, id, recordSet) {
		return await this.client.put(`/workspaces/${workspaceId}/aegis/recordSets/${id}`, recordSet);
	}

	async deleteRecordSet(workspaceId, id) {
		return await this.client.delete(`/workspaces/${workspaceId}/aegis/recordSets/${id}`);
	}

	async searchNumberInRecordSet(workspaceId, id, number) {
		return await this.client.get(`/workspaces/${workspaceId}/aegis/recordSets/${id}/record?number=${encodeURIComponent(number)}`);
	}

	async addNumberToRecordSet(workspaceId, id, savedNumber) {
		return await this.client.post(`/workspaces/${workspaceId}/aegis/recordSets/${id}/record`, savedNumber);
	}

	async removeNumberFromRecordSet(workspaceId, id, number) {
		return await this.client.delete(`/workspaces/${workspaceId}/aegis/recordSets/${id}/record?number=${encodeURIComponent(number)}`);
	}

	async uploadRecords(workspaceId, id, file) {
		let formData = new FormData();
		formData.append('file', file);

		return await this.client.post(`/workspaces/${workspaceId}/aegis/recordSets/${id}/bulkAdd`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	async deleteRecords(workspaceId, id, file) {
		let formData = new FormData();
		formData.append('file', file);

		return await this.client.post(`/workspaces/${workspaceId}/aegis/recordSets/${id}/bulkRemove`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}
}
