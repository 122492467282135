<template>
	<div style="background-color: rgb(var(--v-theme-white)); border-radius: 4px" class="pa-2">
		<div class="row-format align-center pb-2" style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))">
			<div class="brand-medium font-18">Edit node</div>
			<div class="ml-auto row-format align-center">
				<v-btn elevation="0" color="secondary" @click.stop="save()"> OK </v-btn>
			</div>
		</div>
		<div class="pt-3 pb-2">
			<component :is="node.type" :node="localNode" :workflowId="workflowId" @updated="updated($event)"></component>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import Reject from '@/modules/workflow/config/Reject';
	import Accept from '@/modules/workflow/config/Accept';
	import Return from '@/modules/workflow/config/Return';
	import ActiveProspect from '@/modules/workflow/config/ActiveProspect';
	import CallerId from '@/modules/workflow/config/CallerId';
	import DataBoost from '@/modules/workflow/config/DataBoost';
	import Decision from '@/modules/workflow/config/Decision';
	import KnownLitigator from '@/modules/workflow/config/KnownLitigator';
	import RecordSet from '@/modules/workflow/config/RecordSet';
	import DoNotCall from '@/modules/workflow/config/DoNotCall';
	import StateDialing from '@/modules/workflow/config/StateDialing';
	import TimeOfDay from "@/modules/workflow/config/TimeOfDay";
	import Velocity from "@/modules/workflow/config/Velocity";
	import Verisk from "@/modules/workflow/config/Verisk";
	import StateOfEmergency from "@/modules/workflow/config/StateOfEmergency";
	import StateDoNotCall from "@/modules/workflow/config/StateDoNotCall";
	import Webhook from "@/modules/workflow/config/Webhook";
	import WorkflowTransfer from "@/modules/workflow/config/WorkflowTransfer";
	import ReassignedNumberDatabase from "@/modules/workflow/config/ReassignedNumberDatabase";

	export default defineComponent({
		name: 'NodeConfigModal',

		props: ['node','workflowId'],

		components: {
			Reject,
			Accept,
			Return,
			ActiveProspect,
			CallerId,
			DataBoost,
			Decision,
			KnownLitigator,
			RecordSet,
			DoNotCall,
			StateDialing,
			TimeOfDay,
			Velocity,
			Verisk,
			StateOfEmergency,
			StateDoNotCall,
			Webhook,
			WorkflowTransfer,
			ReassignedNumberDatabase
		},

		data: function () {
			return {
				localNode: JSON.parse(JSON.stringify(this.node)),
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			save: function () {
				this.$emit('result', this.localNode);
			},

			updated: function (node) {
				this.localNode = node;
			},
		},

		computed: {},
	});
</script>

<style scoped lang="scss"></style>
