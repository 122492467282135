<template>
	<div class="workflow-node-parent" ref="el" @dblclick="handleClick" style="position: relative;">
		<p-icon :size="24" color="#3d85c6">move_down</p-icon>
		<div>Workflow handoff</div>
		<div class="workflow-node-label-options" v-if="label">{{ label }}</div>
	</div>
</template>

<script>
	import { onMounted, onBeforeUnmount, ref, getCurrentInstance, nextTick } from 'vue';
	import { useStore } from 'vuex';
	import PIcon from '@/components/PIcon';

	export default {
		name: 'WorkflowTransfer',
		inputs: 1,
		outputs: 1,

		components: { PIcon },

		setup() {
			const el = ref(null);
			const label = ref('');
			const nodeId = ref('');
			const store = useStore();
			const df = getCurrentInstance().appContext.config.globalProperties.$df.value;

			// Method defined within setup
			const handleClick = () => {
				store.state.eventBus.$emit('workflow-node', nodeId.value);
			};

			const dataUpdated = (event) => {
				if (event.id === nodeId.value) {
					label.value = event.label;
				}
			};

			// Lifecycle hook
			onMounted(async () => {
				await nextTick();
				nodeId.value = el.value.parentElement.parentElement.id.slice(5);

				const data = df.getNodeFromId(nodeId.value).data;
				label.value = data.label;

				store.state.eventBus.$on('df-node-updated', dataUpdated);
			});

			onBeforeUnmount(() => {
				store.state.eventBus.$off('df-node-updated', dataUpdated);
			});

			// Make handleClick available to the template
			return {
				el,
				handleClick,
				label,
			};
		},
	};
</script>

<style scoped lang="scss"></style>
