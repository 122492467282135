import HttpClient from "@/services/HttpClient";

export default class DownloadService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getDownloads(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/reports`);
    }

    async getDownloadUrl(workspaceId,id) {
        return await this.client.get(`/workspaces/${workspaceId}/reports/${id}/url`);
    }

    async deleteDownload(workspaceId,id) {
        return await this.client.delete(`/workspaces/${workspaceId}/reports/${id}`);
    }

}