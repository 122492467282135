<template>
	<div :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result')">close</p-icon>
				<div class="brand-medium font-18">Edit user</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="$emit('result')"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()">delete</p-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body" v-if="user">
			<div class="column-format gap-3 pt-3">
				<v-text-field label="First" :disabled="disabled" v-model="user.firstName" density="compact" variant="outlined" hide-details></v-text-field>
				<v-text-field label="Last"  :disabled="disabled" v-model="user.lastName" density="compact" variant="outlined" hide-details></v-text-field>
				<v-text-field label="Email" append-inner-icon="lock" :disabled="true"  v-model="user.email" density="compact" variant="outlined" hide-details></v-text-field>
				<maz-phone-number-input :disabled="disabled"
						:translations="{phoneInput: {placeholder:'Two Factor Auth Phone', example: ''}}"
						:only-countries="['US','CA','EC']"
						v-model="user.phone">
				</maz-phone-number-input>
			</div>
			<div class="mt-3">
				<v-select label="User type" :disabled="user.id === $store.state.user.id" v-model="user.userType" density="compact" variant="outlined" :items="userTypes" hide-details>
				</v-select>
			</div>
			<div class="text-left font-14 mt-4">
				<div>Last seen: {{user.lastSeen ? PDateTime.fromISO(user.lastSeen).toLocaleString(PDateTime.DATETIME_MED) : ' -- '}}</div>
				<div>Created: {{PDateTime.fromISO(user.created).toLocaleString(PDateTime.DATETIME_MED)}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ConfirmModal from "@/components/ConfirmModal";
	import UserService from "@/modules/team/UserService";
	import PDateTime from "@/modules/utils/PDateTime";
	import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput"

	export default defineComponent({
		name: 'UserEdit',
		isRightModal: true,

		props: ['id'],

		components: {MazPhoneNumberInput},

		data: function () {
			return {
				user: null,
				userService: new UserService(),
				PDateTime: PDateTime
			};
		},

		mounted() {
			this.getUser();
		},

		beforeUnmount() {},

		methods: {
			handleClose: function(){
				this.$emit('result',this.user);
			},

			getUser: function(){
				this.userService.getUser(this.id).then((res) => {
					this.user = res.data;
				}).catch((err) => this.$store.commit('error',err.response.data.message));
			},

			confirmDelete: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to delete ${this.user.email}?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.userService
								.deleteUser(this.user.id)
								.then(() => {
									this.$store.commit('success', 'User has been deleted');
									this.user.deleted = true;
									this.$emit('result',this.user);
								})
								.catch((err) => this.$store.commit('error', err.response.data.message));
					}
				});
			},
		},

		computed: {
			disabled: function(){
				if(this.user) {
					return this.user.id !== this.$store.state.user.id
				}else{
					return true;
				}
			},

			userTypes: function(){
				let result = [];
				let userType = this.$store.state.user?.userType;
				if(userType === 'Owner'){
					result.push(... ['Owner','Admin']);
				}else if(userType === 'Admin'){
					result.push(... ['Admin']);
				}
				return result;
			}
		},
	});
</script>

<style scoped lang="scss">
	.desktop {
		min-width: 650px;
		width: 650px;
	}
</style>
