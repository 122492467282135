import HttpClient from "@/services/HttpClient";

export default class ProtectService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getProtectMetrics(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/protect/metrics`);
    }

    async getProtectNumbers(workspaceId, search = null,page= 0,perPage = 10000){
        if(search){
            return await this.client.get(`/workspaces/${workspaceId}/protect?page=${page}&size=${perPage}&search=${encodeURIComponent(search)}&sort=number`);
        }else{
            return await this.client.get(`/workspaces/${workspaceId}/protect?page=${page}&size=${perPage}&sort=number`);
        }
    }

    downloadProtectNumbers(workspaceId, search = null) {
        let params = {};

        if(search){
            params.search = search;
        }

        return this.client
            .get(`/workspaces/${workspaceId}/protect/download`, {
                params: params,
                responseType: 'blob',
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    async registerNewNumbers(workspaceId, businessRecordId, file) {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('businessRecordId',businessRecordId);

        return await this.client.post(`/workspaces/${workspaceId}/protect/register`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }


    async deRegisterNumbers(workspaceId, file) {
        let formData = new FormData();
        formData.append('file', file);

        return await this.client.post(`/workspaces/${workspaceId}/protect/deRegister`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }


}