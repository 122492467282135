<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="">
				<module-header name="Workspaces" add-label="Create workspace" @add-new="addWorkspace"></module-header>
			</v-col>
			<v-col cols="12">
				<v-data-table
					id="workspace-table"
					:items="workspaces"
					v-model:items-per-page="itemsPerPage"
					:headers="headers"
					class="pointer"
					:style="`max-width: calc(${width} - 40px)`"
					@click:row="editWorkspace"
				>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import WorkspaceService from '@/modules/workspaces/WorkspaceService';
	import WorkspaceEdit from '@/modules/workspaces/WorkspaceEdit';
	import ModuleHeader from "@/components/ModuleHeader";
	import { useDisplay } from 'vuetify';

	export default defineComponent({
		name: 'WorkspaceList',

		props: ['openWorkspace'],

		components: {ModuleHeader },

		data: function () {
			return {
				workspaceService: new WorkspaceService(),
				workspaces: [],
				itemsPerPage: -1,
				headers: [
					{ title: 'Name', key: 'name', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'Dialer', key: 'dialerProviderType', align: 'start' },
					{ title: '', key: 'action', align: 'end' },
				],
			};
		},

		mounted() {
			this.getWorkspaces();
		},

		beforeUnmount() {},

		methods: {
			async getWorkspaces() {
				let result = await this.workspaceService.getWorkspaces();
				let data = result.data.filter(w => w.status === 'ACTIVE');
				this.workspaces.replace(data);
				if(this.openWorkspace){
					this.editWorkspaceById(this.openWorkspace);
					window.history.replaceState({}, document.title, this.$route.path);
				}
			},

			addWorkspace() {
				this.$store.state.globalModalController.openModal(WorkspaceEdit, {id:null}).then((res) => {
					if(res){
						this.workspaces.push(res);
					}
				});
			},

			editWorkspace: function (event, row) {
				this.editWorkspaceById(row.item.id);
			},

			editWorkspaceById: function (workspaceId) {
				let binding = {
					id: workspaceId
				};
				this.$store.state.globalModalController.openModal(WorkspaceEdit, binding).then((res) => {
					if (res) {
						let ix = this.workspaces.findIndex((u) => u.id === binding.id);
						if (res.deleted) {
							this.workspaces.splice(ix, 1);
						} else {
							this.workspaces.splice(ix, 1, res);
						}
					}
				});
			},
		},

		computed: {
			width: () => {
				return `${useDisplay().width.value}px`;
			},
		},
	});
</script>

<style scoped lang="scss">
	#workspace-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
