<template>
	<div :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result')">close</p-icon>
				<div class="brand-medium font-18">Organization info</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click="saveOrganization"> Save </v-btn>
				</div>
			</div>
		</div>
		<div class="panel-modal-body">
			<div class="column-format gap-3 pt-2" v-if="organization">
				<v-text-field
					label="Name"
					class="my-text-field"
					v-model="organization.name"
					persistent-placeholder
					density="compact"
					variant="outlined"
					hide-details
				>
				</v-text-field>
				<v-text-field
					label="Billing address"
					class="my-text-field"
					v-model="organization.address1"
					persistent-placeholder
					density="compact"
					variant="outlined"
					hide-details
				>
				</v-text-field>
				<v-text-field
					label="Suite, office, etc"
					class="my-text-field"
					v-model="organization.address2"
					persistent-placeholder
					density="compact"
					variant="outlined"
					hide-details
				>
				</v-text-field>
				<v-text-field
					label="City"
					class="my-text-field"
					v-model="organization.city"
					persistent-placeholder
					density="compact"
					variant="outlined"
					hide-details
				>
				</v-text-field>
				<v-text-field
					label="State"
					class="my-text-field"
					v-model="organization.locality"
					persistent-placeholder
					density="compact"
					variant="outlined"
					hide-details
				>
				</v-text-field>
				<v-text-field
					label="Zip"
					class="my-text-field"
					v-model="organization.postal"
					persistent-placeholder
					density="compact"
					variant="outlined"
					hide-details
				>
				</v-text-field>
				<v-text-field
						label="Tax id"
						class="my-text-field"
						v-model="organization.taxId"
						persistent-placeholder
						density="compact"
						variant="outlined"
						hide-details
				>
				</v-text-field>
				<v-text-field
						label="Website"
						class="my-text-field"
						v-model="organization.website"
						persistent-placeholder
						density="compact"
						variant="outlined"
						hide-details
				>
				</v-text-field>
				<v-text-field
						label="Primary phone"
						class="my-text-field"
						v-model="organization.phone"
						persistent-placeholder
						density="compact"
						variant="outlined"
						hide-details
				>
				</v-text-field>
				<v-text-field
						v-if="isSuperAdmin"
						label="Internal support email"
						class="my-text-field"
						v-model="organization.supportEmail"
						persistent-placeholder
						density="compact"
						variant="outlined"
						hide-details
				>
				</v-text-field>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import OrganizationService from '@/modules/organization/OrganizationService';

	export default defineComponent({
		name: 'Organization',
		isRightModal: true,

		props: [],

		components: {},

		data: () => {
			return {
				orgService: new OrganizationService(),
				organization: null,
			};
		},

		async mounted() {
			await this.getOrganization();
		},

		beforeUnmount() {},

		methods: {
			handleClose: function(){
				this.$emit('result',this.organization);
			},

			async getOrganization() {
				try {
					this.organization = (await this.orgService.getOrganization()).data;
				} catch (error) {
					this.$store.commit('error', error.response.data.message)
				}
			},

			async saveOrganization(){
				try {
					this.organization = (await this.orgService.updateOrganization(this.organization)).data;
					this.$store.commit('success', 'Organization settings saved');
					this.$emit('result', this.organization);
				} catch (error) {
					this.$store.commit('error', error.response.data.message)
				}
			},
		},

		computed: {
			isSuperAdmin: function () {
				return this.$store.state.user.userType === 'SuperAdmin';
			},
		},
	});
</script>

<style scoped lang="scss">
	.my-text-field :deep(.v-input__prepend) {
		text-align: right !important;
		min-width: 140px; /* Change the width to your desired value */
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	.desktop {
		min-width: 650px;
		width: 650px;
	}
</style>
