<template>
	<div>
		<v-container class="pa-0 ma-0" style="">
			<v-row dense>
				<v-col cols="12" class="text-left pa-4">
					<settings-link  label="My profile" route="/profile" icon="person"></settings-link>
					<settings-link icon="corporate_fare" label="Organization" select-value="organization" @selected="editOrganization"></settings-link>
					<settings-link icon="support" label="Support center" select-value="support" @selected="openSupport"></settings-link>
					<settings-link icon="webhook" label="API Documentation" select-value="api" @selected="openApiDocs"></settings-link>
					<settings-link label="Log out" select-value="logout" icon="logout" @selected="logout"></settings-link>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import {defineComponent} from 'vue';
import SettingsLink from "@/modules/nav/SettingsLink";
import ConfirmModal from "@/components/ConfirmModal";
import AuthService from "@/modules/auth/AuthService";
import Organization from "@/modules/organization/Organization";

export default defineComponent({
	name: 'UserMenu',

	props: [],

	components: {SettingsLink},

	data: function () {
		return {};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {
		editOrganization: function(){
			this.$store.state.globalModalController.openModal(Organization,{});
		},

		openSupport: function(){
			window.open('https://support.purecallerid.com/','_blank');
		},

		openApiDocs: function(){
			window.open('https://support.purecallerid.com/hc/en-us/categories/17369364417051-API-Documentation','_blank');
		},

		logout: function () {
			let binding = {
				bodyText: 'Confirm log out?',
			};
			this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
				if (res) {
					return AuthService.logout();
				}
			});
		},
	},

	computed: {},
});
</script>

<style scoped lang="scss"></style>
