<template>
	<div :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="handleClose()">close</p-icon>
				<div class="brand-medium font-18">Edit webhook</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="save()" :disabled="!isValid"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()">delete</p-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body" v-if="webhook">
			<v-form v-model="isValid">
				<div class="column-format gap-3 pt-3">
					<v-text-field
						label="Name"
						v-model="webhook.name"
						:rules="[rules.required]"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<v-text-field
						label="URL"
						v-model="webhook.url"
						:rules="[rules.required]"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						placeholder="https://www.yourdomain.com/endpoint"
					></v-text-field>
					<v-select
						v-model="webhook.eventType"
						label="Event type"
						:items="eventTypes"
						item-title="label"
						item-value="value"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					>
					</v-select>
					<v-select
						v-model="webhook.status"
						label="Status"
						:items="statusTypes"
						item-title="label"
						item-value="value"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					>
					</v-select>
					<v-select
						v-model="webhook.webhookAuth.authType"
						label="Authentication type"
						:items="authTypes"
						item-title="label"
						item-value="value"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					>
					</v-select>
					<div class="row-format gap-2" v-if="webhook.webhookAuth.authType === 'Basic'">
						<v-text-field
							label="User name"
							v-model="webhook.webhookAuth.username"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
						></v-text-field>
						<v-text-field
							label="Password"
							v-model="webhook.webhookAuth.password"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							type="password"
						></v-text-field>
					</div>
					<div class="row-format gap-2" v-if="webhook.webhookAuth.authType === 'Bearer'">
						<v-textarea
							label="Bearer token"
							v-model="webhook.webhookAuth.value"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							rows="3"
							no-resize
						></v-textarea>
					</div>
					<div class="row-format gap-3" v-if="webhook.webhookAuth.authType === 'Custom'">
						<v-text-field
							style="max-width: 200px"
							label="HTTP header name"
							v-model="webhook.webhookAuth.headerName"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
						></v-text-field>
						<v-text-field
							label="HTTP header value"
							v-model="webhook.webhookAuth.value"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
						></v-text-field>
					</div>
				</div>
			</v-form>
			<div class="event-box mt-5" v-if="webhook.events.length">
				<v-container fluid class="text-left pa-0 ma-0 font-14">
					<v-row dense style="border-bottom: 1px solid rgb(var(--v-theme-gray_20))">
						<v-col cols="2" class="font-weight-bold">Date</v-col>
						<v-col cols="2" class="font-weight-bold">Category</v-col>
						<v-col cols="8" class="font-weight-bold">Message</v-col>
					</v-row>
					<v-row
						v-for="(event, index) in webhook.events"
						:key="index"
						dense
						style="border-bottom: 1px solid rgb(var(--v-theme-gray_20))"
					>
						<v-col cols="2">{{ DateTime.fromISO(event.timestamp).toLocaleString(DateTime.DATETIME_SHORT) }}</v-col>
						<v-col cols="2">{{ event.type }}</v-col>
						<v-col cols="8">{{ event.message }}</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ConfirmModal from '@/components/ConfirmModal';
	import { DateTime } from 'luxon';
	import WebhookService from '@/modules/webhooks/WebhookService';

	export default defineComponent({
		name: 'WebhookEdit',
		isRightModal: true,

		props: ['id'],

		components: {},

		data: function () {
			return {
				isValid: false,
				webhook: null,
				webhookService: new WebhookService(),
				DateTime: DateTime,
				rules: {
					required: (value) => !!value || 'Required.',
					// eslint-disable-next-line
					https: (value) => /^https:\/\/(?:www\.)?[\w-]+\.\w{2,}(?:\.\w{2})?(?:[\/\?#][^\s]*)?$/.test(value) || 'Must be a valid HTTPS URL with a domain and TLD.',
				},

				eventTypes: [
					{ label: 'Campaign list result', value: 'CampaignList' },
					{ label: 'Sentry replacement', value: 'Sentry' },
					{ label: 'UCProtect SPAM Risk', value: 'UCProtect' },
					{ label: 'Aegis workflow webhook', value: 'AegisEvent' },
					{ label: 'Inbound call backs', value: 'InboundCall' },
					{ label: 'Inbound SMS messages', value: 'SMSMessage' },
					{ label: 'Phone number provisioned', value: 'PhoneProvisioned' },
					{ label: 'Phone number deactivated', value: 'PhoneDeactivated' },
				],

				statusTypes: ['ACTIVE', 'DISABLED'],
				authTypes: ['None', 'Basic', 'Bearer', 'Custom'],
			};
		},

		mounted() {
			this.eventTypes.sort((a,b) => a.label.localeCompare(b.label));
			this.initWebhook();
		},

		beforeUnmount() {},

		methods: {
			handleClose: function () {
				this.$emit('result', this.webhook);
			},

			initWebhook: function () {
				if (this.id) {
					this.getWebhook();
				} else {
					this.webhook = {
						eventType: 'CampaignList',
						status: 'ACTIVE',
						name: null,
						url: null,
						events: [],
						webhookAuth: {
							authType: 'None',
							headerName: null,
							username: null,
							value: null,
							password: null,
						},
					};
				}
			},

			getWebhook: function () {
				this.webhookService
					.getWebhook(this.workspaceId, this.id)
					.then((res) => {
						this.webhook = res.data;
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			async save() {
				if (this.webhook.id) {
					let result = await this.webhookService.updateWebhook(this.workspaceId, this.webhook.id, this.webhook);
					this.webhook = result.data;
				} else {
					let result = await this.webhookService.createWebhook(this.workspaceId, this.webhook);
					this.webhook = result.data;
				}

				this.$store.commit('success', 'Saved');
			},

			confirmDelete: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to delete this webhook?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.webhookService
							.deleteWebhook(this.workspaceId, this.webhook.id)
							.then(() => {
								this.$store.commit('success', 'Webhook has been deleted');
								this.webhook.deleted = true;
								this.$emit('result', this.webhook);
							})
							.catch((err) => this.$store.commit('error', err.response.data.message));
					}
				});
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	.desktop {
		min-width: 650px;
		width: 650px;
	}
</style>
