<template>
	<div :class="`row-format align-center nav-link gap-2 ${active ? 'active' : ''}`" @click="navClicked">
		<p-icon v-if="!expanded" :size="20" :color="active ? 'secondary' : 'white'" class="pointer py-1 pl-1" :content="label" v-tippy="{placement : 'right',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }">{{icon}}</p-icon>
		<p-icon v-else :size="20" :color="active ? 'secondary' : 'white'" class="pointer py-1 pl-1">{{icon}}</p-icon>
		<div class="label" v-if="expanded">{{label}}</div>
	</div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'NavLink',

	props: ['icon','label','route','selectValue'],

	components: {},

	data: function () {
		return {

		};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {
		navClicked: function(){
			if(this.selectValue){
				this.$emit('selected',this.selectValue);
			}else {
				this.$router.push(this.route);
			}
		}
	},

	computed: {
		expanded: function(){
			return this.$store.state.leftNavExpanded;
		},

		active: function(){
			return !!this.$route.path.startsWith(this.route);
		},
	},
});
</script>

<style scoped lang="scss">
	.nav-link {
		padding: 8px 8px 8px 8px;
		cursor: pointer;
		color: rgb(var(--v-theme-white));
		.label {
			font-size: 16px;
			white-space: nowrap;
			overflow: hidden;
		}

		&:hover {
			//color: rgb(var(--v-theme-tertiary));
		}
	}

	.nav-link.active {
		background-color: rgb(var(--v-theme-white));
		color: rgb(var(--v-theme-secondary));
		//border-right: 1px solid rgb(var(--v-theme-secondary));
	}
</style>
