<template>
	<v-row class="module-header row-format align-center">
		<v-col class="text-left row-format align-center gap-2" cols="12" :sm="addLabel ? 6 : 12" :md="addLabel ? 6 : 12">
			<p-icon v-if="backButton" class="pointer mt-1" @click.stop="$router.push(backButton)">arrow_back</p-icon>
			<h3>
				<span v-if="backButton" class="gray_50"> | </span>
				<v-menu v-model="menuOpen" v-if="workspaceSelector" :disabled="workspaceSelectorDisabled">
					<template v-slot:activator="{ props }"
						><span :class="`${!workspaceSelectorDisabled ? 'pointer workspace-selector' : ''}`" v-bind="props">{{
							selectedWorkspaceName
						}}</span></template
					>
					<div class="column-format more-menu">
						<div
							v-for="workspace in workspaces"
							:key="workspace.id"
							class="more-menu-item"
							@click="selectWorkspace(workspace)"
						>
							{{ workspace.name }}
						</div>
					</div>
				</v-menu>
				<span v-if="workspaceSelector" class="gray_50"> | </span>{{ name }}
			</h3>
		</v-col>
		<v-col cols="12" sm="6" md="6" :class="!mobile ? 'row-format' : 'mobile-row'" class="align-center gap-1 text-right" v-if="addLabel">
			<div class="ml-auto row-format gap-1">
				<slot  name="filter"></slot>
				<v-btn elevation="0" style="min-height: 40px" color="secondary" @click="$emit('add-new')" :disabled="disabled">{{ addLabel }}</v-btn>
				<slot name="additional"></slot>
			</div>
		</v-col>
	</v-row>
</template>

<script>
	import { defineComponent } from 'vue';
	import { useDisplay } from 'vuetify';
	import PIcon from "@/components/PIcon";
	export default defineComponent({
		name: 'ModuleHeader',

		props: {
			name: String,
			addLabel: String,
			backButton: String,
			workspaceSelector: {
				type: Boolean,
				default: false,
				required: false,
			},
			disableWorkspaceSelector: {
				type: Boolean,
				default: false,
				required: false,
			},
			disabled: {
				type: Boolean,
				default: false,
				required: false,
			},
		},

		emits: ['add-new'],

		components: {PIcon},

		data: function () {
			return {
				menuOpen: false,
			};
		},

		mounted() {
		},

		beforeUnmount() {},

		methods: {
			selectWorkspace: function (workspace) {
				this.$store.commit('setSelectedWorkspace', workspace.id);
			},
		},

		computed: {
			mobile: () => useDisplay().mobile.value,
			workspaces: function () {
				return this.$store.state.workspaces;
			},

			workspaceSelectorDisabled: function(){
				return this.workspaces.length === 1 || this.disableWorkspaceSelector
			},

			selectedWorkspaceName: function () {
				let workspace = this.workspaces.find((w) => w.id === this.$store.state.selectedWorkspace);
				if (workspace) {
					return workspace.name;
				} else {
					return '[Select workspace]';
				}
			},
		},
	});
</script>

<style scoped lang="scss">
	.module-header {
		.mobile-row:deep(.v-input) {
				margin-bottom: 10px;
		}
		.workspace-selector {
				&:hover {
					color: rgb(var(--v-theme-primary));
				}
			}
	}
</style>
