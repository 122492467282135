<template>
	<div id="ftc-san-edit" :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`" v-if="rndConnection">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result')">close</p-icon>
				<div class="brand-medium font-18">{{ rndConnection.id ? 'Edit' : 'Create' }} RND Connection</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="save()" :disabled="!isValid"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()">delete</p-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body">
			<v-form v-model="isValid">
				<div class="column-format gap-2 mt-2">
					<v-text-field
						label="Company Id"
						v-model="rndConnection.companyNumber"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<v-text-field
						label="Company name"
						v-model="rndConnection.companyName"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<v-textarea
						label="API Token"
						v-model="rndConnection.apiToken"
						rows="3"
						:rules="rndConnection.id ? [] : required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-textarea>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ConfirmModal from '@/components/ConfirmModal';
	import RndConnectionService from '@/modules/watchTower/RndService';

	export default defineComponent({
		name: 'RndConnectionDetail',
		isRightModal: true,

		props: ['id'],

		components: {},

		data: function () {
			return {
				isValid: false,
				rndConnection: null,
				rndConnectionService: new RndConnectionService(),
				required: [(v) => !!v || 'Field is required'],
			};
		},

		mounted() {
			this.getRndConnection();
		},

		beforeUnmount() {},

		methods: {
			async getRndConnection() {
				if (this.id) {
					let result = await this.rndConnectionService.getRndConnection(this.workspaceId, this.id);
					this.rndConnection = result.data;
				} else {
					this.rndConnection = this.newRndConnection();
				}
			},

			async save() {
				if (!this.rndConnection.id) {
					let result = await this.rndConnectionService.createRndConnection(this.workspaceId, this.rndConnection);
					this.$emit('result', result.data);
				} else {
					let result = await this.rndConnectionService.updateRndConnection(
						this.workspaceId,
						this.rndConnection.id,
						this.rndConnection
					);
					this.$emit('result', result.data);
				}
			},

			confirmDelete() {
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete this RND Connection?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if (this.rndConnection.id) {
							this.rndConnectionService.deleteRndConnection(this.workspaceId, this.rndConnection.id);
							this.rndConnection.deleted = true;
							this.$emit('result', this.rndConnection);
						} else {
							this.$emit('result');
						}
					}
				});
			},

			newRndConnection() {
				return {
					id: null,
					companyNumber: null,
					companyName: null,
					apiToken: null,
				};
			},
		},

		watch: {},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss"></style>
