<template>
	<div id="ftc-san-edit" :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`" v-if="connection">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result')">close</p-icon>
				<div class="brand-medium font-18">{{ connection.id ? 'Edit' : 'Create' }} Active Prospect Connection</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="save()" :disabled="!isValid"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()">delete</p-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body">
			<v-form v-model="isValid">
				<div class="column-format gap-2 mt-2">
					<v-text-field
						label="Company name"
						v-model="connection.companyName"
						:rules="required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<v-text-field
						label="API Token"
						v-model="connection.apiKey"
						:rules="connection.id ? [] : required"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ConfirmModal from '@/components/ConfirmModal';
	import ActiveProspectService from '@/modules/watchTower/ActiveProspectService';

	export default defineComponent({
		name: 'ActiveProspectConnectionDetail',
		isRightModal: true,

		props: ['id'],

		components: {},

		data: function () {
			return {
				isValid: false,
				connection: null,
				connectionService: new ActiveProspectService(),
				required: [(v) => !!v || 'Field is required'],
			};
		},

		mounted() {
			this.getConnection();
		},

		beforeUnmount() {},

		methods: {
			async getConnection() {
				if (this.id) {
					let result = await this.connectionService.getConnection(this.workspaceId, this.id);
					this.connection = result.data;
					this.connection.apiKey = null;
				} else {
					this.connection = this.newConnection();
				}
			},

			async save() {
				if (!this.connection.id) {
					let result = await this.connectionService.createConnection(
						this.workspaceId,
						this.connection.companyName,
						this.connection.apiKey
					);
					this.$emit('result', result.data);
				} else {
					let result = await this.connectionService.updateConnection(
						this.workspaceId,
						this.connection.id,
						this.connection.companyName,
						this.connection.apiKey
					);
					this.$emit('result', result.data);
				}
			},

			confirmDelete() {
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete this Active Prospect Connection?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if (this.connection.id) {
							this.connectionService.deleteConnection(this.workspaceId, this.connection.id);
							this.connection.deleted = true;
							this.$emit('result', this.connection);
						} else {
							this.$emit('result');
						}
					}
				});
			},

			newConnection() {
				return {
					id: null,
					companyName: null,
					apiKey: null,
				};
			},
		},

		watch: {},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss"></style>
