import HttpClient from '@/services/HttpClient';

export default class DashboardService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	async getDashboardMetrics(workspaceId,searchRequest) {
		return await this.httpClient.post(`/workspaces/${workspaceId}/aegis/dashboard`,searchRequest);
	}

	async getInteractions(workspaceId,searchRequest){
		return await this.httpClient.post(`/workspaces/${workspaceId}/aegis/dashboard/interactions`,searchRequest);
	}

	async downloadInteractions(workspaceId, request) {
		return await this.httpClient
			.post(`/workspaces/${workspaceId}/aegis/dashboard/interactions/download`, request, {
				responseType: 'blob',
			});
	}

}
