import HttpClient from '@/services/HttpClient';

export default class CampaignLeadService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getCampaignLeads(workspaceId, campaignId, prefix) {
		let params = {
			prefix: prefix
		}

		if(campaignId){
			params.campaignId = campaignId
		}

		return await this.client.get(`/workspaces/${workspaceId}/campaignLeads`,{
			params: params
		});
	}


	downloadCampaignLeads(workspaceId, campaignId) {
		return this.client
			.get(`/workspaces/${workspaceId}/campaignLeads/download`, {
				params: {
					campaignId: campaignId,
				},
				responseType: 'blob',
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
