<template>
	<div id="right-modal" :class="`right-modal hidden`" :style="`z-index: 150;`" @click="handleClick">
		<div :class="`right-side ${$vuetify.display.mobile ? 'mobile' : ''}`" @click.stop>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "RightModal",

	props: ['persistent','closeOnEscape'],

	components: {},

	data: function () {
		return {
			width: 40
		}
	},

	mounted() {
		if (this.closeOnEscape) {
			document.addEventListener('keydown', this.handleKeyDown);
		}
		this.$store.state.rightModalCount++;
	},

	beforeUnmount() {
		if (this.closeOnEscape) {
			document.removeEventListener('keydown', this.handleKeyDown);
		}
		this.$store.state.rightModalCount--;
	},

	methods: {
		handleClick: function(){
			console.log('handling click');
			if(!this.persistent){
				this.handleClose();
			}
		},

		handleClose: function() {
			this.$emit('close');
		},

		handleKeyDown: function(e) {
			if (e.key === 'Escape') {
				this.handleClose();
			}
		},
	},

	computed: {},

}
</script>

<style scoped lang="scss">
	.right-modal {
		width: 100vw!important;
		height: calc(var(--vh) + 2px)!important;
		min-height: calc(var(--vh) + 2px)!important;
		min-width: 100vw!important;
		max-height: calc(var(--vh) + 2px)!important;
		max-width: 100vw!important;
		background: rgba(0, 43, 82, 0.7);
		position: absolute;
		z-index: 1001;
		top:0;
		left:0;
	}


	.right-side {
		background-color: var(--v-white-base);
		position: absolute;
		right:0;
		box-shadow: -5px 0px 10px 0px rgba(0,0,0,0.22);
		min-height: var(--vh);
		max-height: var(--vh);
		height: var(--vh)!important;
		overflow-y: hidden;
	}
	.mobile {
		width: 100%;
	}
</style>