<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<div class="row-format align-center">
					<div><h2>Team members</h2></div>
					<v-btn class="ml-auto" elevation="0" color="secondary" @click="addNewUser()">+ Add user</v-btn>
				</div>

				<v-data-table
					id="team-table"
					v-model:items-per-page="itemsPerPage"
					:items="users"
					:headers="headers"
					class="pointer"
					@click:row="editUser"
					:style="`max-width: calc(${width} - 40px)`"
				>
					<template v-slot:[`item.name`]="{ item }">{{ item.firstName }} {{ item.lastName }} </template>
					<template v-slot:[`item.lastSeen`]="{ item }">
						{{
							item.lastSeen
								? PDateTime.fromISO(item.lastSeen).toLocaleString(PDateTime.DATETIME_SHORT)
								: ' -- '
						}}
					</template>
					<template v-slot:[`item.created`]="{ item }">
						{{ PDateTime.fromISO(item.created).toLocaleString(PDateTime.DATETIME_SHORT) }}
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import UserService from '@/modules/team/UserService';
	import PDateTime from '@/modules/utils/PDateTime';
	import UserEdit from '@/modules/team/UserEdit';
	import InviteUser from '@/modules/team/InviteUser';
	import { useDisplay } from 'vuetify';
	
	export default defineComponent({
		name: 'Team',

		props: [],

		components: { },

		data: function () {
			return {
				PDateTime: PDateTime,
				userService: new UserService(),
				itemsPerPage: -1,
				users: [],
				headers: [
					{ title: 'Name', key: 'name', align: 'start' },
					{ title: 'Email', key: 'email', align: 'start' },
					{ title: 'User type', key: 'userType', align: 'start' },
					{ title: 'Created', key: 'created', align: 'start' },
					{ title: 'Last seen', key: 'lastSeen', align: 'start' },
				],
			};
		},

		mounted() {
			this.getUsers();
		},

		beforeUnmount() {},

		methods: {
			getUsers: function () {
				this.userService
					.getUsers()
					.then((res) => {
						this.users.splice(0);
						this.users.push(...res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			editUser: function (event, row) {
				let binding = {
					id: row.item.id,
				};
				this.$store.state.globalModalController.openModal(UserEdit, binding).then((res) => {
					if (res) {
						let ix = this.users.findIndex((u) => u.id === binding.id);
						if (res.deleted) {
							this.users.splice(ix, 1);
						} else {
							this.users.splice(ix, 1, res);
						}
					}
				});
			},

			addNewUser: function () {
				this.$store.state.globalModalController.openModal(InviteUser, {}).then((res) => {
					if (res) {
						this.users.push(res);
					}
				});
			},
		},

		computed: {
			width: () => {
				return `${useDisplay().width.value}px`;
			},
		},
	});
</script>

<style scoped lang="scss">
	#team-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
