<template>
	<div id="pinpoint-settings">

		<div class="column-format gap-3">
			<div><img class="amazon-pinpoint" alt="Connect logo" src="/images/vendors/amazonConnectLogo.png"></div>
			<v-text-field label="Access Key ID" v-model="pinpointSettings.accessKeyId" density="compact" variant="outlined" hide-details persistent-placeholder></v-text-field>
			<v-text-field label="Secret Access Key" v-model="pinpointSettings.secretAccessKey" type="password" density="compact" variant="outlined" hide-details persistent-placeholder></v-text-field>
			<v-autocomplete label="Region" v-model="pinpointSettings.awsRegion" :items="regions" density="compact" variant="outlined" hide-details clearable persistent-placeholder item-value="value" item-title="label"></v-autocomplete>
			<v-text-field label="Pinpoint Project ID" v-model="pinpointSettings.appId" density="compact" variant="outlined" hide-details persistent-placeholder></v-text-field>
			<div v-if="pinpointSettings.connected" class="column-format gap-3">
				<v-text-field label="Reserved Caller ID Field" v-model="pinpointSettings.callerIdFieldName" density="compact" variant="outlined" hide-details persistent-placeholder></v-text-field>
				<v-text-field
					label="Attributes"
					v-model="attribute"
					@keydown.enter="addAttribute"
					density="compact"
					variant="outlined"
					clearable
					hide-details
					persistent-placeholder
				>
				<v-chip
					v-for="(value) in mappingFields"
					:key="value"
					closable
					@click:close="removeAttribute(value)"
				>
					{{ typeof value === 'object' ? value.columnName : value }}
				</v-chip>
				</v-text-field>
			</div>
			<div v-else class="font-gray_70">
				Please set access key ID, secret access key, region, and Pinpoint project ID to continue.
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import DialerService from '@/modules/dialers/DialerService';
	import PinpointService from '@/modules/dialers/pinpoint/PinpointService';

	export default defineComponent({
		name: 'PinpointSettings',

		props: ['workspace'],

		components: {},

		data: function () {
			return {
				dialerService: new DialerService(),
				pinpointService: new PinpointService(),
				pinpointSettings: {... this.workspace.dialerProviderSettings},
				attribute: "",
				mappingFields: [],
				regions: [
					{value:'us-east-2',label:'US East (Ohio)'},
					{value:'us-east-1',label:'US East (N. Virginia)'},
					{value:'us-west-2',label:'US West (Oregon)'},
					{value:'ap-south-1',label:'Asia Pacific (Mumbai)'},
					{value:'ap-northeast-2',label:'Asia Pacific (Seoul)'},
					{value:'ap-southeast-1',label:'Asia Pacific (Singapore)'},
					{value:'ap-southeast-2',label:'Asia Pacific (Sydney)'},
					{value:'ap-northeast-1',label:'Asia Pacific (Tokyo)'},
					{value:'ca-central-1',label:'Canada (Central)'},
					{value:'eu-central-1',label:'Europe (Frankfurt)'},
					{value:'eu-west-1',label:'Europe (Ireland)'},
					{value:'eu-west-2',label:'Europe (London)'},
					{value:'us-gov-west-1',label:'AWS GovCloud (US-West)'}
				],
				awsRegion: null,
			};
		},

		mounted() {
			this.checkSettings();
		},

		beforeUnmount() {
		},

		methods: {
			checkSettings: function() {
				if (this.pinpointSettings.connected) {
					this.getMappingFields();
				}
			},

			addAttribute() {
				if (this.attribute && this.attribute.trim() !== '' && !this.mappingFields.includes(this.attribute)) {
					this.mappingFields.push(this.attribute.trim());
					console.log("mappingFields: ", this.mappingFields);
					this.attribute = "";
				}
			},

			removeAttribute(value) {
				const index = this.mappingFields.indexOf(value);
				if (index !== -1) {
					this.mappingFields.splice(index, 1);
				}
			},

			async save() {
				if(this.pinpointSettings.connected){
					if(!this.pinpointSettings.callerIdFieldName){
						this.$store.commit('error','Reserved Caller ID mapping is required');
						return false;
					}else if(!this.pinpointSettings.accessKeyId){
						this.$store.commit('error','Access key ID is required');
						return false;
					}else if(!this.pinpointSettings.secretAccessKey){
						this.$store.commit('error','Secret access key is required');
						return false;
					}else if(!this.pinpointSettings.awsRegion){
						this.$store.commit('error','Region is required');
						return false;
					}else if(!this.pinpointSettings.appId){
						this.$store.commit('error','Pinpoint project ID is required');
						return false;
					}else if(this.pinpointSettings.callerIdFieldName === (this.pinpointSettings.PHONE_FIELD_NAME || this.pinpointSettings.ZIP_FIELD_NAME)){
						this.$store.commit('error','Reserved Caller ID cannot be mapped to the Phone or Zip field.');
						return false;
					}
				}

				this.pinpointSettings.attributes.replace(Array.from([...this.mappingFields]));
				let result = await this.pinpointService.connectPinpoint(this.workspace.id,this.pinpointSettings);
				return result.data;
			},

			async getMappingFields() {
				let result = await this.dialerService.getMappingFields(this.workspace.id, false);
				this.mappingFields.replace(result.data.map(item => item.columnName));
			}
		},

		computed: {},

		watch: {
			workspace: function(val) {
				this.pinpointSettings = {... val.dialerProviderSettings};
				this.checkSettings();
			}
		}
	});
</script>

<style scoped lang="scss">
	#pinpoint-settings {
		text-align: left;
		.amazon-pinpoint {
			max-width: 80px;
		}
	}
</style>
