<template>
	<div class="column-format gap-3">
		<v-text-field v-model="item.label" variant="outlined" density="compact" hide-details persistent-placeholder label="Label" @change="updated()"></v-text-field>
		<v-switch class="ml-2" v-model="item.allStates" hide-details density="compact" label="All states" color="secondary"></v-switch>
		<div v-if="!item.allStates" class="row-format flex-wrap gap-3">
			<v-checkbox v-for="state in states" :key="state" v-model="item.states" hide-details density="compact" :label="state" :true-value="state"></v-checkbox>
		</div>
	</div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'StateDoNotCall',

	props: ['node'],

	components: {},

	data: function () {
		return {
			item: {...this.node},
			states: [
					"CO","FL","IN","LA","MA","MO","OK","PA","TN","TX","WY"
			]
		};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {
		updated: function(){
			this.$emit('updated',this.item);
		}
	},

	computed: {},
});
</script>

<style scoped lang="scss"></style>
