<template>
	<v-app data-app style="height: 100%">
		<top-nav :hideMenu="workspaces.length === 0" v-if="$store.state.authenticated"></top-nav>
		<v-main v-if="$store.state.authenticated">
			<div id="app-area">
				<div v-if="!mobile" id="left-nav" :class="$store.state.leftNavExpanded ? 'left-nav-expanded' : 'left-nav-collapsed'">
					<left-nav></left-nav>
				</div>
				<div id="main-content" class="pa-0">
					<router-view></router-view>
				</div>
				<div id="notifications">
					<notifications :mobile="mobile" :drawer="$store.state.notificationExpanded && $store.state.rightModalCount === 0"></notifications>
				</div>
			</div>
		</v-main>
		<div v-else style="margin-top: 100px" class="row-format centered">
			<router-view></router-view>
		</div>
		<global-modal-controller ref="gmc"></global-modal-controller>
		<v-snackbar
				v-if="message"
				v-model="show"
				:color="color"
				:timeout="timeout"
				location="bottom right"
		>
			<div  class="row-format align-center" style="justify-content: space-between">
				<div>{{ message }}</div>
				<v-btn class="ml-2" size="24" @click="show = false" elevation="0" variant="outlined">
					<p-icon :size="20" color="white">close</p-icon>
				</v-btn>
			</div>
		</v-snackbar>
		<v-overlay z-index="1000" v-model="$store.state.loadingState" class="align-center justify-center">
			<div class="column-format centered gap-2">
				<div class="font-24 font-weight-bold secondary" style="text-align: center" v-if="$store.state.loadingMessage">{{$store.state.loadingMessage}}</div>
				<v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
			</div>
		</v-overlay>
		<notification-state-manager v-if="$store.state.authenticated"></notification-state-manager>
	</v-app>
</template>

<script>
	import { defineComponent } from 'vue';
	import TopNav from "@/modules/nav/TopNav";
	import GlobalModalController from "@/modules/utils/GlobalModalController";
	import LeftNav from "@/modules/nav/LeftNav";
	import PIcon from "@/components/PIcon";
	import { PERMISSION_STATES, askForPushPermission, subscribeUserToPush, getExistingPushSubscription } from '@/services/Notifications';
	import PushSubscriptionService from "@/services/PushSubscriptionService";
	import NotificationStateManager from "@/modules/notifications/NotificationStateManager";
	import Notifications from "@/modules/notifications/Notifications";
	import { useDisplay } from 'vuetify';

	export default defineComponent({
		name: 'App',
		components: {Notifications, PIcon, LeftNav, TopNav, GlobalModalController, NotificationStateManager},

		data() {
			return {
				show: false,
				message: null,
				timeout: -1,
				color: 'success',
				timeouts: { error: -1, warning: 10000, info: 7000, success: 7000 },
			};
		},

		mounted() {
			this.setMaxVh();
			window.addEventListener('resize', () => {
				this.setMaxVh();
			});
			this.$store.state.eventBus.$on('logged-in',this.pushNotifications);

			let app = localStorage.getItem('SELECTED_APP');
			if(app){
				this.$store.state.selectedApp = app;
			}
		},

		methods: {
			setMaxVh: function(){
				let vh = window.innerHeight;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			},

			async pushNotifications(){
				console.log('checking push notifications');
				let existingSubscription = await getExistingPushSubscription();

				if(!existingSubscription){
					const permissionResult = await askForPushPermission();

					if (permissionResult === PERMISSION_STATES.GRANTED) {
						this.existingSubscription = await subscribeUserToPush();
						new PushSubscriptionService().subscribe(this.existingSubscription).then(() => {
							console.log('Push notifications are active');
						}).catch((err) => this.$store.commit('error',err.response.data.message));
					}
				}else{
					console.log('Already subscribed to push notifications.');
				}
			}
		},

		watch: {
			alertMessage: {
				deep: true,
				handler(newAlert){
					this.message = newAlert.message;
					this.color = newAlert.type;
					this.show = true;
					this.timeout = this.timeouts[newAlert.type];
				}
			}
		},

		computed: {
			alertMessage(){
				return this.$store.state.alertMessage;
			},

			workspaces(){
				return this.$store.state.workspaces;
			},
			mobile: () => useDisplay().mobile.value
		}
	});
</script>

<style lang="scss">
	@import './assets/css/brand.scss';
	@import './assets/css/vuetify.scss';

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		font-size: 16px;
		color: rgb(var(--v-theme-secondary));
		background-color: rgb(var(--v-theme-white));
	}

	#app-area {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
	}

	#left-nav {
		transition: all 150ms linear;
	}

	.left-nav-expanded {
		width: 205px;
		min-width: 205px;
		max-width: 205px;
	}

	.left-nav-collapsed {
		width: 48px;
		min-width: 48px;
		max-width: 48px;
	}

	#main-content {
		flex: 1;
	}

	#notifications {
	}

	#notifications.hidden {
		display: none;
	}

	#notifications.visible {
		padding-top: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-left: 1px solid rgb(var(--v-theme-gray_30));
		min-width: 300px;
		width: 300px;
		box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
	}

	:root {
		--v-recaptcha-visibility: visible;
	}

	.grecaptcha-badge {
		visibility: var(--v-recaptcha-visibility) !important;
	}
</style>
