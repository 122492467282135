<template>
	<div :class="`confirm-dialog-div simple-text-input  ${$vuetify.display.mobile ? '' : 'desktop'}`">
		<div class="ma-4 column-format gap-3">
			<div v-if="instructions">{{instructions}}</div>
			<v-text-field
				v-model="inputValue"
				type="text"
				:label="label"
				persistent-placeholder
				autofocus
				hide-details
				variant="outlined"
			></v-text-field>
		</div>
		<v-form @submit.prevent="handleSubmit()" ref="confirmDialog" id="confirmDialog" lazy-validation>
			<div class="modal-footer row-format centered">
				<v-btn v-if="!hideNo" min-width="100" class="secondary-action mr-1" @click.prevent="handleClose()">{{
					noText
				}}</v-btn>
				<v-btn
					type="submit"
					min-width="100"
					elevation="0"
					:disabled="submitDisabled"
					class="primary-action ml-1"
					@click.prevent="handleSubmit()"
				>
					{{ yesText }}
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'SimpleTextInput',

		props: {
			instructions: String,
			label: String,
			hideNo: { type: Boolean, required: false, default: false },
			yesText: { type: String, required: false, default: 'OK' },
			noText: { type: String, required: false, default: 'Cancel' },
			input: { type: String, required: false },
			required: {type: Boolean, required: false, default: true}
		},

		data() {
			return {
				inputValue: this.input ? this.input : null,
			};
		},

		mounted() {},

		methods: {
			handleSubmit() {
				this.$emit('result', this.inputValue);
			},
			handleClose() {
				this.$emit('result', null);
			},
		},

		computed: {
			submitDisabled() {
				if (this.inputValue || !this.required) return false;
				else return true;
			},
		},
	};
</script>

<style lang="scss">
	.simple-text-input {
		background-color: rgb(var(--v-theme-white));
		position: relative;
		border-radius: 8px !important;
	}
	.desktop {
		width: 600px;
	}
</style>
