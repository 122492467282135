<template>
	<div class="column-format gap-3">
		<v-autocomplete
			v-model="item.campaignId"
			:items="campaigns"
			:clearable="true"
			item-title="name"
			item-value="id"
			variant="outlined"
			density="compact"
			hide-details
			persistent-placeholder
			label="Pure CallerId Campaign"
			@update:modelValue="updated"
		></v-autocomplete>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import CampaignService from '@/modules/campaigns/CampaignService';

	export default defineComponent({
		name: 'CallerId',

		props: ['node'],

		emits: ['updated'],

		components: {},

		data: function () {
			return {
				item: { ...this.node },
				campaigns: [],
				campaignService: new CampaignService(),
			};
		},

		mounted() {
			this.getCampaigns();
		},

		beforeUnmount() {},

		methods: {
			async getCampaigns() {
				let result = await this.campaignService.getCampaigns(this.workspaceId);
				this.campaigns.replace(result.data);
				this.campaigns.sort((a,b) => a.name && a.name.localeCompare(b.name));
			},

			updated: function () {
				if(this.item.campaignId){
					let campaign = this.campaigns.find(c => c.id === this.item.campaignId);
					this.item.label = campaign.name;
				}else{
					this.item.label = '';
				}
				this.$emit('updated', this.item);
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss"></style>
