<template>
	<div id="ftc-san-edit"  :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`" v-if="profile">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result')">close</p-icon>
				<div class="brand-medium font-18">{{profile.id ? 'Edit' : 'Create'}} Profile</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="save()" v-if="!isDisabled"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()" v-if="!isDisabled">delete</p-icon>
				</div>
			</div>
		</div>

		<div class="panel-modal-body">
			<div class="column-format gap-2 mt-2">
				<v-text-field label="Label" v-model="profile.label" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<v-text-field label="Legal name" v-model="profile.legalName" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<v-text-field label="Caller name (15 char)" maxlength="15" v-model="profile.callerNameShort" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<v-text-field label="Caller name (32 char)" maxlength="32" v-model="profile.callerNameLong" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<v-text-field label="Call purpose" v-model="profile.callPurpose" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<v-text-field label="Business category" v-model="profile.businessCategory" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<v-text-field label="TMO Business category" v-model="profile.tmoBusinessCategory" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import BrandedCallingService from "@/modules/watchTower/BrandedCallingService";
	import ConfirmModal from "@/components/ConfirmModal";

	export default defineComponent({
		name: 'BrandedCallingProfileDetail',
		isRightModal: true,

		props: ['id'],

		components: {},

		data: function () {
			return {
				profile: null,
				brandedCallingService: new BrandedCallingService(),
				required: [(v) => !!v || 'Field is required'],
				areaCodes: null,
			};
		},

		mounted() {
			this.getBrandedCallingProfile();
		},

		beforeUnmount() {},

		methods: {
			async getBrandedCallingProfile(){
				if(this.id){
					let result = await this.brandedCallingService.getBrandedCallingProfile(this.workspaceId,this.id);
					this.profile = result.data;
				}else{
					this.profile = this.newBrandedCallingProfile();
				}
			},

			async save(){
				if(!this.profile.id){
					let result = await this.brandedCallingService.createBrandedCallingProfile(this.workspaceId,this.profile);
					this.$emit('result',result.data);
				}else{
					let result = await this.brandedCallingService.updateBrandedCallingProfile(this.workspaceId,this.profile.id,this.profile);
					this.$emit('result',result.data);
				}
			},

			confirmDelete(){
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete this Profile?`
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if(this.profile.id) {
							this.brandedCallingService.deleteBrandedCallingProfile(this.workspaceId, this.ftcSan.id);
							this.profile.deleted = true;
							this.$emit('result', this.ftcSan);
						}else{
							this.$emit('result');
						}
					}
				});
			},

			newBrandedCallingProfile(){
				return {
					id: null,
					label: null,
					callerNameShort: null,
					callerNameLong: null,
					legalName: null,
					callPurpose: null,
					businessCategory: null,
					tmoBusinessCategory: null
				}
			}
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			isDisabled: function () {
				return this.$store.state.user.userType !== 'SuperAdmin';
			},
		},
	});
</script>

<style scoped lang="scss"></style>
