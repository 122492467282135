import HttpClient from "@/services/HttpClient";

export default class BrandedCallingService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getBrandedCallingProfileList(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/brandedCallingProfiles`);
    }

    async getBrandedCallingProfile(workspaceId,id) {
        return await this.client.get(`/workspaces/${workspaceId}/brandedCallingProfiles/${id}`);
    }

   async createBrandedCallingProfile(workspaceId,profile) {
        return await this.client.post(`/workspaces/${workspaceId}/brandedCallingProfiles`,profile);
    }

    async updateBrandedCallingProfile(workspaceId,id,profile) {
        return await this.client.put(`/workspaces/${workspaceId}/brandedCallingProfiles/${id}`,profile);
    }

    async deleteBrandedCallingProfile(workspaceId,id) {
        return await this.client.delete(`/workspaces/${workspaceId}/brandedCallingProfiles/${id}`);
    }

}