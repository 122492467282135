<template>
	<div class="column-format gap-3">
		<v-select
				v-model="item.recordSetId"
				:items="recordSets"
				item-value="id"
				item-title="name"
				hide-details
				density="compact"
				variant="outlined"
				placeholder="Record set"
				@update:modelValue="updated"
		></v-select>
		<v-select
				v-model="item.zipOverride"
				:items="zipOverride"
				item-value="value"
				item-title="text"
				hide-details
				density="compact"
				variant="outlined"
				placeholder="Override ZIP from record set"
				@update:modelValue="updated"
		></v-select>
		<v-autocomplete
				v-model="item.zipAttribute"
				:items="attributes"
				hide-details
				density="compact"
				variant="outlined"
				placeholder="Attribute containing ZIP"
				@update:modelValue="updated"
		></v-autocomplete>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import RecordSetService from "@/modules/recordSets/RecordSetService";

	export default defineComponent({
		name: 'RecordSet',

		props: ['node'],

		components: {},

		data: function () {
			return {
				item: { ...this.node },
				recordSets: [],
				recordSetService: new RecordSetService(),
				zipOverride: [
					{text: 'Never override', value: 'NEVER'},
					{text: 'Always override', value: 'ALWAYS'},
					{text: 'When not set', value: 'UNSET'}
				],

			};
		},

		mounted() {
			this.getRecordSets();
		},

		beforeUnmount() {},

		methods: {
			async getRecordSets(){
				let result = await this.recordSetService.getRecordSets(this.workspaceId);
				this.recordSets.replace(result.data.filter(r => r.status === 'ACTIVE'));
			},

			updated: function () {
				if(this.item.recordSetId){
					let recordSet = this.recordSets.find(c => c.id === this.item.recordSetId);
					this.item.label = recordSet.name;
				}else{
					this.item.label = '';
				}
				this.$emit('updated', this.item);
			},
		},

		computed: {
			workspaceId: function(){
				return this.$store.state.selectedWorkspace;
			},

			attributes: function(){
				let recordSet = this.recordSets.find(c => c.id === this.item.recordSetId);
				if(recordSet){
					return recordSet.attributes.map(a => a.name);
				}else{
					return [];
				}
			}
		},
	});
</script>

<style scoped lang="scss"></style>
