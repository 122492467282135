<template>
	<div class="mt-4">
		<div class="row-format gap-2">
			<v-text-field
				v-model="filter"
				density="compact"
				hide-details
				placeholder="Filter..."
				class="mb-2"
				append-inner-icon="filter_list"
			></v-text-field>
			<v-btn @click="downloadPoolNumbers" icon elevation="0" style="max-height: 40px" color="gray_50" v-tippy="{content: 'Download all numbers in campaign'}"><v-icon>download</v-icon></v-btn>
		</div>

		<v-data-table :items="filteredNumbers" :items-per-page="-1" :headers="headers" id="pool-table">
			<template v-slot:[`item.number`]="{ item }">
				<span :class="item.status === 'AGING' ? 'aging' : ''">{{ item.number }}</span>
			</template>
			<template v-slot:[`item.locality`]="{ item }">
				<span :class="item.status === 'AGING' ? 'aging' : ''">{{ item.locality }}</span>
			</template>
			<template v-slot:[`item.region`]="{ item }">
				<span :class="item.status === 'AGING' ? 'aging' : ''">{{ item.region }}</span>
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<span :class="item.status === 'AGING' ? 'aging' : ''">{{ item.status }}</span>
			</template>
			<template v-slot:[`item.usageCount`]="{ item }">
				<span :class="item.status === 'AGING' ? 'aging' : ''">{{ item.usageCount }}</span>
			</template>
			<template v-slot:[`item.riskLevel`]="{ item }">
				<span :class="item.status === 'AGING' ? 'aging' : ''">{{ item.riskLevel }}</span>
			</template>
			<template v-slot:[`item.created`]="{ item }">
				<span :class="item.status === 'AGING' ? 'aging' : ''">{{
					DateTime.fromISO(item.created).toLocaleString(DateTime.DATE_FULL)
				}}</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import CampaignPoolService from '@/modules/pools/CampaignPoolService';
	import PDateTime from '@/modules/utils/PDateTime';

	export default defineComponent({
		name: 'CampaignPool',

		props: ['workspaceId', 'campaign'],

		components: {},

		data: function () {
			return {
				DateTime: PDateTime,
				pool: [],
				filter: null,
				campaignPoolService: new CampaignPoolService(),
				headers: [
					{ title: 'Number', key: 'number', align: 'start' },
					{ title: 'City', key: 'locality', align: 'start' },
					{ title: 'State', key: 'region', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'Created', key: 'created', align: 'start' },
					{ title: 'Usages', key: 'usageCount', align: 'start' },
					{ title: 'SPAM', key: 'riskLevel', align: 'start' },
				],
			};
		},

		mounted() {
			this.getPoolNumbers();
		},

		beforeUnmount() {},

		methods: {
			async getPoolNumbers() {
				let result = await this.campaignPoolService.getCampaignPool(this.workspaceId, this.campaign.id);
				this.pool.replace(result.data);
				this.pool.sort((a, b) => a.number.localeCompare(b.number));
			},

			downloadPoolNumbers: function(){
				this.$store.commit('startLoading');
				this.campaignPoolService.downloadPoolNumbers(this.workspaceId,this.campaign.id).then((res) => {
					let fileURL = window.URL.createObjectURL(res.data);
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute(
							'download',
							`${this.campaign.name}-Pool-Download.csv`
					);
					document.body.appendChild(fileLink);
					fileLink.click();
				}).finally(() => {
					this.$store.commit('stopLoading');
				})
			},
		},

		computed: {


			filteredNumbers: function () {
				if (!this.filter) {
					return this.pool;
				}

				let search = this.filter.toLowerCase();

				return this.pool.filter((n) => {
					if (n.number.includes(search)) {
						return true;
					} else if (n.locality.toLowerCase().startsWith(search) && search.length > 2) {
						return true;
					} else if (n.region.toLowerCase() === search) {
						return true;
					}
					return false;
				});
			},
		},
	});
</script>

<style scoped lang="scss">
	.scrollable {
		.highlight {
			background-color: rgb(var(--v-theme-gray_10));
		}

		.aging {
			opacity: 0.4;
		}
	}

	#pool-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>

<style lang="scss">
	.aging {
		opacity: 0.4;
	}
</style>

<style scoped lang="scss"></style>
