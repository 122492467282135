<template>
	<div style="background-color: rgb(var(--v-theme-white)); border-radius: 4px" class="pa-4" v-if="isReady">
		<div class="row-format align-center pb-2" style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))">
			<div class="brand-medium font-18">Test workflow</div>
			<p-icon @click="$emit('result')" class="ml-auto pointer">close</p-icon>
		</div>
		<div class="pt-3 pb-2 column-format gap-2">
			<maz-phone-number-input
				:translations="{ phoneInput: { placeholder: 'To number', example: 'To number: ' } }"
				:only-countries="['US', 'CA']"
				@update="handlePhoneInput"
				size="sm"
				v-model="aegisRequest.to"
			>
			</maz-phone-number-input>
			<maz-phone-number-input
				:translations="{ phoneInput: { placeholder: 'From number', example: 'From number: ' } }"
				:only-countries="['US', 'CA']"
				@update="handleFromInput"
				size="sm"
				v-model="aegisRequest.from"
			>
			</maz-phone-number-input>
			<v-text-field
				v-model="aegisRequest.zip"
				type="number"
				persistent-placeholder
				hide-details
				density="compact"
				variant="outlined"
				label="Zip code (optional)"
				@input="saveState()"
			></v-text-field>
			<datepicker
				v-model="emulatedTime"
				:is24="false"
				placeholder="Emulate date/time of test"
				@update:model-value="dateUpdated"
			></datepicker>
			<div class="column-format gap-2">
				<div class="row-format align-center">
					<div>Meta data</div>
					<v-btn class="ml-auto" variant="text" @click="addMeta()">+ Add</v-btn>
				</div>
				<div v-for="meta in aegisRequest.metaData" :key="meta.id" class="row-format gap-3 align-center">
					<v-text-field
							v-model="meta.key"
							persistent-placeholder
							hide-details
							density="compact"
							variant="outlined"
							label="Field name"
							@input="saveState()"
					></v-text-field>
					<v-text-field
							v-model="meta.value"
							persistent-placeholder
							hide-details
							density="compact"
							variant="outlined"
							label="Field value"
							@input="saveState()"
					></v-text-field>
					<v-icon class="material-symbols-outlined" @click="removeMeta(meta.id)">delete</v-icon>
				</div>
			</div>
			<v-btn class="super-action" block :disabled="!phoneValid" @click="runTest()">Run test</v-btn>
		</div>
		<interaction-detail v-if="interaction" :interaction="interaction"></interaction-detail>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
	import WorkflowService from '@/modules/workflow/WorkflowService';
	import { DateTime } from 'luxon';
	import PIcon from '@/components/PIcon';
	import Datepicker from '@vuepic/vue-datepicker';
	import '@vuepic/vue-datepicker/dist/main.css';
	import InteractionDetail from '@/modules/workflow/InteractionDetail';
	import { v4 as uuid } from 'uuid';

	export default defineComponent({
		name: 'WorkflowTestModal',

		props: ['id'],

		components: {
			InteractionDetail,
			PIcon,
			MazPhoneNumberInput,
			Datepicker,
		},

		data: function () {
			return {
				isReady: false,
				DateTime: DateTime,
				phoneValid: false,
				workflowService: new WorkflowService(),
				interaction: null,
				emulatedTime: null,

				aegisRequest: {
					from: '+12015550213',
					to: null,
					zip: null,
					metaData: [],
					data: {},
					emulatedTime: null,
				},
			};
		},

		mounted() {
			this.retrieveState();
		},

		beforeUnmount() {},

		methods: {
			runTest: function () {
				this.interaction = null;
				this.$store.commit('startLoading');
				this.aegisRequest.data = {};
				this.aegisRequest.metaData.forEach(item => {
						this.aegisRequest.data[item.key] = item.value;
				});
				this.workflowService
					.testWorkflow(this.workspaceId, this.id, this.aegisRequest)
					.then((res) => {
						this.interaction = res.data;
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			addMeta: function () {
				this.aegisRequest.metaData.push({
					id: uuid(),
					key: '',
					value: '',
				});
				this.saveState();
			},

			removeMeta: function (id) {
				let ix = this.aegisRequest.metaData.findIndex((m) => m.id === id);
				if (ix > -1) {
					this.aegisRequest.metaData.splice(ix, 1);
					this.saveState();
				}
			},

			handlePhoneInput: function (event) {
				this.phoneValid = event.isValid;
				if (event.isValid) {
					this.saveState();
				}
			},

			handleFromInput: function (event) {
				if (event.isValid) {
					this.saveState();
				}
			},

			retrieveState: function () {
				try {
					let result = localStorage.getItem('WORKFLOW_TEST_NUMBER');
					if (result) {
						this.aegisRequest = JSON.parse(result);
						if (this.aegisRequest.emulatedTime) {
							this.emulatedTime = DateTime.fromISO(this.aegisRequest.emulatedTime).toJSDate();
						}
						if(!this.aegisRequest.metaData){
							this.aegisRequest.metaData = [];
						}
						this.aegisRequest.data = [];
					}
				} catch (err) {
					console.log(err);
				} finally {
					this.isReady = true;
				}
			},

			dateUpdated: function () {
				this.aegisRequest.emulatedTime = this.emulatedTime ? this.emulatedTime.toISOString() : null;
				this.saveState();
			},

			saveState: function () {
				let state = JSON.stringify(this.aegisRequest);
				localStorage.setItem('WORKFLOW_TEST_NUMBER', state);
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss"></style>
