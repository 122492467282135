<template>
	<div :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon :size="30" class="mr-2 pointer" @click.stop="$emit('result')">arrow_right</p-icon>
				<div class="brand-medium font-18">Add user</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" :disabled="!isValid" @click.stop="inviteUser()"> Invite </v-btn>
				</div>
			</div>
		</div>
		<div class="panel-modal-body" v-if="user">
			<v-form @submit.prevent v-model="isValid">
				<div class="column-format gap-3 pt-3">
					<v-text-field
						label="First"
						:rules="requiredRules"
						v-model="user.firstName"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<v-text-field
						label="Last"
						:rules="requiredRules"
						v-model="user.lastName"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<v-text-field
						label="Email"
						:rules="emailRules"
						v-model="user.email"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
					></v-text-field>
					<v-select
						label="User type"
						v-model="user.userType"
						density="compact"
						variant="outlined"
						:items="userTypes"
						hide-details
					>
					</v-select>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import UserService from '@/modules/team/UserService';

	export default defineComponent({
		name: 'InviteUser',
		isRightModal: true,

		props: ['id'],

		components: {},

		data: function () {
			return {
				isValid: false,
				requiredRules: [(v) => !!v || 'Field is required'],
				emailRules: [
					(v) => !!v || 'Email is required',
					(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid',
				],
				user: {
					userType: 'Admin',
				},
				userService: new UserService(),
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			inviteUser: function () {
				this.$store.commit('startLoading');
				this.userService
					.addUser(this.user)
					.then((res) => {
						this.$store.commit('success', 'User has been successfully invited.');
						this.$emit('result', res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {
			userTypes: function () {
				let result = [];
				let userType = this.$store.state.user?.userType;
				if (userType === 'Owner') {
					result.push(...['Owner', 'Admin']);
				} else if (userType === 'Admin') {
					result.push(...['Admin']);
				}
				return result;
			},
		},
	});
</script>

<style scoped lang="scss">
	.desktop {
		min-width: 650px;
		width: 650px;
	}

</style>
