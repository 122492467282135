<template>
	<div>
		<div v-for="(modal,index) in modals" :key="modal.id">
			<v-dialog v-if="modal.type === 'standard'" :fullscreen="modal.fullScreen" :retain-focus="modal.retainFocus" :persistent="modal.persistent" v-model="modal.dialog" :style="`${modal.fullScreen ? '' : 'width:' + modal.width + 'px'}`">
				<component
						:key="'component-' + modal.id"
						:ref="modal.id"
						:is="modal.component"
						v-bind="modal.binding"
						@result="onResult(modal, $event)"
						@error="onError(modal, $event)"
				></component>
			</v-dialog>

			<right-modal :style="`z-index: ${200 + index}`" v-if="modal.type === 'right'" @close="handleClose(modal)" :persistent="modal.persistent" :close-on-escape="modal.closeOnEscape">
				<component
						:key="'component-' + modal.id"
						:ref="modal.id"
						:is="modal.component"
						v-bind="modal.binding"
						@result="onResult(modal, $event)"
						@error="onError(modal, $event)"
				></component>
			</right-modal>
		</div>
	</div>
</template>

<script>
	import Deferred from '@/modules/utils/Deferred';
	import { v4 as uuidv4 } from 'uuid';
	import BasicModal from '@/components/BasicModal';
	import RightModal from "@/components/RightModal";
	import ConfirmModal from "@/components/ConfirmModal";
	import UserEdit from "@/modules/team/UserEdit";
	import Organization from "@/modules/organization/Organization";
	import InviteUser from "@/modules/team/InviteUser";
	import WorkspaceEdit from "@/modules/workspaces/WorkspaceEdit";
	import ListEdit from "@/modules/campaigns/ListEdit";
	import SimpleTextInput from "@/components/SimpleTextInput";
	import CreateCampaign from "@/modules/campaigns/detail/CreateCampaign";
	import FtcSanDetail from "@/modules/watchTower/FtcSanDetail";
	import BusinessRecordDetail from "@/modules/watchTower/BusinessRecordDetail";
	import EditRecordSet from "@/modules/recordSets/EditRecordSet";
	import WorkflowEditor from "@/modules/workflow/WorkflowEditor";
	import NodeConfigModal from "@/modules/workflow/config/NodeConfigModal";
	import WorkflowTestModal from "@/modules/workflow/WorkflowTestModal";
	import InteractionDetailModal from "@/modules/aegis/InteractionDetailModal";
	import WebhookEdit from "@/modules/webhooks/WebhookEdit";
	import EnableMFA from "@/modules/profile/EnableMFA";
	import RndConnectionDetail from "@/modules/watchTower/RndConnectionDetail";
	import ActiveProspectConnectionDetail from "@/modules/watchTower/ActiveProspectConnectionDetail";
	import BrandedCallingProfileDetail from "@/modules/watchTower/BrandedCallingProfileDetail";

	export default {
		name: 'GlobalModalController',

		props: [],

		components: {
			BasicModal,
			RightModal,
			ConfirmModal,
			SimpleTextInput,
			UserEdit,
			Organization,
			InviteUser,
			WorkspaceEdit,
			ListEdit,
			CreateCampaign,
			FtcSanDetail,
			RndConnectionDetail,
			ActiveProspectConnectionDetail,
			BusinessRecordDetail,
			BrandedCallingProfileDetail,
			EditRecordSet,
			WorkflowEditor,
			NodeConfigModal,
			WorkflowTestModal,
			InteractionDetailModal,
			WebhookEdit,
			EnableMFA
		},

		data: function() {
			return {
				ignoreBackEvent: false,
				modals: [],
			};
		},

		mounted() {
			this.$store.state.globalModalController = this;
		},

		beforeUnmount() {
			this.$store.state.globalModalController = null;
		},

		methods: {
			openModal(component, binding, closeOnEscape = true, fullScreen = false, retainFocus = false, persistent = false, width = 500) {
				let dfd = new Deferred();
				let modal = {
					id: uuidv4(),
					component: component.name,
					binding: binding,
					dialog: true,
					closeOnEscape: closeOnEscape,
					fullScreen: fullScreen,
					retainFocus: retainFocus,
					persistent: persistent,
					type: component.isRightModal ? 'right' : 'standard',
					dfd: dfd,
					width: width
				};

				this.modals.push(modal);
				return dfd.promise;
			},

			handleClose(modal) {
				// Tinymce link modal was erroring out. This test fixes it. Weird.
				if (typeof this.$refs[modal.id][0].handleClose !== 'undefined') {
					this.$refs[modal.id][0].handleClose();
				}
			},

			onResult(modal, result) {
				modal.dialog = false;
				modal.dfd.resolve(result);
				this.removeModal(modal);
			},

			onError(modal, error) {
				modal.dialog = false;
				modal.dfd.reject(error);
				this.removeModal(modal);
			},

			removeModal(modal) {
				let ix = this.modals.findIndex((m) => m.id === modal.id);
				this.modals.splice(ix, 1);
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	.v-dialog::-webkit-scrollbar {
		display: block !important;
	}
</style>
