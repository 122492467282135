import HttpClient from '@/services/HttpClient';

export default class CampaignPoolService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getCampaignPool(workspaceId, campaignId) {
		return await this.client.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/pool`);
	}

	async searchCampaignPool(workspaceId, prefix) {
		let params = {
			prefix: prefix,
		};

		return await this.client.get(`/workspaces/${workspaceId}/pool`, {
			params: params,
		});
	}

	downloadPoolNumbers(workspaceId, campaignId = null) {
		return this.client
			.get(`/workspaces/${workspaceId}/pool/download`, {
				params: {
					campaignId: campaignId,
				},
				responseType: 'blob',
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
