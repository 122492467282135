<template>
	<div class="row-format">
		<div class="button-box pointer row-format centered"  v-tippy="{ content: 'Dismiss notification' }" @click.stop="deleteOne()">
			<v-icon size="22" color="secondary">done</v-icon>
		</div>
	</div>
</template>

<script>
	import NotificationService from './NotificationService';

	export default {
		name: 'SnoozeDelete',

		props: ['id'],

		components: {},

		data: function () {
			return {
				notificationService: new NotificationService(),
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			deleteOne: function () {
				this.notificationService.deleteNotification(this.id);
			},
		},
		computed: {},
	};
</script>

<style scoped lang="scss">
	.button-box {
		width: 32px;
		height: 32px;
		border: none;
		//border: 1px solid var(--v-gray_50-base);
		//box-sizing: border-box;
		//border-radius: 4px;
	}
</style>
