<template>
	<div>
		<v-data-table id="ap-connection-table" :items="connections" :headers="headers" :items-per-page="-1" @click:row="rowClick">
			<template v-slot:[`item.timestamp`]="{ item }">
				{{DateTime.fromISO(item.timestamp).toLocaleString(DateTime.DATETIME_MED)}}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ActiveProspectService from "@/modules/watchTower/ActiveProspectService";
	import ActiveProspectConnectionDetail from "@/modules/watchTower/ActiveProspectConnectionDetail";
	import {DateTime} from 'luxon';

	export default defineComponent({
		name: 'ActiveProspectConnectionList',

		props: [],

		components: {},

		data: function () {
			return {
				DateTime: DateTime,
				connectionService: new ActiveProspectService(),
				connections: [],
				headers: [
					{ title: 'Company name', key: 'companyName', align: 'start' },
					{ title: 'Status', key: 'resourceStatus', align: 'start' },
					{ title: 'Created', key: 'timestamp', align: 'start' },
				]
			};
		},

		mounted() {
			this.getConnections();
		},

		beforeUnmount() {},

		methods: {
			async addNew(){
				await this.editRndConnection();
			},

			async rowClick(event,row){
				await this.editRndConnection(row.item.id);
			},

			async editRndConnection(id = null){
				let binding = {
					id:id
				}

				this.$store.state.globalModalController.openModal(ActiveProspectConnectionDetail,binding).then((connection) => {
					if(!connection){
						return;
					}

					let ix = this.connections.findIndex(f => f.id === connection.id);

					if(connection.deleted && ix > -1){
						this.connections.splice(ix,1);
					}else if(ix > -1){
						this.connections.splice(ix,1, connection)
					}else{
						this.connections.push(connection);
					}
				})
			},

			async getConnections(){
				let result = await this.connectionService.getConnectionList(this.workspaceId);
				this.connections.replace(result.data);
			}
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#ap-connection-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
