<template>
	<v-dialog
			:value="dialog"
			width="unset"
			:persistent="true"
			@click:outside="!persistent ? handleClose() : ''"
			:fullscreen="fullscreen"
			content-class="basic-modal"
			:retain-focus="retainFocus"
			:no-click-animation="!retainFocus"
	>
		<slot></slot>
	</v-dialog>
</template>

<script>
export default {
	name: 'BasicModal',
	components: {},

	props: {
		dialog: { type: Boolean, default: false },
		fullscreen: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		closeOnEscape: { type: Boolean, default: true },
		retainFocus: {type: Boolean, default: true}
	},

	data() {
		return {
			alert: {},
		};
	},

	mounted() {
		if (this.closeOnEscape) {
			document.addEventListener('keydown', this.handleKeyDown);
		}
	},

	beforeUnmount() {
		if (this.closeOnEscape) {
			document.removeEventListener('keydown', this.handleKeyDown);
		}
	},

	methods: {
		handleClose: function() {
			this.$emit('close');
		},

		handleKeyDown: function(e) {
			if (e.key === 'Escape') {
				this.handleClose();
			}
		},
	},

	watch: {
		dialog(newVal) {
			if (this.closeOnEscape) {
				if (newVal) {
					document.addEventListener('keydown', this.handleKeyDown);
				} else {
					document.removeEventListener('keydown', this.handleKeyDown);
				}
			}
		},
	},

	computed: {},
};
</script>

<style lang="scss">
	.v-dialog.basic-modal {
		background-color: rgb(var(--v-theme-white));
	}
</style>
