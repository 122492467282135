<template>
	<div>
		<div>Please wait. Connecting CXOne to your Workspace.</div>
	</div>
</template>

<script>
	import NiceService from '@/modules/dialers/nice/NiceService';

	export default {
		name: 'NiceOAuth',

		props: ['code'],

		components: {},

		data: function () {
			return {
				niceService: new NiceService(),
			};
		},

		mounted() {
			if(!this.$store.state.oAuthProcessed){
				this.$store.state.oAuthProcessed = true;
				this.connectNice();
			}
		},

		methods: {
			connectNice: function () {
				this.$store.commit('startLoading');
				let workspaceId = localStorage.getItem('NICE_OAUTH_WORKSPACE');

				this.niceService
					.connect(workspaceId, this.code)
					.then(() => {
						this.$store.commit('success', 'Successfully connected CXOne');
						this.$router.push(`/workspaces?openWorkspace=${workspaceId}`);
					})
					.catch((err) => this.$store.commit('error', 'There was an error connecting your CXOne account:' + err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
