import axios from 'axios';
import qs from 'qs';
import store from '@/store';
import router from '@/router';

const getClient = () => {
	const options = {
		baseURL: process.env.VUE_APP_BASE_AUTH_URL,
		withCredentials: true,
	};

	return axios.create(options);
};

export default {
	async activationTokenVerify(token){
		const request = {
			token
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		const response = await getClient().post('/auth/activationTokenVerify', qs.stringify(request), config)
		return response.data;
	},

	async authenticationToken(token){
		console.log(token);
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		const response = await getClient().post(`/auth/token?token=${encodeURIComponent(token)}`, null, config)
		return response.data;
	},

	async activateAccount(token,password){
		const request = {
			token,
			password
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		const response = await getClient().post('/auth/activateAccount', qs.stringify(request), config);
		return response.data;
	},

	async requestPasswordRest(email, reCaptchaToken){
		const request = {
			email,
			reCaptchaToken
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		const response =  await getClient().post('/auth/requestPasswordReset', qs.stringify(request), config);
		return response.data;

	},

	async resetPasswordVerify(token){
		const request = {
			token
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		const response = await getClient().post('/auth/resetPasswordVerify', qs.stringify(request), config);
		return response.data;
	},


	async resetPassword(token,password){
		const request = {
			token,password
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		const response = await getClient()
		.post('/auth/resetPassword', qs.stringify(request), config)
		return response.data;
	},


	async refreshToken(force=false) {
		const response = await getClient().post(`/auth/refresh?force=${force}`);
		return response.data;
	},

	async authWithCredentials(reCaptchaToken,email,password) {
		const request = {
			reCaptchaToken,
			email,
			password
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		const response = await getClient().post('/auth/credentials', qs.stringify(request), config)
		return response.data;
	},

	async secondFactor(token,code) {
		const request = {
			token,
			code
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		const response = await getClient().post('/auth/secondFactor', qs.stringify(request), config);
		return response.data;
	},

	async logout() {
		await getClient().post('/auth/logout');
		store.commit('logOut');
		router.push('/sign-in');
	},

	async getProfile() {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		const response = await getClient()
		.get('/auth/me', config);
		return response.data;
	},

	async generateMfa() {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
			responseType: 'blob',
		};

		const response = await getClient()
			.get('/auth/mfa', config);

		return URL.createObjectURL(response.data);
	},

	async verifyMfa(code) {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		const response = await getClient()
			.post(`/auth/mfa/verify?code=${code}`, null, config);
		return response.data;
	},

	getPasswordRules() {
		return [
			(v) => !!v || 'Password is required',
			(v) => v.length >= 8 || 'Password must be at least 8 characters long',
			(v) => v.length <= 20 || 'Password must be less than 20 characters long',
			(v) => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
			(v) => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
			(v) => /[0-9]/.test(v) || 'Password must contain at least one digit',
			(v) => /[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/.test(v) || 'Password must contain at least one symbol',
		];
	}
};
