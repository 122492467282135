<template>
	<div id="nice-settings">
		<div class="column-format gap-3">

			<div class="text-left row-format align-center">
				<img class="nice-logo" alt="NICE logo" src="/images/vendors/nice.png" style="max-width: 80px">
				<div v-if="niceSettings.connected" class="row-format align-center ml-auto">
					<v-icon color="success" size="30">check</v-icon>
					<div>Successfully connected.</div>
				</div>
				<div v-else class="row-format align-center ml-auto">
					<v-icon color="error" size="30">error</v-icon>
					<div>Not currently connected.  Please authenticate.</div>
				</div>
			</div>
			<v-btn @click="authorize" class="secondary-action">Authenticate with your NICE credentials</v-btn>
		</div>
	</div>
</template>

<script>
import {defineComponent} from 'vue';
import DialerService from "@/modules/dialers/DialerService";
import NiceService from "@/modules/dialers/nice/NiceService";

export default defineComponent({
	name: 'NiceSettings',

	props: ['workspace'],

	components: {},

	data: function () {
		return {
			dialerService: new DialerService(),
			niceService: new NiceService(),
			niceSettings: {... this.workspace.dialerProviderSettings},
			mappingFields: [],
		};
	},

	mounted() {
		this.checkSettings();
	},

	beforeUnmount() {
	},

	methods: {
		checkSettings: function(){
			if(this.niceSettings.connected){
				this.getMappingFields();
			}
		},

		async save(){
			return true;
		},

		async authorize(){
			try {
				localStorage.setItem('NICE_OAUTH_WORKSPACE',this.workspace.id);
				let result = await this.niceService.authorize(this.workspace.id);
				window.location.href = result.data;
			}catch(err){
				this.$store.commit('error', err.response.data.message);
			}
		},

		async getMappingFields(){
			let result = await this.dialerService.getMappingFields(this.workspace.id, false);
			this.mappingFields.replace(result.data);
		}
	},

	computed: {},

	watch: {
		workspace: function(val){
			this.niceSettings = {... val.dialerProviderSettings};
			this.checkSettings();
		}
	}
});
</script>

<style scoped lang="scss">
	#five9-settings {
		text-align: left;
		.five9-logo {
			max-width: 80px;
		}
	}
</style>
