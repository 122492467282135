<template>
	<div class="column-format gap-3">
		<v-select
			v-model="item.ftcSanId"
			:items="computedSanList"
			item-value="id"
			item-title="label"
			hide-details
			density="compact"
			variant="outlined"
			placeholder="FTC SAN"
			@update:modelValue="updated"
		></v-select>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import FtcSanService from '@/modules/watchTower/FtcSanService';

	export default defineComponent({
		name: 'DoNotCall',

		props: ['node'],

		components: {

		},

		data: function () {
			return {
				item: { ...this.node },
				ftcSanService: new FtcSanService(),
				ftcSans: [],
			};
		},

		mounted() {
			this.getFtcSanList();
		},

		beforeUnmount() {},

		methods: {
			getFtcSanList: function(){
				this.ftcSanService.getFtcSanList(this.workspaceId).then((res) => {
					this.ftcSans.replace(res.data);
				})
			},

			updated: function () {
				if(this.item.ftcSanId){
					let ftcSan = this.ftcSans.find(c => c.id === this.item.ftcSanId);
					this.item.label = ftcSan.ftcOrgName;
				}else{
					this.item.label = '';
				}
				this.$emit('updated', this.item);
			},
		},

		computed: {
			workspaceId: function(){
				return this.$store.state.selectedWorkspace;
			},

			computedSanList: function(){
				let result = [];
				this.ftcSans.forEach(f => {
					result.push({
						id: f.id,
						label: f.ftcSan + ': ' + f.ftcOrgName
					})
				});
				return result;
			}
		},
	});
</script>

<style scoped lang="scss"></style>
