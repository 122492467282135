import LeadList from "@/modules/leads/LeadList";

export default [
    {
        path: '/leads',
        name: 'leads',
        component: LeadList,
        title: 'Lead list',
        props: true
    },

]