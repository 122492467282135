import HttpClient from '@/services/HttpClient';

export default class MessagingListService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getMessagingLists(workspaceId, campaignId) {
		return await this.client.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/messagingLists`);
	}

	async startMessagingToList(workspaceId, campaignId, messagingListId) {
		return await this.client.post(`/workspaces/${workspaceId}/campaigns/${campaignId}/messagingLists/${messagingListId}/start`);
	}

	async stopMessagingToList(workspaceId, campaignId, messagingListId) {
		return await this.client.post(`/workspaces/${workspaceId}/campaigns/${campaignId}/messagingLists/${messagingListId}/stop`);
	}
}
