<template>
	<i class="material-symbols-outlined" @click="emitClick($event)" :style="`color: ${calculatedColor}; font-size: ${size}px`">
		<slot></slot>
	</i>
</template>

<script>
import { defineComponent, computed, toRefs } from 'vue';

export default defineComponent({
	name: 'PIcon',

	emits: ['click'],

	props: {
		size: {
			type: Number,
			default: 24,
		},
		color: {
			type: String,
			default: 'secondary'
		}
	},

	setup(props, { emit }) {
		const { color } = toRefs(props);

		const calculatedColor = computed(() => {
			if (color.value.startsWith('#')) {
				return color.value;
			} else {
				return `rgb(var(--v-theme-${color.value}))`;
			}
		});

		function emitClick(event) {
			emit('click', event);
		}

		// Return everything that should be available in the template
		return {
			calculatedColor,
			emitClick
		};
	},
});
</script>

<style scoped lang="scss"></style>