import mixpanel from 'mixpanel-browser';
import store from '@/store';

export default {
    install(app) {
        mixpanel.init(process.env.VUE_APP_MIXPANEL, {
            debug: true,
            ignore_dnt: true,
            track_pageview: true,
            batch_flush_interval_ms: 1000,
        });

        app.config.globalProperties.$track = {
            identify(user) {
                if (user.userType === 'SuperAdmin') {
                    return;
                }

                try {
                    mixpanel.identify(user.email);

                    mixpanel.people.set({
                        $email: user.email,
                        $name: `${user.firstName} ${user.lastName}`,
                        orgId: user.orgId
                    });

                    mixpanel.track('Sign In', {
                        org_id: store.getters.getOrgId
                    });
                }catch(err){
                    console.log(err);
                }
            },

            record(event, props) {
                if (store.getters.isSuperAdmin) {
                    return;
                }
                if(!props){
                    props = {};
                }
                props['orgId'] = store.getters.getOrgId;
                mixpanel.track(event.toLowerCase(), props);
            },

        };
    },
};