import HttpClient from '@/services/HttpClient';


export default class PushSubscriptionService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	subscribe(subscription) {
		return this.httpClient
			.post(`/pushSubscriptions/subscribe`,subscription)
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	unsubscribe(subscription){
		return this.httpClient
			.post(`/pushSubscriptions/unsubscribe`,subscription)
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}
}
