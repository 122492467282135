<template>
	<v-menu
			ref="menu"
			v-model="dateMenu"
			:close-on-content-click="false"
			transition="scale-transition"
			offset-y
			:disabled="disabled"
			min-width="290px"
	>
		<template v-slot:activator="{ props }">
			<v-text-field
					v-bind="props"
					:value="dateFormatted"
					readonly
					:label="label"
					:disabled="disabled"
					density="compact"
					variant="outlined"
					hide-details
					persistent-placeholder
					append-inner-icon="event"
					class="pointer"
			>
			</v-text-field>
		</template>
		<v-date-picker v-model="date" @update:modelValue="dateMenu=false"></v-date-picker>
	</v-menu>
</template>

<script>
import {defineComponent} from 'vue';
import {DateTime} from 'luxon';

export default defineComponent({
	name: 'DateSelector',

	props: ['label','disabled','modelValue'],

	components: {},

	data: function () {
		return {
			dateMenu: false,
			date: this.modelValue,
		};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {},

	watch: {
		date: function(){
			if(this.date){
				this.$emit('update:modelValue', DateTime.fromISO(this.date).toISODate());
			}else {
				this.$emit('update:modelValue', null);
			}
		}
	},

	computed: {
		dateFormatted: function () {
			if (this.date) {
				return DateTime.fromISO(this.date).toLocaleString(DateTime.DATE_MED);
			} else {
				return '';
			}
		},
	},
});
</script>

<style scoped lang="scss"></style>
