<template>
	<div class="metric">
		<div class="label pb-3 font-weight-bold">{{ label }}</div>
		<div
			v-for="(count) in visibleCounts"
			:key="getKey(count)"
			class="row-format py-1"
			style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
		>
			<div>{{ getLabel(count) }}</div>
			<div class="ml-auto">{{ formatNumber(count.count) }}</div>
		</div>
		<div v-if="counts.length === 0">[No data]</div>
		<div v-if="showMoreButton || showLessButton" class="row-format pt-2">
			<div v-if="showMoreButton" @click="showMore" class="pointer brand-medium" style="font-size: 14px; color: rgb(var(--v-theme-secondary))">+ Show More</div>
			<div v-if="showLessButton" @click="showLess" class="pointer brand-medium" style="font-size: 14px; color: rgb(var(--v-theme-secondary))">- Show Less</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			label: {
				type: String,
				required: true,
			},
			counts: {
				type: Array,
				required: true,
			},
			keyField: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				itemsToShow: 5,
				initialItemsToShow: 5,
			};
		},
		computed: {
			visibleCounts() {
				return this.counts.slice(0, this.itemsToShow);
			},
			showMoreButton() {
				return this.itemsToShow < this.counts.length;
			},
			showLessButton() {
				return this.itemsToShow > this.initialItemsToShow;
			},
		},
		methods: {
			formatNumber: function(number){
				if(number) {
					return number.toLocaleString('en-US', {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0
					});
				}else{
					return number;
				}
			},
			showMore() {
				this.itemsToShow = this.counts.length;
			},
			showLess() {
				this.itemsToShow = this.initialItemsToShow;
			},
			getKey(count) {
				return count[this.keyField];
			},
			getLabel(count) {
				return count[this.keyField];
			},
		},
	};
</script>

<style scoped>

</style>
