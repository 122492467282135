<template>
	<div class="confirm-dialog-div">
		<i class="material-symbols-outlined pointer pa-2" @click.prevent="handleClose()">close</i>

		<div class="modal-padding">
			<div class="modal-title row-format centered gap-1 mt-n3" v-if="headingText">
				<i class="material-symbols-outlined" :style="`font-size: 24px; color: rgb(var(--v-theme-${severity}))`" @click.prevent="handleClose()">{{icon}}</i>
				<div class="font-18 brand-medium">{{ headingText }}</div>
			</div>
			<div :class="`modal-subtitle text-${align}`" v-if="bodyText" v-html="bodyText"></div>
			<v-text-field
				v-if="confirmText"
				v-model="confirmation"
				type="text"
				class="confirm-input"
				autofocus
				:rules="[(value) => !!value || confirmHint]"
				@keyup.enter="handleEnter()"
			></v-text-field>
		</div>
		<v-form @submit.prevent="handleSubmit()" ref="confirmDialog" id="confirmDialog" lazy-validation>
			<div class="modal-footer row-format centered">
				<v-btn v-if="!hideNo" min-width="100" class="super-action mr-1" @click.prevent="handleClose()">{{
					noText
				}}</v-btn>
				<v-btn
					type="submit"
					min-width="100"
					elevation="0"
					:disabled="submitDisabled"
					class="primary-action ml-1"
					@click.prevent="handleSubmit()"
				>
					{{ yesText }}
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'ConfirmModal',

		props: {
			severity: { type: String, required: false, default: 'warning' }, // info, warning, error, success
			icon: { type: String, required: false, default: 'warning' }, // $alert, $info, etc.
			headingText: String,
			bodyText: String,
			hideNo: { type: Boolean, required: false, default: false },
			yesText: { type: String, required: false, default: 'Yes' },
			noText: { type: String, required: false, default: 'No' },
			persistent: { type: Boolean, default: false },
			confirmText: { type: String, required: false, default: '' },
			confirmHint: { type: String, required: false, default: '' },
			timeOut: {type: Number, required: false},
			forceTimeoutEventId: {type: String, required: false, default: null},
			align: {type: String, required: false, default: 'center'}
		},

		data() {
			return {
				confirmation: '',
			};
		},

		mounted() {
			if(this.timeOut){
				setTimeout(() => this.handleClose(),this.timeOut*1000);
			}

			if(this.forceTimeoutEventId){
				this.$store.state.eventBus.$on(this.forceTimeoutEventId,this.handleClose);
			}
		},

		beforeUnmount() {
			if(this.forceTimeoutEventId){
				this.$store.state.eventBus.$off(this.forceTimeoutEventId,this.handleClose);
			}
		},

		methods: {
			handleEnter() {
				if (!this.submitDisabled) {
					this.handleSubmit();
				}
			},
			handleSubmit() {
				this.$emit('result', true);
			},
			handleClose() {
				this.$emit('result', false);
			},
		},

		computed: {
			submitDisabled() {
				if (!this.confirmText) return false;
				return !this.confirmation || this.confirmation !== this.confirmText;
			},
		},
	};
</script>

<style lang="scss">
	.confirm-dialog-div {
		background-color: rgb(var(--v-theme-white));
		position: relative;
		border-radius: 4px !important;
		max-width: 360px;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
