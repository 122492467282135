<template>
	<div class="row-format align-center gap-1" ref="el">
		<component :is="iconComponent" class="material-symbols-outlined" color="warning" :size="24">warning</component>
		<div>Test</div>
	</div>
</template>

<script>
import {onMounted, onBeforeUnmount, shallowRef, ref, getCurrentInstance, nextTick} from 'vue';
	import PIcon from '@/components/PIcon';
import {useStore} from "vuex";

	export default {
		name: 'Test',
		inputs: 1,
		outputs: 1,

		components: {  },

		setup() {
			let df = null
			const el = ref(null);
			const dataNode = ref({});
			const nodeId = ref("");
			const iconComponent = shallowRef(PIcon);
			const store = useStore();

			df = getCurrentInstance().appContext.config.globalProperties.$df.value;

			// Method defined within setup
			const handleClick = () => {
				store.state.eventBus.$emit('workflow-node',nodeId.value);
			};

			// Lifecycle hook
			onMounted(async() => {
				await nextTick();
				nodeId.value = el.value.parentElement.parentElement.id.slice(5)
				dataNode.value = df.getNodeFromId(nodeId.value);
			});

			onBeforeUnmount(() => {
				console.log('Component about to unmount');
			});

			// Make handleClick available to the template
			return {
				el,
				handleClick,
				iconComponent
			};
		},
	};
</script>

<style scoped lang="scss">
	button {
		background-color: #04aa6d; /* Green */
		border: none;
		color: white;
		padding: 15px 32px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 16px;
	}
</style>
