<template>
	<v-container fluid class="" style="max-height: calc(100vh - 80px); overflow-y: auto">
		<v-row>
			<v-col cols="12">
				<module-header name="Dashboard" :workspace-selector="true">
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="column-format gap-12">
				<lead-chart style="max-height: 350px; max-width: 100%" :campaign-stats="campaignStats" v-if="campaignStats"></lead-chart>
				<sentry-chart style="max-height: 250px; max-width: 100%" :campaign-stats="campaignStats" v-if="campaignStats"></sentry-chart>
				<sms-chart style="max-height: 350px; max-width: 100%" :campaign-stats="campaignStats" v-if="campaignStats"></sms-chart>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import PDateTime from '@/modules/utils/PDateTime';
	import CampaignService from "@/modules/campaigns/CampaignService";
	import ModuleHeader from "@/components/ModuleHeader";
	import SmsChart from "@/modules/campaigns/charts/SmsChart";
	import SentryChart from "@/modules/campaigns/charts/SentryChart";
	import LeadChart from "@/modules/campaigns/charts/LeadChart";

	export default defineComponent({
		name: 'Dashboard',

		props: [],

		components: {ModuleHeader,LeadChart,SentryChart,SmsChart},

		data: function () {
			return {
				DateTime: PDateTime,
				campaignService: new CampaignService(),
				campaignStats: {
					listStats: [],
					sentryStats: [],
					smsStats: [],
				},
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.getCampaignStats);
			this.getCampaignStats();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.getCampaignStats);
		},

		methods: {
			async getCampaignStats() {
				try {
					let result = await this.campaignService.getAllCampaignStats(this.workspaceId);

					let listStats = result.data.listStats;
					let sentryStats = result.data.sentryStats;
					let smsStats = result.data.smsStats;

					if (listStats.length) {
						let result = this.fillOutStats(listStats, {
							date: null,
							total: 0,
							inactive: 0,
							invalid: 0,
							valid: 0,
						});
						this.campaignStats.listStats.replace(result);
					}

					if (smsStats.length) {
						let result = this.fillOutStats(smsStats, {
							date: null,
							sent: 0,
							delivered: 0,
							failed: 0,
						});
						this.campaignStats.smsStats.replace(result);
					}

					if (sentryStats.length) {
						let result = this.fillOutStats(sentryStats, {
							date: null,
							replaced: 0,
						});
						this.campaignStats.sentryStats.replace(result);
					}
				} catch (err) {
					console.log(err);
				}
			},

			fillOutStats(statsArray, defaultObject) {
				let start = this.DateTime.fromISO(statsArray[0].date).startOf('day');
				let end = this.DateTime.fromISO(statsArray[statsArray.length - 1].date).startOf('day');
				let result = [];

				while (start <= end) {
					let date = start.toISODate();
					let stats = statsArray.find((s) => s.date === date);
					if (stats) {
						stats.date = start.toLocaleString({ month: 'short', day: 'numeric' });
						result.push(stats);
					} else {
						let empty = JSON.parse(JSON.stringify(defaultObject));
						empty.date = start.toLocaleString({ month: 'short', day: 'numeric' });
						result.push(empty);
					}
					start = start.plus({ days: 1 });
				}

				return result;
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss"></style>
