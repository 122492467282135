<template>
	<div id="list-edit" class="panel-modal">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon :size="24" class="mr-2 pointer" @click.stop="$emit('result', list)">close</p-icon>
				<div class="brand-medium font-18" v-if="list">{{ list.name }}</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="process()"> Process list </v-btn>
				</div>
			</div>
		</div>
		<div class="panel-modal-body">
			<div class="column-format gap-3 my-2">
				<v-autocomplete
					v-if="messagingEnabled"
					v-model="type"
					label="List processing type"
					:items="loadType"
					item-value="value"
					item-title="label"
					variant="outlined"
					density="compact"
					hide-details
				></v-autocomplete>

				<v-autocomplete
					v-model="mappingTemplate"
					v-if="mappingTemplates.length && isDialerType"
					@change="mappingTemplateModified = false"
					label="Dialer mapping template"
					:items="mappingTemplates"
					item-value="id"
					item-title="name"
					variant="outlined"
					density="compact"
					hide-details
					clearable
				>
					<template #item="{ item, props }">
						<v-list-item v-bind="props">
							<template #title>
								<span>{{ item.title }}</span>
							</template>
							<template #append>
								<v-icon class="material-symbols-outlined ml-auto" @click.stop="confirmDeleteTemplate(item)"
									>delete</v-icon
								>
							</template>
						</v-list-item>
					</template>
				</v-autocomplete>
				<v-autocomplete
					v-if="isNotRingOrPinpointDialer"
					v-model="selectedList"
					label="Send to dialer list"
					:items="dialerLists"
					variant="outlined"
					density="compact"
					hide-details
					clearable
					item-value="id"
					item-title="name"
				></v-autocomplete>
				<v-combobox
					v-if="isRingDialer"
					v-model="selectedList"
					label="Send to dialer list"
					:items="dialerLists"
					variant="outlined"
					density="compact"
					hide-details
					clearable
					item-value="value"
					item-title="name"
					:return-object="false"
				></v-combobox>
				<v-autocomplete
					v-if="isRingDialer"
					v-model="selectedDedupe"
					label="De-dupe list"
					:items="dedupeOptions"
					variant="outlined"
					density="compact"
					hide-details
					clearable
					item-value="value"
					item-title="label"
				></v-autocomplete>
				<v-autocomplete
					v-if="isFive9Dialer"
					v-model="mode"
					label="Dialer processing mode"
					:items="modeList"
					item-value="value"
					item-title="label"
					variant="outlined"
					density="compact"
					hide-details
				></v-autocomplete>
				<Five9Config
					v-if="isFive9Dialer"
					:single="false"
					@crm-add-chosen="onCrmAddChosen"
					@crm-update-chosen="onCrmUpdateChosen"
					@list-add-chosen="onListAddChosen"
				/>
			</div>
			<div class="select-container column-format gap-3 pt-3 mb-8" v-if="isDialerType">
				<h2>Dialer list mapping</h2>
				<v-data-table
					:headers="headers"
					:items="dataPreview"
					:items-per-page="isMessagingType ? 3 : 5"
					class="elevation-1"
				>
					<template v-slot:headers>
						<tr>
							<th v-for="(header, index) in headersComputed" :key="header.value">
								<h4>
									<v-menu v-model="header.menuOpen" :close-on-content-click="true">
										<template v-slot:activator="{ props }">
											<div
												class="header-container row-format align-center"
												v-tippy="{ content: header.title }"
											>
												<div
													:class="`pointer header-selector d-inline-block text-truncate ${
														header.mapped ? 'text-primary' : ''
													}`"
													v-bind="props"
												>
													{{ header.mapped ? header.columnId : header.title }}
												</div>
												<v-icon
													size="20"
													color="primary"
													class="pointer"
													v-if="header.mapped"
													@click.stop="deSelectField(index)"
													>clear</v-icon
												>
											</div>
										</template>
										<div
											class="column-format more-menu"
											style="max-height: calc(100vh - 100px); min-width: 200px; overflow-y: auto"
										>
											<v-text-field
												density="compact"
												autofocus
												v-model="headerFilter"
												hide-details
												placeholder="Filter..."
												@click.stop
											></v-text-field>
											<div
												v-for="field in dialerFieldsFiltered"
												:key="field"
												class="more-menu-item"
												@click="() => selectField(field, header, index)"
											>
												{{ field.columnId }}
											</div>
										</div>
									</v-menu>
								</h4>
							</th>
						</tr>
					</template>
					<template #bottom></template>
				</v-data-table>
			</div>
			<div class="select-container column-format gap-3" v-if="isMessagingType && messagingEnabled">
				<h2>Message service mapping</h2>
				<messaging-list-mapper
					v-if="messagingHeaders"
					:headers="messagingHeaders"
					:data-preview="dataPreview"
					:tokens="campaign.messagingConfig.tokens"
					:is-dialer-type="isDialerType"
					@change="updateMessageMapping($event)"
				></messaging-list-mapper>
			</div>
		</div>
	</div>
</template>
<script>
	import { defineComponent } from 'vue';
	import CampaignListService from '@/modules/campaigns/CampaignListService';
	import DialerService from '@/modules/dialers/DialerService';
	import MappingTemplateService from "@/modules/campaigns/MappingTemplateService";
	import SimpleTextInput from "@/components/SimpleTextInput";
	import ConfirmModal from "@/components/ConfirmModal";
	import CampaignService from "@/modules/campaigns/CampaignService";
	import MessagingListMapper from "@/modules/campaigns/MessagingListMapper";
	import WorkspaceService from '@/modules/workspaces/WorkspaceService';
	import Five9Config from '@/modules/campaigns/Five9Config.vue';

	export default defineComponent({
		name: 'ListEdit',
		isRightModal: false,

		props: ['id', 'campaignId', 'workspaceId', 'list', 'dialerCampaignId'],

		components: { Five9Config, MessagingListMapper },

		data: function () {
			return {
				isValid: true,
				listPreview: null,
				headerFilter: null,
				mode: 'REPLACE',
				type: 'DIALER',
				dialerProviderType: null,
				dialerFields: [],
				mapping: [],
				headersFromFile: [],
				dataPreview: [],
				headers: [],
				messagingHeaders: null,
				messagingMappedCols: [],
				dialerLists: [],
				dedupeOptions: [
					{ value: 'RING_REMOVE_ALL_EXISTING', label: 'Yes, remove duplicates found in existing lists' },
					{ value: 'RING_REMOVE_FROM_LIST', label: 'Yes, remove duplicates found in this list' },
					{ value: 'RING_RETAIN_ALL', label: 'No, retain duplicates' },
				],
				mappingTemplates: [],
				campaign: null,
				selectedList: null,
				selectedDedupe: null,
				mappingTemplate: null,
				mappingTemplateModified: false,
				campaignListService: new CampaignListService(),
				mappingTemplateService: new MappingTemplateService(),
				dialerService: new DialerService(),
				campaignService: new CampaignService(),
				workspaceService: new WorkspaceService(),
				modeList: [
					{ value: 'REPLACE', label: 'Replace all records' },
					{ value: 'APPEND', label: 'Append to list' },
				],
				loadType: [
					{ value: 'DIALER', label: 'Dialer Only - send all records to dialer' },
					{ value: 'MESSAGING', label: 'Messaging Only - send mobile records to messaging campaign' },
					{
						value: 'COMBINED',
						label: 'Combined - send mobile records to messaging service and landline records to dialer'
					},
				],
				five9Modifiers: {
					crmAdd: null,
					crmUpdate: null,
					listAdd: null,
				},
			};
		},

		mounted() {
			console.log('listedit mounted');
			this.getMappingTemplates();
			this.getDialerLists();
			this.getList();
			this.getDialerProviderType();
		},

		beforeUnmount() {},

		methods: {
			selectField(field, header, index) {
				let mappedCol = {
					columnIndex: index,
					columnId: field.columnId
				}
				let ix = this.mapping.findIndex(m => m.columnIndex === index);
				if (ix > -1) {
					this.mapping.splice(ix, 1, mappedCol);
				} else {
					this.mapping.push(mappedCol);
				}
				header.menuOpen = false;
				this.headerFilter = null;
				this.mappingTemplate = null;
				this.mappingTemplateModified = true;
			},

			deSelectField(index) {
				let ix = this.mapping.findIndex(m => m.columnIndex === index);
				if (ix > -1) {
					this.mapping.splice(ix, 1);
				}
				this.mappingTemplate = null;
				this.mappingTemplateModified = true;
			},

			updateMessageMapping(mapping) {
				this.messagingMappedCols = mapping;
			},

			getDialerLists() {
				this.dialerService.getCampaign(this.workspaceId, this.dialerCampaignId).then((res) => {
					this.dialerLists.replace(res.data.lists);
				});
			},

			async getDialerProviderType() {
				let result = await this.workspaceService.getWorkspace(this.workspaceId);
				this.dialerProviderType = result.data.dialerProviderType;
			},

			async getMappingTemplates() {
				let result = await this.mappingTemplateService.getMappingTemplates(this.workspaceId);
				this.mappingTemplates.replace(result.data);
			},

			async getCampaign() {
				let result = await this.campaignService.getCampaign(this.workspaceId, this.campaignId);
				this.campaign = result.data;
			},

			async getList() {
				try {
					this.$store.commit('startLoading');

					let result = await this.campaignListService.getCampaignListPreview(
						this.workspaceId,
						this.campaignId,
						this.id
					);

					await this.getMappingTemplates();
					await this.getCampaign();

					this.listPreview = result.data;
					this.headersFromFile = this.listPreview[0];

					this.headers = this.headersFromFile.map((header, index) => {
						return { title: header, key: `field_${index}`, sortable: false, menuOpen: false };
					});

					this.messagingHeaders = JSON.parse(JSON.stringify(this.headers));

					this.dataPreview = this.listPreview
						.map((data) => {
							const newData = {};
							data.forEach((data2, index) => {
								newData[`field_${index}`] = data2;
							});
							return newData;
						})
						.slice(1, this.dataPreview.length - 1);

					this.dialerFields = (
						await this.dialerService.getMappingFieldsForCampaign(this.workspaceId, this.campaign.dialerCampaignId)
					).data;
					this.dialerFields.sort((a, b) => a.columnId.localeCompare(b.columnId));

					let template = this.mappingTemplates.find(m => m.id === this.campaign.defaultMappingTemplate);
					if (template) {
						this.mappingTemplate = template.id;
					} else {
						this.matchFields();
					}

					this.$store.commit('stopLoading');
				} catch (err) {
					console.log('🚀 ~ file: ListEdit.vue:94 ~ getList ~ err:', err);
					this.$store.commit('error', err?.response?.data?.message);
				}
			},

			matchFields() {
				this.mapping.splice(0);

				for (let i = 0; i < this.headers.length; i++) {
					let h = this.headers[i];
					let d = this.dialerFields.find(d => d.columnId === h.title)
					if (d) {
						this.mapping.push({
							columnIndex: i,
							columnId: d.columnId
						});
					}
				}
			},

			async process() {
				if (this.isDialerType && !this.selectedList && !this.isPinpointDialer) {
					return this.$store.commit('error', 'Select a list.');
				}

				if (
					this.isMessagingType &&
					!this.isDialerType &&
					this.messagingMappedCols.findIndex(m => m.columnId === 'Phone') === -1
				) {
					return this.$store.commit('error', 'Phone mapping is required for messaging campaign.');
				} else if (
					this.isMessagingType &&
					!this.isDialerType &&
					this.messagingMappedCols.findIndex(m => m.columnId === 'Zip') === -1
				) {
					return this.$store.commit('error', 'Zip mapping is required for messaging campaign.');
				}

				if (!this.isDialerType || this.mappingTemplate) {
					await this.submitListProcess(null, false);
				} else {
					let binding = {
						instructions: 'Would you like to save these mapping settings as a template?',
						label: 'Template name',
						yesText: 'Yes',
						noText: 'No'
					}
					this.$store.state.globalModalController.openModal(SimpleTextInput, binding).then((res) => {
						if (res) {
							this.submitListProcess(res, true);
						} else {
							this.submitListProcess(null, false);
						}
					});
				}
			},

			async submitListProcess(templateName, saveAsTemplate) {
				const list = {
					type: this.type,
					mode: this.mode,
					dialerListId: this.selectedList,
					dedupe: this.selectedDedupe,
					mappedCols: this.mapping,
					messagingMappedCols: this.messagingMappedCols,
					templateName: templateName,
					saveAsTemplate: saveAsTemplate,
					crmUpdateMode: this.five9Modifiers.crmUpdate,
					crmAddMode: this.five9Modifiers.crmAdd,
					listAddMode: this.five9Modifiers.listAdd,
				};

				try {
					this.$store.commit('startLoading');
					await this.campaignListService.createCampaignListProcess(this.workspaceId, this.campaignId, this.id, list);
					setTimeout(() => this.$emit('result'), 250);
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},

			confirmDeleteTemplate(event) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this mapping template?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.mappingTemplateService.deleteMappingTemplate(this.workspaceId, event.id).then(() => {
							let ix = this.mappingTemplates.findIndex(m => m.id === event.id);
							if (ix > -1) {
								this.$store.commit('success', 'Mapping template deleted');
								this.mappingTemplates.splice(ix, 1);
							}

							if (event.id === this.mappingTemplate) {
								this.mappingTemplate = null;
							}
						});
					}
				});
			},
			onCrmAddChosen: function(value) {
				this.five9Modifiers.crmAdd = value;
			},
			onCrmUpdateChosen: function(value) {
				this.five9Modifiers.crmUpdate = value;
			},
			onListAddChosen: function(value) {
				this.five9Modifiers.listAdd = value;
			},
		},

		watch: {
			mappingTemplate: function (val) {
				if (this.mappingTemplateModified) {
					return;
				}
				if (val) {
					let template = this.mappingTemplates.find(t => t.id === val);
					if (template) {
						this.mapping.replace(template.mapping);
					}
				} else {
					this.matchFields();
				}
			},
		},

		computed: {
			isDialerType: function () {
				return this.type === 'COMBINED' || this.type === 'DIALER'
			},

			isNotRingOrPinpointDialer: function () {
				return !this.isRingDialer && !this.isPinpointDialer;
			},

			isRingDialer: function () {
				return this.isDialerType && this.dialerProviderType === 'RING'
			},

			isFive9Dialer: function () {
				return this.isDialerType && this.dialerProviderType === 'FIVE9'
			},

			isPinpointDialer: function () {
				return this.isDialerType && this.dialerProviderType === 'CONNECT'
			},

			isMessagingType: function () {
				return this.type === 'COMBINED' || this.type === 'MESSAGING'
			},

			messagingEnabled: function () {
				return this.campaign && this.campaign.messagingConfig.enabled;
			},

			headersComputed: function () {
				let result = [...this.headers];

				for (let i = 0; i < result.length; i++) {
					let mapping = this.mapping.find(m => m.columnIndex === i);

					if (mapping) {
						result[i].columnId = mapping.columnId;
						result[i].mapped = true;
					} else {
						result[i].columnId = null;
						result[i].mapped = false;
					}
				}
				return result;
			},

			dialerFieldsFiltered: function () {
				let result = [...this.dialerFields];
				return result
					.filter(r => {
						if (this.headerFilter) {
							return r.columnId.toLowerCase().includes(this.headerFilter.toLowerCase());
						} else {
							return true;
						}
					})
					.filter(r => this.mapping.findIndex(m => m.columnId === r.columnId) === -1)
					.filter(r => this.campaign.validationDataMapping.mappedColumns.findIndex(m => m === r.columnId) === -1);
			}
		},
	});
</script>

<style scoped lang="scss">
	#list-edit {
		.header-container {
			display: flex;
			.header-selector {
				max-width: 120px;
				&:hover {
					color: rgb(var(--v-theme-secondary));
				}
			}
			.unselect {
				&:hover {
					color: rgb(var(--v-theme-secondary));
				}
			}
		}

		.found {
			color: rgb(var(--v-theme-primary));
		}
		.tab {
			background-color: rgb(var(--v-theme-gray_20));
			border: 1px solid rgb(var(--v-theme-gray_20));
			color: rgb(var(--v-theme-gray_70));
			border-radius: 18px;
			text-align: center;
			padding: 2px 12px;
			cursor: pointer;

			&:hover {
				background-color: rgb(var(--v-theme-gray_30));
				border: 1px solid rgb(var(--v-theme-gray_70));
				color: rgb(var(--v-theme-secondary));
			}
		}

		.tab.active {
			background-color: rgb(var(--v-theme-gray_30));
			border: 1px solid rgb(var(--v-theme-gray_70));
			color: rgb(var(--v-theme-secondary));
		}

		.event-box {
			border-top: 1px solid rgb(var(--v-theme-gray_50));
		}
	}
</style>
