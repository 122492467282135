<template>
	<div class="row-format align-center gap-1" ref="el">
		<component :is="iconComponent" class="material-symbols-outlined" color="success" :size="24">play_circle</component>
		<div>Start</div>
	</div>
</template>

<script>
import {onMounted, onBeforeUnmount, shallowRef, ref, getCurrentInstance, nextTick} from 'vue';
import PIcon from '@/components/PIcon';
import {useStore} from "vuex";

export default {
	name: 'Start',
	inputs: 0,
	outputs: 1,

	components: {  },

	setup() {
		let df = null
		const el = ref(null);
		const dataNode = ref({});
		const nodeId = ref("");
		const iconComponent = shallowRef(PIcon);
		const store = useStore();

		df = getCurrentInstance().appContext.config.globalProperties.$df.value;

		// Method defined within setup
		const handleClick = () => {
			store.state.eventBus.$emit('workflow-node',nodeId.value);
		};

		// Lifecycle hook
		onMounted(async() => {
			await nextTick();
			nodeId.value = el.value.parentElement.parentElement.id.slice(5)
			dataNode.value = df.getNodeFromId(nodeId.value);
		});

		onBeforeUnmount(() => {
			console.log('Component about to unmount');
		});

		// Make handleClick available to the template
		return {
			el,
			handleClick,
			iconComponent
		};
	},
};
</script>

<style scoped lang="scss">
</style>
