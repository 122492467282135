import HttpClient from '@/services/HttpClient';

export default class WebhookService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getWebhooks(workspaceId) {
		return await this.client.get(`/workspaces/${workspaceId}/webhooks`);
	}

	async getWebhook(workspaceId,id) {
		return await this.client.get(`/workspaces/${workspaceId}/webhooks/${id}`);
	}

	async createWebhook(workspaceId,webhook){
		return await this.client.post(`/workspaces/${workspaceId}/webhooks`,webhook);
	}

	async updateWebhook(workspaceId,id,webhook){
		return await this.client.put(`/workspaces/${workspaceId}/webhooks/${id}`,webhook);
	}

	async deleteWebhook(workspaceId,id){
		return await this.client.delete(`/workspaces/${workspaceId}/webhooks/${id}`);
	}
}
