import PoolNumberList from "@/modules/pools/PoolNumberList";

export default [
    {
        path: '/pools',
        name: 'pools',
        component: PoolNumberList,
        title: 'Pool Numbers',
        props: true
    },

]