<template>
	<div class="text-left" style="">
		<div class="brand-medium font-16">API Keys</div>
		<div class="row-format gap-2">
			<input type="hidden" id="copyInput">
			<v-text-field hide-details density="compact" persistent-placeholder variant="outlined" placeholder="Key name" v-model="newKeyName"></v-text-field>
			<v-btn class="px-2" size="medium" variant="outlined" color="secondary" :disabled="!newKeyName" @click="createApiKey">Create API key</v-btn>
		</div>

		<v-data-table
				id="api-key-table"
				:items="apiKeys"
				v-model:items-per-page="itemsPerPage"
				:headers="headers"
				class="pointer"
				@click:row="copyLink"
		>
			<template v-slot:[`item.encryptedKey`]="{ item }">{{item.encryptedKey.substring(0,8)}}...</template>
			<template v-slot:[`item.created`]="{ item }">{{DateTime.fromISO(item.created).toLocaleString(DateTime.DATETIME_MED)}}</template>
			<template v-slot:[`item.lastActivity`]="{ item }"><span v-if="!item.lastActivity">--</span><span v-else>{{DateTime.fromISO(item.lastActivity).toLocaleString(DateTime.DATETIME_MED)}}</span></template>
			<template v-slot:[`item.action`]="{ item }"><span class="material-symbols-outlined" style="font-size: 20px; color: rgb(var(--v-theme-gray_70))" @click.stop="confirmDelete(item)">delete</span></template>
		</v-data-table>
		<div v-if="!apiKeys.length" class="font-12 font-italic mt-3">
			No API keys configured for this workspace
		</div>
	</div>
</template>

<script>
import {defineComponent} from 'vue';
import ApiKeyService from "@/modules/workspaces/ApiKeyService";
import {DateTime} from 'luxon';
import ConfirmModal from "@/components/ConfirmModal";

export default defineComponent({
	name: 'ApiKeys',

	props: ['workspace'],

	components: {},

	data: function () {
		return {
			DateTime:DateTime,
			itemsPerPage: -1,
			newKeyName: null,
			apiKeys: [],
			apiKeyService: new ApiKeyService(),
			headers: [
				{ title: 'Name', key: 'name', align: 'start' },
				{ title: 'Key', key: 'encryptedKey', align: 'start' },
				{ title: 'Created', key: 'created', align: 'start' },
				{ title: 'Last used', key: 'lastActivity', align: 'start' },
				{ title: '', key: 'action', align: 'end' },
			],
		};
	},

	mounted() {
		this.getApiKeys();
	},

	beforeUnmount() {
	},

	methods: {
		async getApiKeys() {
			let result = await this.apiKeyService.getApiKeys(this.workspace.id);
			this.apiKeys.replace(result.data);
		},

		async createApiKey() {
			let result = await this.apiKeyService.createApiKey(this.workspace.id,this.newKeyName);
			this.newKeyName = null;
			this.apiKeys.push(result.data);
		},

		confirmDelete(item) {
			let binding = {
				headingText: 'Confirm!',
				bodyText: `Are you sure you want to delete this API key?`
			};
			this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
				if (res) {
					this.apiKeyService.deleteApiKey(this.workspace.id, item.id).then(() => {
						let ix = this.apiKeys.findIndex(s => s.id === item.id);
						this.apiKeys.splice(ix,1);
					});
				}
			});
		},

		copyLink: function(event, row){
			let copyText = document.getElementById('copyInput');
			copyText.type = 'text';
			copyText.value = row.item.encryptedKey;
			copyText.select();
			document.execCommand('copy');
			copyText.type = 'hidden';

			this.$store.commit('info', 'Copied to clipboard');
		}
	},

	computed: {},
});
</script>

<style scoped lang="scss">
	#api-key-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
