<template>
	<div class="font-14">
		<div><strong>To:</strong> {{ interaction.to }}</div>
		<div>
			<strong>From:</strong> {{ interaction.from }}
			<span v-if="interaction.pcidFromNumber">(replaced with: {{ interaction.pcidFromNumber }})</span>
		</div>
		<div>
			<strong>Geo-location:</strong> {{ interaction.city }}, {{ interaction.state }} {{ interaction.zip }} ({{
				interaction.timezone
			}})
		</div>
		<div>
			<strong>Call Time:</strong>
			{{
				DateTime.fromISO(interaction.timestamp)
						.setZone(interaction.timezone)
						.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
			}}
		</div>
		<div v-if="interaction.sipCallResult">
			<strong>Call Result:</strong>
			{{interaction.sipCallResult.status}} / {{interaction.sipCallResult.hangupCause}}
		</div>
		<div v-if="interaction.sipCallResult">
			<strong>Call Duration:</strong>
			{{interaction.sipCallResult.duration}} seconds
		</div>
		<div class="row-format align-center">
			<strong>Aegis Result:</strong> &nbsp;{{ interaction.result }} &nbsp;
			<p-icon :size="20" color="success" v-if="interaction.result === 'Accepted'">verified</p-icon>
			<p-icon v-else :size="20" color="error">report</p-icon>
			<p-icon :size="20" v-if="interaction.isTest" color="warning" v-tippy="{content: 'Test Call'}">construction</p-icon>
		</div>
		<div>
			<strong>Reason:</strong> {{ interaction.reason }}
		</div>
		<div v-if="interaction.runTimeInSeconds"><strong>Execution time:</strong> {{ interaction.runTimeInSeconds }} seconds</div>

		<v-container
			style="border: 1px solid rgb(var(--v-theme-gray_30)); max-height: calc(100vh - 550px); overflow-y: scroll"
			class="mt-3"
		>
			<v-row
				v-for="(event, index) in interaction.events"
				:key="index"
				:style="index < interaction.events.length - 1 ? 'border-bottom: 1px solid rgb(var(--v-theme-gray_30))' : ''"
			>
				<v-col cols="1" class="row-format centered">
					<p-icon :size="20" color="success" v-if="event.type === 'SUCCESS'">verified</p-icon>
					<p-icon :size="20" color="info" v-if="event.type === 'INFO'">info</p-icon>
					<p-icon :size="20" color="warning" v-if="event.type === 'WARNING'">warning</p-icon>
					<p-icon :size="20" color="error" v-if="event.type === 'ERROR'">report</p-icon>
				</v-col>
				<v-col cols="1">{{ calculateTime(index) }}</v-col>
				<v-col cols="10">{{ event.message }}</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { DateTime } from 'luxon';

	export default defineComponent({
		name: 'InteractionDetail',

		props: ['interaction'],

		components: {},

		data: function () {
			return {
				DateTime: DateTime
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			calculateTime: function (index) {
				if (index === 0) {
					return '--';
				} else {
					let start = DateTime.fromISO(this.interaction.events[0].timestamp);
					let end = DateTime.fromISO(this.interaction.events[index].timestamp);
					let diffInMilliseconds = end.diff(start).milliseconds;
					return Number(diffInMilliseconds / 1000).toFixed(3);
				}
			},
		},

		computed: {},
	});
</script>

<style scoped lang="scss"></style>
