import HttpClient from "@/services/HttpClient";

export default class UtilityService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getCnam(number) {
        return await this.client.get(`/utility/cnamCheck`, {params: {number}});
    }

    async getSpam(number) {
        return await this.client.get(`/utility/spamCheck`, {params: {number}});
    }

    async ping(){
        return await this.client.get(`/utility/ping`);
    }
}