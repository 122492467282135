<template>
	<div :class="`row-format align-center scope-button ${scope === activeScope || isActive ? 'active' : ''}`" @click="$emit('scope-update',scope)">
		<div>{{label}}</div>
	</div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
	name: "ScopeButton",

	props: ['scope','label','activeScope','isActive'],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	methods: {},

	computed: {},

})
</script>

<style scoped lang="scss">
	.scope-button {
		background-color: rgb(var(--v-theme-white));
		color: rgb(var(--v-theme-gray_70));
		border: 1px solid rgb(var(--v-theme-gray_50));
		border-radius: 12px;
		padding: 4px 8px;
		width:fit-content;
		font-size: 12px;
		font-weight: 500;
		cursor: pointer;
		white-space: nowrap;

		&:hover {
			background-color: rgb(var(--v-theme-gray_20));
		}
	}

	.active {
		background-color: rgb(var(--v-theme-gray_20));
		color: rgb(var(--v-theme-secondary));
		border: 1px solid rgb(var(--v-theme-gray_50));
	}
</style>