<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header
					name="Leads"
					add-label="Search leads"
					@add-new="searchLeads()"
					:workspace-selector="true"
				>
					<template v-slot:filter>
						<v-text-field density="compact" placeholder="555-555-1212" hide-details variant="outlined" v-model="search" style="min-width: 200px"></v-text-field>
					</template>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					id="lead-table"
					:items="fixedUpLeads"
					v-model:items-per-page="itemsPerPage"
					:headers="headers"
					class="pointer"
					fixed-header
					:height="leads.length ? 'calc(100vh - 150px)' : ''"
				>
					<template v-slot:[`item.loaded`]="{ item }">{{ DateTime.fromISO(item.loaded).toLocaleString(DateTime.DATE_FULL) }}</template>
					<template v-slot:[`item.campaignName`]="{item}"><span @click.stop="openCampaign(item)">{{item.campaignName}}</span></template>
				</v-data-table>

				<div class="row-format centered mt-5 font-14" v-if="leads.length === 0">
					<div v-if="searchComplete">No numbers found starting with {{search}}</div>
					<div v-else>Please enter at least 3 digits of the number you are looking for.</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import PDateTime from "@/modules/utils/PDateTime";
	import CampaignService from "@/modules/campaigns/CampaignService";
	import CampaignLeadService from "@/modules/leads/CampaignLeadService";

	export default defineComponent({
		name: 'LeadList',

		props: [],

		components: { ModuleHeader },

		data: function () {
			return {
				DateTime: PDateTime,
				leads: [],
				campaigns: [],
				search: null,
				searchComplete: false,
				campaignService: new CampaignService(),
				campaignLeadService: new CampaignLeadService(),
				itemsPerPage: -1,
				headers: [
					{ title: 'Number', key: 'leadKey.phone', align: 'start' },
					{ title: 'Campaign', key: 'campaignName', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'Loaded', key: 'loaded', align: 'start' },
					{ title: 'Caller ID', key: 'callerId', align: 'start' }
				],
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.init);
			this.init();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.init);
		},

		methods: {
			async init(){
				this.clearLeads();
				let result = await this.campaignService.getCampaigns(this.workspaceId);
				this.campaigns.replace(result.data);
			},

			openCampaign(lead){
				this.$router.push(`/campaigns/${lead.campaignId}`);
			},

			clearLeads: function(){
				this.leads.splice(0,this.leads.length);
			},

			async searchLeads() {
				if(!this.search || this.search.length < 3){
					this.$store.commit('error','Please provide at least the first 3 digits of the lead number to continue.');
					return;
				}
				let result = await this.campaignLeadService.getCampaignLeads(this.workspaceId,null,this.search);
				this.leads.replace(result.data);
				this.searchComplete = true;
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			fixedUpLeads: function(){
				let result = [... this.leads]

				result.forEach(n => {
					let campaign = this.campaigns.find(c => c.id === n.leadKey.campaignId);
					if(campaign){
						n.campaignName = campaign.name;
					}
				});

				return result;
			}
		},
	});
</script>

<style scoped lang="scss">
	#lead-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
