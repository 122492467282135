<template>
	<v-container>
		<v-row align="center" justify="center">
			<v-col cols="12">
				<div class="row-format centered">
					<div class="auth-logo"><img alt="logo" src="/images/logo.png" /></div>
				</div>
			</v-col>
			<v-col cols="12" sm="6" md="6" v-if="showError">
				<v-card class="elevation-12">
					<v-toolbar-title>
						<h2>Password Reset</h2>
						<span>This password reset link is no longer valid.</span>
					</v-toolbar-title>
				</v-card>
			</v-col>
				<v-col cols="12" sm="6" md="6" v-if="isReady && !showConfirm">
					<v-card class="elevation-12">
						<v-toolbar>
							<v-toolbar-title>
								<h2>Password Reset</h2>
								<span>Please enter your new password.</span>
							</v-toolbar-title>
						</v-toolbar>
						<v-form ref="form" v-model="valid" lazy-validation>
							<v-card-text class="column-format gap-3">
								<v-text-field
									autofocus
									v-model="password"
									:rules="passwordRules"
									label="New password"
									type="password"
									required
									hide-details variant="outlined" persistent-placeholder
								></v-text-field>
								<v-text-field
									v-model="confirmPassword"
									:rules="confirmPasswordRules"
									label="Confirm password"
									type="password"
									required
									hide-details variant="outlined" persistent-placeholder
								></v-text-field>
							</v-card-text>
							<v-card-actions class="v-col-12">
								<v-btn block color="primary" text @click="resetPassword" :disabled="!valid">Update password</v-btn>
							</v-card-actions>
						</v-form>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="6" v-if="isReady && showConfirm">
					<v-card class="elevation-12">
						<v-toolbar>
							<v-toolbar-title>
								<h2>Password Reset</h2>
								<span>Your password has been updated. Please sign in now.</span>
							</v-toolbar-title>
							<v-btn outlined @click="$router.push('/sign-in')">Return to sign in</v-btn>
						</v-toolbar>
					</v-card>
				</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';

	export default {
		name: 'ResetPassword',

		props: ['token'],

		data() {
			return {
				password: '',
				confirmPassword: '',
				valid: false,
				showConfirm: false,
				showError: false,
				isReady: false,

				passwordRules: AuthService.getPasswordRules(),

				confirmPasswordRules: [
					(v) => !!v || 'Confirm password is required',
					(v) => v === this.confirmPassword || 'Passwords do not match',
				],
			};
		},

		async mounted(){
			await this.verifyToken()
		},
		methods: {
			async verifyToken() {
				try {
					await AuthService.resetPasswordVerify(this.token);
					this.isReady = true
				} catch (error) {
					this.showError = true
				}
			},

			async resetPassword(){
				try {
					await AuthService.resetPassword(this.token, this.password);
					this.showConfirm = true
				} catch (error) {
					this.$store.commit('error', error.response.data.message)
				}
			},
		},
		computed: {},
	};
</script>
