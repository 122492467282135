<template>
	<div id="ftc-san-edit"  :class="`panel-modal ${$vuetify.display.mobile ? '' : 'desktop'}`" v-if="ftcSan">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<p-icon class="mr-2 pointer" @click.stop="$emit('result')">close</p-icon>
				<div class="brand-medium font-18">{{ftcSan.id ? 'Edit' : 'Create'}} FTC SAN</div>
				<div class="ml-auto row-format align-center">
					<v-btn elevation="0" color="secondary" @click.stop="save()" v-if="!isDisabled"> Save </v-btn>
					<p-icon :size="20" color="gray_70" class="pointer ml-2" @click="confirmDelete()" v-if="!isDisabled">delete</p-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body">
			<div class="column-format gap-2 mt-2">
				<v-text-field label="Org name" v-model="ftcSan.ftcOrgName" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<v-text-field label="Org Id" v-model="ftcSan.ftcOrgId" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<v-text-field label="SAN" v-model="ftcSan.ftcSan" :rules="required" density="compact" variant="outlined" hide-details persistent-placeholder :disabled="isDisabled"></v-text-field>
				<date-selector label="Expires" v-model="ftcSan.expires" :disabled="isDisabled"></date-selector>
				<v-checkbox label="Full coverage" v-model="ftcSan.fullCoverage" hide-details density="compact" :disabled="isDisabled"></v-checkbox>
				<div v-if="!ftcSan.fullCoverage">
					<v-textarea v-model="areaCodes" label="Area codes" variant="outlined" persistent-placeholder density="compact" hide-details rows="3" auto-grow  :disabled="isDisabled"></v-textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import FtcSanService from "@/modules/watchTower/FtcSanService";
	import DateSelector from "@/components/DateSelector";
	import ConfirmModal from "@/components/ConfirmModal";

	export default defineComponent({
		name: 'FtcSanDetail',
		isRightModal: true,

		props: ['id'],

		components: {DateSelector},

		data: function () {
			return {
				ftcSan: null,
				ftcSanService: new FtcSanService(),
				required: [(v) => !!v || 'Field is required'],
				areaCodes: null,
			};
		},

		mounted() {
			this.getFtcSan();
		},

		beforeUnmount() {},

		methods: {
			async getFtcSan(){
				if(this.id){
					let result = await this.ftcSanService.getFtcSan(this.workspaceId,this.id);
					this.ftcSan = result.data;
					this.areaCodes = this.ftcSan.areaCodes.join(", ");
				}else{
					this.ftcSan = this.newFtcSan();
				}
			},

			async save(){
				if(!this.ftcSan.id){
					let result = await this.ftcSanService.createFtcSan(this.workspaceId,this.ftcSan);
					this.$emit('result',result.data);
				}else{
					let result = await this.ftcSanService.updateFtcSan(this.workspaceId,this.ftcSan.id,this.ftcSan);
					this.$emit('result',result.data);
				}
			},

			confirmDelete(){
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete this FTC SAN?`
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if(this.ftcSan.id) {
							this.ftcSanService.deleteFtcSan(this.workspaceId, this.ftcSan.id);
							this.ftcSan.deleted = true;
							this.$emit('result', this.ftcSan);
						}else{
							this.$emit('result');
						}
					}
				});
			},

			newFtcSan(){
				return {
					id: null,
					ftcOrgName: null,
					ftcOrgId: null,
					ftcSan: null,
					expires: null,
					fullCoverage: true,
					areaCodes: []
				}
			}
		},

		watch: {
			areaCodes: function(){
				if(this.areaCodes) {
					let value = this.areaCodes.split(",");
					for (let i = 0; i < value.length; i++) {
						value[i] = value[i].trim();
					}
					this.ftcSan.areaCodes.replace(value);
				}else{
					this.ftcSan.areaCodes.splice(0);
				}
			}
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			isDisabled: function () {
				return this.$store.state.user.userType !== 'SuperAdmin';
			},
		},
	});
</script>

<style scoped lang="scss"></style>
