<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header
						name="Webhooks"
						:workspace-selector="true"
						add-label="Add webhook"
						@add-new="addNewWebhook"
				>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
						id="webhook-table"
						:items="webhooks"
						:items-per-page="-1"
						:headers="headers"
						class="pointer"
						fixed-header
						height="calc(100vh - 150px)"
						@click:row="editWebhook"
				>
				</v-data-table>

			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';

	import {DateTime} from 'luxon'
	import ModuleHeader from "@/components/ModuleHeader";
	import WebhookService from "@/modules/webhooks/WebhookService";
	import WebhookEdit from "@/modules/webhooks/WebhookEdit";
	
	export default defineComponent({
		name: 'Webhooks',

		props: [],

		components: { ModuleHeader},

		data: function () {
			return {
				DateTime: DateTime,
				webhookService: new WebhookService(),
				itemsPerPage: -1,
				webhooks: [],
				headers: [
					{ title: 'Name', key: 'name', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'Type', key: 'eventType', align: 'start' },
					{ title: 'URL', key: 'url', align: 'start' },
					{ title: 'Last response', key: 'lastResponse', align: 'start' },
					{ title: 'Last invocation', key: 'lastInvocation', align: 'start' },
				],
			};
		},

		mounted() {
			this.getWebhooks();
		},

		beforeUnmount() {},

		methods: {
			getWebhooks: function () {
				this.webhookService
					.getWebhooks(this.workspaceId)
					.then((res) => {
						this.webhooks.splice(0);
						this.webhooks.push(...res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			editWebhook: function (event, row) {
				let binding = {
					id: row.item.id,
				};
				this.$store.state.globalModalController.openModal(WebhookEdit, binding).then((res) => {
					if (res) {
						let ix = this.webhooks.findIndex((u) => u.id === binding.id);
						if (res.deleted) {
							this.webhooks.splice(ix, 1);
						} else {
							this.webhooks.splice(ix, 1, res);
						}
					}
				});
			},

			addNewWebhook: function () {
				this.$store.state.globalModalController.openModal(WebhookEdit, {id:null}).then((res) => {
					if (res) {
						this.webhooks.push(res);
					}
				});
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#webhook-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
