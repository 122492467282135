<template>
	<div class="column-format gap-3">
		<v-text-field v-model="item.label" variant="outlined" density="compact" hide-details persistent-placeholder label="Label" @change="updated()"></v-text-field>
		<period-configuration
				style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
				label="Sun"
				v-model="item.sunday"
		></period-configuration>
		<period-configuration
				style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
				label="Mon"
				v-model="item.monday"
		></period-configuration>
		<period-configuration
				style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
				label="Tue"
				v-model="item.tuesday"
		></period-configuration>
		<period-configuration
				style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
				label="Wed"
				v-model="item.wednesday"
		></period-configuration>
		<period-configuration
				style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
				label="Thu"
				v-model="item.thursday"
		></period-configuration>
		<period-configuration
				style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
				label="Fri"
				v-model="item.friday"
		></period-configuration>
		<period-configuration
				style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
				label="Sat"
				v-model="item.saturday"
		></period-configuration>
	</div>
</template>

<script>
import {defineComponent} from 'vue';
import PeriodConfiguration from "@/components/PeriodConfiguration";

export default defineComponent({
	name: 'TimeOfDay',

	props: ['node'],

	components: {PeriodConfiguration},

	data: function () {
		return {
			item: {...this.node}
		};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {
		updated: function(){
			this.$emit('updated',this.item);
		}
	},

	computed: {},
});
</script>

<style scoped lang="scss"></style>
