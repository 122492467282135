import HttpClient from '@/services/HttpClient';

export default class UsageReportService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    async getUsageData(workspaceId,type,searchRequest){
        return await this.httpClient.post(`/workspaces/${workspaceId}/usage/${type}`,searchRequest);
    }

    async downloadUsage(workspaceId, type, request) {
        return await this.httpClient
            .post(`/workspaces/${workspaceId}/usage/${type}/download`, request);
    }

}