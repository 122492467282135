<template>
	<div style="background-color: rgb(var(--v-theme-white)); border-radius: 4px" class="pa-4">
		<div class="row-format align-center pb-2" style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))">
			<div class="brand-medium font-18">Call detail</div>
			<div class="ml-auto row-format align-center">
				<p-icon class="pointer" @click="$emit('result')">close</p-icon>
			</div>
		</div>
		<div class="pt-3 pb-2">
			<interaction-detail :interaction="interaction"></interaction-detail>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import InteractionDetail from '@/modules/workflow/InteractionDetail';

	export default defineComponent({
		name: 'InteractionDetailModal',

		props: ['interaction'],

		components: { InteractionDetail },

		data: function () {
			return {};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {},

		computed: {},
	});
</script>

<style scoped lang="scss"></style>
