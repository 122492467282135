<template>
	<div style="width: 100%; height: 100%">
		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<module-header name="UC Protect" :workspace-selector="true"> </module-header>
				</v-col>
			</v-row>
			<v-row class="text-left" style="border-top: 1px solid rgb(var(--v-theme-gray_30)">
				<v-col cols="12" class="column-format gap-3">
					<div class="font-18 brand-medium">Add/Remove numbers</div>
					<div class="row-format gap-3" style="flex-wrap: wrap">
						<v-file-input
							v-model="csvFile"
							prepend-icon=""
							prepend-inner-icon="attachment"
							accept="text/csv"
							hide-details
							density="compact"
							variant="outlined"
							label="CSV File"
							style="min-width: 200px"
						></v-file-input>
						<v-select
							hide-details
							density="compact"
							variant="outlined"
							persistent-placeholder
							label="Action"
							v-model="action"
							:items="['Add', 'Remove']"
							style="min-width: 200px"
						></v-select>
						<v-select
							v-if="action === 'Add'"
							:items="businessRecords"
							v-model="businessRecordId"
							item-title="legalName"
							item-value="id"
							hide-details
							density="compact"
							variant="outlined"
							persistent-placeholder
							label="Associated business record"
							style="min-width: 200px"
						></v-select>
						<v-btn
							style="min-height: 40px"
							variant="outlined"
							color="primary"
							@click="action === 'Add' ? registerProtectNumbers() : deRegisterProtectNumbers()"
							>{{ action }} numbers</v-btn
						>
					</div>
					<div class="font-14 font-italic">
						Numbers must be uploaded in a CSV file format. The first column will contain the 10 digit phone number to
						be managed. If adding new numbers, an optional second column can be provided for the description of the
						number.
					</div>
				</v-col>
			</v-row>
			<v-row v-if="metrics" style="border-top: 1px solid rgb(var(--v-theme-gray_30)" class="mt-4 pt-4">
				<v-col cols="12" class="row-format gap-4" style="flex-wrap: wrap">
					<div class="metric pointer" @click="setFilter(null)">
						<div class="label">Managed numbers</div>
						<div class="value">{{ metrics.managedNumbers }}</div>
					</div>
					<div :class="`metric pointer`" @click="setFilter('spam')" :style="`${filter === 'spam' ? 'border: 1px solid rgb(var(--v-theme-secondary))' : ''}`">
						<div class="label">SPAM activity alerts</div>
						<div :class="`value ${metrics.spamLikely === 0 ? 'success' : 'error'}`">{{ metrics.spamLikely }}</div>
					</div>
					<div class="metric pointer"  @click="setFilter('cnam')" :style="`${filter === 'cnam' ? 'border: 1px solid rgb(var(--v-theme-secondary))' : ''}`">
						<div class="label">CNAM Errors</div>
						<div :class="`value ${metrics.cnamError === 0 ? 'success' : 'error'}`">{{ metrics.cnamError }}</div>
					</div>
					<div class="metric pointer" @click="setFilter('ftc')" :style="`${filter === 'ftc' ? 'border: 1px solid rgb(var(--v-theme-secondary))' : ''}`">
						<div class="label">FTC Complaints</div>
						<div :class="`value ${metrics.ftcComplaints === 0 ? 'success' : 'error'}`">{{ metrics.ftcComplaints }}</div>
					</div>
					<div class="metric">
						<div class="label">Registration pending</div>
						<div class="value">{{ metrics.pending }}</div>
					</div>
				</v-col>
			</v-row>
			<v-row class="mt-4 pt-0" v-if="pageResult">
				<v-col cols="12">
					<div class="row-format gap-2">
						<v-text-field
							hide-details
							density="compact"
							variant="outlined"
							label="+15555551212"
							style="max-width: 200px"
							v-model="searchFilter"
							@keydown.enter.exact="performSearch"
						></v-text-field>
						<v-btn style="min-height: 40px" color="secondary" @click="performSearch">Search</v-btn>
						<v-btn
							class="ml-auto"
							@click="downloadProtectNumbers"
							icon
							elevation="0"
							style="max-height: 40px"
							color="gray_50"
							v-tippy="{ content: 'Download UC Protect numbers' }"
							><v-icon>download</v-icon></v-btn
						>
					</div>
					<v-data-table
						id="protect-number-table"
						:headers="headers"
						:items="filteredNumbers"
						:items-per-page="20" style="max-height: calc(100vh - 500px)"
						:fixed-header="true"
						@click:row="handleRowClick"
					>
						<template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
							<tr>
								<template v-for="column in columns" :key="column.key">
									<th>
										<span v-tippy :content="column.tooltip" class="mr-2 cursor-pointer" @click="() => toggleSort(column)">{{ column.title }}</span>
										<template v-if="isSorted(column)">
											<v-icon :icon="getSortIcon(column)"></v-icon>
										</template>
									</th>
								</template>
							</tr>
						</template>

						<template v-slot:[`item.status`]="{ item }">
							{{
								item.status === 'INITIALIZING' || item.registrationPending ? 'Registration Pending' : item.status
							}}
						</template>
						<template v-slot:[`item.lastScanTime`]="{ item }">
							{{ item.lastScanTime ? DateTime.fromISO(item.lastScanTime).toLocaleString(DateTime.DATE_MED) : '--' }}
						</template>
						<template v-slot:[`item.cnam`]="{ item }">
							<div v-if="item.cnam">
								<v-icon color="error" v-if="item.cnam.invalid">warning</v-icon
								><span v-else>{{ item.cnam.name }}</span>
							</div>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
		<v-dialog v-model="showNumber" :persistent="false" style="max-width: 500px">
			<v-container style="background-color: rgb(var(--v-theme-white)); border-radius: 8px">
				<v-row>
					<v-col cols="12">
						<strong>Number:</strong> {{numberToShow.number}}<br>
						<strong>CNAM:</strong> {{numberToShow.cnam ? numberToShow.cnam.name : '--'}}<br>
						<strong>Last scan:</strong> {{ numberToShow.lastScanTime ? DateTime.fromISO(numberToShow.lastScanTime).toLocaleString(DateTime.DATE_MED) : '--' }}<br>
						<strong>Usage activity:</strong> {{numberToShow.validationResult ? numberToShow.validationResult.activity_score : '--'}}<br>
						<strong>SPAM activity:</strong> {{numberToShow.spamResult ? numberToShow.spamResult.riskLevel : '--'}}<br>
						<strong>FTC Complaints:</strong> {{numberToShow.ftcComplaints}}<br>
						<strong>Line type:</strong> {{numberToShow.validationResult ? numberToShow.validationResult.line_type : '--'}}<br>
						<strong>Carrier:</strong> {{numberToShow.validationResult ? numberToShow.validationResult.carrier : '--'}}<br>
					</v-col>
					<v-col cols="12" v-if="numberToShow.spamResult">
						<div v-if="numberToShow.spamResult.cleanCall"><strong>CleanCall:</strong> {{numberToShow.spamResult.cleanCall.recommendation}}</div>
						<div v-if="numberToShow.spamResult.icehookScout"><strong>Icehook Scout:</strong>  Risk rating [{{numberToShow.spamResult.icehookScout.risk_rating}}] / Risk level [{{numberToShow.spamResult.icehookScout.risk_level}}]</div>
						<div v-if="numberToShow.spamResult.nomoRobo"><strong>NomoRobo:</strong> Score [{{numberToShow.spamResult.nomoRobo.score}}]</div>
						<div v-if="numberToShow.spamResult.trueSpam"><strong>TrueSpam:</strong> In List [{{numberToShow.spamResult.trueSpam.spam_score_match ? 'Yes' : 'No'}}] / Is SPAM [{{numberToShow.spamResult.trueSpam.spam_score ? 'Yes' : 'No'}}] </div>
					</v-col>
				</v-row>
			</v-container>
		</v-dialog>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import ProtectService from '@/modules/protect/ProtectService';
	import BusinessRecordService from '@/modules/watchTower/BusinessRecordService';
	import { DateTime } from 'luxon';

	export default defineComponent({
		name: 'Protect',

		props: [],

		components: { ModuleHeader },

		data: function () {
			return {
				showNumber: false,
				numberToShow: null,
				DateTime: DateTime,
				page: 1,
				itemsPerPage: 20,
				searchFilter: null,
				businessRecordId: null,
				csvFile: null,
				businessRecords: [],
				action: 'Add',
				metrics: null,
				pageResult: null,
				protectService: new ProtectService(),
				businessRecordService: new BusinessRecordService(),
				currentBreakpoint: '',
				filter: null
			};
		},

		mounted() {
			this.getProtectMetrics();
			this.getProtectNumbers();
			this.getBusinessRecordList();
			this.updateBreakpoint();
		},

		beforeUnmount() {},

		methods: {
			handleRowClick: function (event, row) {
				this.numberToShow = row.item;
				this.showNumber = true;
			},

			updateBreakpoint() {
				this.currentBreakpoint = this.$vuetify.display.name;
			},

			setFilter: function(filter){
				if(this.filter === filter){
					this.filter = null;
				}else{
					this.filter = filter;
				}
			},

			async performSearch() {
				this.page = 1;
				await this.getProtectNumbers();
			},

			async getProtectMetrics() {
				let result = await this.protectService.getProtectMetrics(this.workspaceId);
				this.metrics = result.data;
			},

			async getBusinessRecordList() {
				let result = await this.businessRecordService.getBusinessRecordList(this.workspaceId);
				this.businessRecords.replace(result.data);
			},

			async getProtectNumbers() {
				this.$store.commit('startLoading');
				let result = await this.protectService.getProtectNumbers(
					this.workspaceId,
					this.searchFilter
				);
				this.pageResult = result.data;
				this.$store.commit('stopLoading');
			},

			downloadProtectNumbers: function () {
				this.$store.commit('startLoading');
				this.protectService
					.downloadProtectNumbers(this.workspaceId, this.searchFilter)
					.then((res) => {
						let fileURL = window.URL.createObjectURL(res.data);
						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute('download', `UC-Protect-Download.csv`);
						document.body.appendChild(fileLink);
						fileLink.click();
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			async registerProtectNumbers() {
				if (!this.businessRecordId) {
					this.$store.commit('error', 'Business record is required');
					return;
				} else if (!this.csvFile) {
					this.$store.commit('error', 'Please select a CSV file to upload.');
					return;
				}

				this.$store.commit('startLoading');
				let result = await this.protectService.registerNewNumbers(
					this.workspaceId,
					this.businessRecordId,
					this.csvFile
				);
				let success = result.data.successfulRecords;
				let failure = result.data.failureRecords;
				this.$store.commit(
					'success',
					`Protect file processed.  ${success} records successfully added.  ${failure} records failed to import.`
				);
				this.csvFile = null;
				await this.getProtectNumbers();
				await this.getProtectMetrics();
				this.$store.commit('stopLoading');
			},

			async deRegisterProtectNumbers() {
				if (!this.csvFile || this.csvFile.length === 0) {
					this.$store.commit('error', 'Please select a CSV file to upload.');
					return;
				}
				this.$store.commit('startLoading');
				await this.protectService.deRegisterNumbers(this.workspaceId, this.csvFile);
				this.csvFile = null;
				await this.getProtectNumbers();
				await this.getProtectMetrics();
				this.$store.commit('stopLoading');
			},
		},

		watch: {
			page: function () {
				this.getProtectNumbers();
			},
			'$vuetify.display.name': 'updateBreakpoint',
		},

		computed: {
			filteredNumbers: function(){
				let result = [... this.protectNumbers];

				if(this.filter){
					if(this.filter === 'spam'){
						return result.filter(r => r.spamResult?.riskLevel === 'HIGH');
					}else if(this.filter === 'cnam'){
						return result.filter(r => r.cnam.invalid);
					}else if(this.filter === 'ftc'){
						return result.filter(r => r.ftcComplaints);
					}
				}

				return result;
			},

			headers: function(){
				let result = [
					{ title: 'Number', key: 'number', align: 'start'},
					{ title: 'Description', key: 'description', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'Last scan', key: 'lastScanTime', align: 'start' },
					{ title: 'SPAM', key: 'spamResult.riskLevel', align: 'start', tooltip: 'SPAM Activity score'},
					{ title: 'Usage', key: 'validationResult.activity_score', align: 'start', tooltip: '0 = inactive; 100 = most active' },
					{ title: 'CNAM', key: 'cnam', align: 'start', tooltip: 'Current CNAM registration'},
					{ title: 'FTC', key: 'ftcComplaints', align: 'start', tooltip: 'FTC Complaint count' },
					{ title: 'Type', key: 'validationResult.line_type', align: 'start' },
					{ title: 'Carrier', key: 'validationResult.carrier', align: 'start' },
				];

				if(this.currentBreakpoint === 'lg' || this.currentBreakpoint === 'xl') {
					result.splice(8, 0, {
						title: 'CleanCall',
						key: 'spamResult.cleanCall.recommendation',
						align: 'start',
						tooltip: 'CleanCall status'
					})
					result.splice(8, 0, {
						title: 'IceHook',
						key: 'spamResult.icehookScout.risk_level',
						align: 'start',
						tooltip: 'IceHook rating; less than 60 is OK'
					})
					result.splice(8, 0, {
						title: 'NomoRobo',
						key: 'spamResult.nomoRobo.score',
						align: 'start',
						tooltip: 'NomoRobo score; 1 = spam'
					})
					result.splice(8, 0, {
						title: 'TrueSpam',
						key: 'spamResult.trueSpam.spam_score',
						align: 'start',
						tooltip: 'TrueSpam score; less than 50 is OK'
					})
				}

				return result;
			},

			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			protectNumbers: function () {
				if (this.pageResult) {
					return this.pageResult.content;
				} else {
					return [];
				}
			},
		},
	});
</script>

<style scoped lang="scss">
	.metric {
		text-align: left;
		border: 1px solid rgb(var(--v-theme-gray_30));
		background-color: rgb(var(--v-theme-gray_10));
		padding: 18px;
		border-radius: 4px;
		min-width: 200px;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		.label {
			font-weight: 500;
		}
		.value {
			font-size: 48px;
			font-weight: 500;
		}
		.error {
			color: red;
		}
		.success {
		}
	}

	//#protect-number-table :deep(.v-data-table-footer) {
	//	display: none;
	//}
</style>
