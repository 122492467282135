<template>
	<div :style="`max-width:calc(100vw - ${$store.state.leftNavExpanded ? '200px' : '50px'}); width: 100%; overflow-x: auto`" :key="refreshKey">
		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<module-header :name="header" :workspace-selector="true"> </module-header>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="row-format gap-2">
					<maz-phone-number-input
						:no-country-selector="true"
						:translations="{ phoneInput: { placeholder: 'To', example: 'To' } }"
						:only-countries="['US', 'CA']"
						size="sm"
						v-model="to"
					>
					</maz-phone-number-input>
					<maz-phone-number-input
						:no-country-selector="true"
						:translations="{ phoneInput: { placeholder: 'From', example: 'From' } }"
						:only-countries="['US', 'CA']"
						size="sm"
						v-model="from"
					>
					</maz-phone-number-input>
					<v-select
						id="workflow-select"
						:items="campaigns"
						v-model="campaignIds"
						style="max-width: 200px"
						item-value="id"
						item-title="name"
						label="Workflow"
						density="compact"
						variant="outlined"
						persistent-placeholder
						placeholder="All campaigns"
						hide-details
						:multiple="true"
					>
						<template v-slot:selection="{ item, index }">
							<div v-if="campaignIds.length === 1" class="truncate-column">{{ item.title }}</div>
							<div v-else-if="index === campaignIds.length - 1" class="truncate-column">
								{{ campaignIds.length }} campaigns
							</div>
						</template>
					</v-select>
					<v-btn class="super-action" style="min-height: 40px" @click="doSearch">Search</v-btn>
					<v-btn elevation="0" class="" style="min-height: 40px" @click="downloadUsage()"
						><p-icon :size="30">download</p-icon></v-btn
					>
				</v-col>
				<v-col cols="12" class="row-format gap-1" style="flex-wrap: wrap">
					<scope-button
						v-for="(dateOption, index) in dateOptions"
						:scope="dateOption.value"
						:active-scope="datePeriod"
						:label="dateOption.text"
						@scope-update="scopeUpdated"
						:key="index"
					></scope-button>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table-server
						id="search-table"
						:items="processUsage"
						:headers="headers"
						:fixed-header="true"
						:multi-sort="false"
						:must-sort="true"
						v-model:items-per-page="itemsPerPage"
						:items-length="totalItems"
						:loading="loading"
						v-model:page="page"
						v-model:sort-by="sortBy"
						@update:options="doSearch"
						:items-per-page-options="[25, 100, 250, 500]"
						style="max-height: calc(100vh - 250px)"
					>
						<template v-slot:[`item.timestamp`]="{ item }">
							<span style="white-space: nowrap">{{
								DateTime.fromISO(item.timestamp).toLocaleString(DateTime.DATETIME_SHORT)
							}}</span>
						</template>
					</v-data-table-server>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import FilterHelpers from '@/modules/utils/FilterHelpers';
	import ScopeButton from '@/components/ScopeButton';
	import ModuleHeader from '@/components/ModuleHeader';
	import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
	import { DateTime } from 'luxon';
	import CampaignService from '@/modules/campaigns/CampaignService';
	import PIcon from '@/components/PIcon';
	import UsageReportService from '@/modules/usage/UsageReportService';

	export default defineComponent({
		name: 'UsageReport',

		props: ['type'],

		components: { PIcon, ScopeButton, ModuleHeader, MazPhoneNumberInput },

		data: function () {
			return {
				DateTime: DateTime,
				usage: [],
				campaigns: [],
				campaignIds: [],
				to: null,
				from: null,
				datePeriod: 'today',
				reportData: null,
				usageReportService: new UsageReportService(),
				campaignService: new CampaignService(),
				dateOptions: FilterHelpers.getDateOptions([
					'today',
					'yesterday',
					'this-week',
					'last-week',
					'this-month',
					'last-month',
					'this-year',
					'last-year',
					'custom',
				]),

				page: 1,
				itemsPerPage: 25,
				sortBy: [{ key: 'timestamp', order: 'desc' }],
				totalItems: 0,
				loading: true,
				refreshKey: 0,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.initialize);
			this.initialize();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.initialize);
		},

		methods: {
			initialize: function(){
				this.usage.splice(0);
				this.getCampaigns();
				this.refreshKey++;
			},

			getCampaigns: function () {
				new CampaignService().getCampaigns(this.workspaceId).then((res) => {
					this.campaigns.splice(0,this.campaigns.length);
					res.data.forEach((d) => {
						this.campaigns.push({ id: d.id, name: d.name });
					});
				});
			},

			scopeUpdated: function (scope) {
				this.datePeriod = scope;
				this.doSearch();
			},

			getSearchObject() {
				return {
					earliest: this.earliest,
					latest: this.latest,
					campaignId: this.campaignIds,
					to: this.to,
					from: this.from,
					page: this.page - 1,
					size: this.itemsPerPage,
					sortBy: this.sortBy[0].key,
					sortOrder: this.sortBy[0].order,
				};
			},

			async doSearch() {
				try {
					this.$store.commit('startLoading');
					let search = this.getSearchObject();
					let result = await this.usageReportService.getUsageData(this.workspaceId, this.type, search);
					this.usage.replace(result.data.content);
					this.totalItems = result.data.totalElements;
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
					this.loading = false;
				}
			},

			async downloadUsage() {
				try {
					this.$store.commit('startLoading');
					await this.usageReportService.downloadUsage(this.workspaceId, this.type, this.getSearchObject());
					this.$store.commit(
						'success',
						'Your request is processing.  You will be notified when the file is ready to download.'
					);
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},
		},

		watch: {
			type: function(){
				this.initialize();
			}
		},

		computed: {
			header: function(){
				return this.type === 'voice' ? 'Inbound calls' : 'SMS messages';
			},

			processUsage: function () {
				let result = [...this.usage];
				const campaignMap = new Map(this.campaigns.map((item) => [item.id, item.name]));

				result.forEach((r) => {
					r.campaignName = campaignMap.get(r.campaignId);
				});

				return result;
			},

			headers: function () {
				if(this.type === 'voice'){
					return [
						{ title: 'Time', key: 'timestamp', align: 'start' },
						{ title: 'From', key: 'caller', align: 'start' },
						{ title: 'To', key: 'called', align: 'start' },
						{ title: 'Destination', key: 'forwardedTo', align: 'start' },
						{ title: 'Campaign', key: 'campaignName', align: 'start' },
						{ title: 'Status', key: 'callStatus', align: 'start' },
						{ title: 'Duration', key: 'billableDuration', align: 'start' }
					];
				}else{
					return [
						{ title: 'Time', key: 'timestamp', align: 'start' },
						{ title: 'Status', key: 'status', align: 'start' },
						{ title: 'From', key: 'from', align: 'start' },
						{ title: 'To', key: 'to', align: 'start' },
						{ title: 'Campaign', key: 'campaignName', align: 'start' },
						{ title: 'Message', key: 'message', align: 'start' },
						{ title: 'Segments', key: 'segments', align: 'start' }
					];
				}

			},

			earliest: function () {
				return FilterHelpers.getEarliestAndLatest(this.datePeriod).earliest;
			},

			latest: function () {
				return FilterHelpers.getEarliestAndLatest(this.datePeriod).latest;
			},

			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	.metric {
		text-align: left;
		border: 1px solid rgb(var(--v-theme-gray_30));
		background-color: rgb(var(--v-theme-gray_10));
		padding: 18px;
		border-radius: 4px;
		min-width: 200px;
		flex: 1 1 auto;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		.label {
			font-weight: 500;
		}
		.value {
			font-size: 48px;
			font-weight: 500;
		}
		.error {
			color: red;
		}
		.success {
			color: green;
		}
	}

	.truncate-column {
		max-width: 150px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	//#search-table :deep(.v-data-table-footer) {
	//	display: none;
	//}
</style>
