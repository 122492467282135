<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header
						name="Record sets"
						add-label="Create record set"
						@add-new="addRecordSet()"
						:workspace-selector="true"
				>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
						id="record-set-table"
						:items-per-page="-1"
						:items="recordSets"
						:headers="headers"
						class="pointer"
						fixed-header
						height="calc(100vh - 150px)"
						@click:row="editRecordSet"
				>
					<template v-slot:[`item.ttl`]="{ item }">
						<span v-if="item.ttlEnabled">{{item.ttl.duration}} days</span>
						<span v-else>Disabled</span>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import RecordSetService from '@/modules/recordSets/RecordSetService';
	import ModuleHeader from "@/components/ModuleHeader";
	import EditRecordSet from "@/modules/recordSets/EditRecordSet";

	export default defineComponent({
		name: 'RecordSetList',

		props: [],

		components: {ModuleHeader},

		data: function () {
			return {
				recordSetService: new RecordSetService(),
				recordSets: [],
				headers: [
					{ title: 'Name', key: 'name', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'TTL', key: 'ttl', align: 'start' }
				]
			};
		},

		mounted() {
			this.getRecordSets();
			this.$store.state.eventBus.$on('workspace-changed', this.getRecordSets);
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.getRecordSets);
		},

		methods: {
			async getRecordSets() {
				let result = await this.recordSetService.getRecordSets(this.workspaceId);
				this.recordSets.replace(result.data.filter(r => r.status !== 'DELETED'));
			},

			addRecordSet: function(){
				this.$store.state.globalModalController.openModal(EditRecordSet,{}).then(() => this.getRecordSets());
			},

			editRecordSet: function (event, row) {
				this.$store.state.globalModalController.openModal(EditRecordSet,{id:row.item.id}).then(() => this.getRecordSets());
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#record-set-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
