<template>
	<div class="column-format gap-3">
		<v-text-field v-model="item.label" variant="outlined" density="compact" hide-details persistent-placeholder label="Label" @change="updated()"></v-text-field>
		<v-text-field v-model="item.reasonMessage" variant="outlined" density="compact" hide-details persistent-placeholder label="Reject reason (optional)" @change="updated()"></v-text-field>
	</div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'Reject',

	props: ['node'],

	components: {},

	data: function () {
		return {
			item: {...this.node}
		};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {
		updated: function(){
			this.$emit('updated',this.item);
		}
	},

	computed: {},
});
</script>

<style scoped lang="scss"></style>
