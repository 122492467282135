<template>
	<div>
		<v-data-table id="business-record-table" :items="businessRecords" :headers="headers" :items-per-page="-1" @click:row="rowClick">
			<template v-slot:[`item.cnams`]="{ item }">
				{{item.cnams.map(c => c.name).join(", ")}}
			</template>
			<template v-slot:[`item.contact`]="{ item }">
				{{item.contact.firstName}} {{item.contact.lastName}}
			</template>
			<template v-slot:[`item.dateCreated`]="{ item }">
				{{DateTime.fromISO(item.dateCreated).toLocaleString(DateTime.DATE_MED)}}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import BusinessRecordService from "@/modules/watchTower/BusinessRecordService";
	import BusinessRecordDetail from "@/modules/watchTower/BusinessRecordDetail";
	import {DateTime} from 'luxon';

	export default defineComponent({
		name: 'BusinessRecordList',

		props: [],

		components: {},

		data: function () {
			return {
				DateTime: DateTime,
				businessRecordService: new BusinessRecordService(),
				businessRecords: [],
				headers: [
					{ title: 'Name', key: 'legalName', align: 'start' },
					{ title: 'Status', key: 'resourceStatus', align: 'start' },
					{ title: 'CNAM', key: 'cnams', align: 'start' },
					{ title: 'Contact', key: 'contact', align: 'start' },
					{ title: 'Created', key: 'dateCreated', align: 'start' }
				]
			};
		},

		mounted() {
			this.getBusinessRecordList();
		},

		beforeUnmount() {},

		methods: {
			async addNew(){
				await this.editBusinessRecord();
			},

			async rowClick(event,row){
				await this.editBusinessRecord(row.item.id);
			},

			async editBusinessRecord(id = null){
				let binding = {
					id:id
				}

				this.$store.state.globalModalController.openModal(BusinessRecordDetail,binding).then((businessRecord) => {
					if(!businessRecord){
						return;
					}

					let ix = this.businessRecords.findIndex(f => f.id === businessRecord.id);

					if(businessRecord.deleted && ix > -1){
						this.businessRecords.splice(ix,1);
					}else if(ix > -1){
						this.businessRecords.splice(ix,1, businessRecord)
					}else{
						this.businessRecords.push(businessRecord);
					}
				})
			},

			async getBusinessRecordList(){
				let result = await this.businessRecordService.getBusinessRecordList(this.workspaceId);
				this.businessRecords.replace(result.data);
			}
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#business-record-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
