<template>
	<v-container fluid v-if="campaign">
		<v-row>
			<v-col cols="12">
				<module-header
					:workspace-selector="true"
					:disable-workspace-selector="true"
					:name="campaign.name"
					back-button="/campaigns"
				></module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<div class="row-format" style="flex-wrap: wrap">
					<div
							:class="`page-link ${currentTab === page.value ? 'page-link-active' : ''}`"
							v-for="page in pages"
							:key="page.value"
							@click.stop="setView(page.value,$event)"
					>
						<div>{{ page.label }}</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<div style="height: calc(100vh - 210px); max-height: calc(100vh - 210px); overflow-y: auto; width: 100%" class="px-2">
					<campaign-overview ref="Overview" :workspace-id="workspaceId" :campaign="campaign" :campaign-stats="campaignStats" v-if="currentTab === 'Overview'" @save="save($event)" @delete="confirmDelete()"></campaign-overview>
					<campaign-data-boost ref="DataBoost" :workspace-id="workspaceId" :campaign="campaign" v-if="currentTab === 'DataBoost'" @save="saveDataBoost($event)"></campaign-data-boost>
					<campaign-watchtower ref="Watchtower" :workspace-id="workspaceId" :campaign="campaign" v-if="currentTab === 'Watchtower'" @save="saveWatchtower($event)"></campaign-watchtower>
					<campaign-messaging ref="Messaging" :workspace-id="workspaceId" :campaign="campaign" v-if="currentTab === 'Messaging'" @save="saveMessagingConfig($event)" @a2p-campaign="saveA2PCampaign($event)"></campaign-messaging>
					<campaign-lists ref="Lists" :workspace-id="workspaceId" :campaign="campaign" v-if="currentTab === 'Lists'"></campaign-lists>
					<campaign-pool ref="Pool" :workspace-id="workspaceId"  :campaign="campaign" v-if="currentTab === 'Pool'"></campaign-pool>
					<campaign-leads ref="Leads" :workspace-id="workspaceId"  :campaign="campaign" v-if="currentTab === 'Leads'"></campaign-leads>
					<campaign-events ref="Events" :workspace-id="workspaceId"  :campaign="campaign" v-if="currentTab === 'Events'"></campaign-events>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import CampaignService from '@/modules/campaigns/CampaignService';
	import PDateTime from '@/modules/utils/PDateTime';
	import ConfirmModal from '@/components/ConfirmModal';
	import CampaignLists from '@/modules/campaigns/detail/CampaignLists';
	import CampaignPool from '@/modules/campaigns/detail/CampaignPool';
	import CampaignLeads from '@/modules/campaigns/detail/CampaignLeads';
	import CampaignOverview from '@/modules/campaigns/detail/CampaignOverview';
	import ModuleHeader from '@/components/ModuleHeader';
	import CampaignEvents from "@/modules/campaigns/detail/CampaignEvents";
	import CampaignDataBoost from "@/modules/campaigns/detail/CampaignDataBoost";
	import CampaignMessaging from "@/modules/campaigns/detail/CampaignMessaging";
	import CampaignWatchtower from "@/modules/campaigns/detail/CampaignWatchtower";

	export default defineComponent({
		name: 'CampaignEdit',
		isRightModal: true,

		props: ['id'],

		components: {
			CampaignWatchtower,
			CampaignMessaging,
			CampaignDataBoost,
			CampaignEvents, ModuleHeader, CampaignOverview, CampaignLeads, CampaignPool, CampaignLists },

		data: function () {
			return {
				DateTime:PDateTime,
				currentTab: 'Overview',
				isValid: true,
				campaign: null,
				campaignStats: {
					listStats: [],
					sentryStats: [],
					smsStats: [],
				},
				dialerCampaign: null,
				campaignService: new CampaignService(),
				identities: [],
				pages: [
					{ label: 'Overview', value: 'Overview' },
					{ label: 'DataBoost', value: 'DataBoost' },
					{ label: 'Watchtower', value: 'Watchtower' },
					{ label: 'Messaging', value: 'Messaging' },
					{ label: 'Lists', value: 'Lists' },
					{ label: 'Pool', value: 'Pool' },
					{ label: 'Leads', value: 'Leads' },
					{ label: 'Event log', value: 'Events' },
				]
			};
		},

		mounted() {
			if(this.workspaceId) {
				this.getCampaign();
				this.getCampaignStats();
			}
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off(this.getChannelName(), this.handleCampaignEvents);
		},

		methods: {
			setView: function(view) {
				this.currentTab = view;
			},

			handleCampaignEvents: function (event) {
				if (event.userMetadata === 'CampaignList' && this.currentTab === 'Lists') {
					this.$refs.Lists.listUpdated(event.message);
				}else if(event.userMetadata === 'MessagingList' && this.currentTab === 'Lists'){
					this.$refs.Lists.messagingListUpdated(event.message);
				} else if (event.userMetadata === 'Campaign') {
					this.campaign = event.message;
				}
			},

			async getCampaignStats(){
				try{
					let result = await this.campaignService.getCampaignStats(this.workspaceId,this.id);
					console.log(result.data.sentryStats);

					let listStats = result.data.listStats;
					let sentryStats = result.data.sentryStats;
					let smsStats = result.data.smsStats;

					if(listStats.length){
						let result = this.fillOutStats(listStats,{
							date: null,
							total:0,
							inactive:0,
							invalid:0,
							valid:0
						})
						this.campaignStats.listStats.replace(result);
					}

					if(smsStats.length){
						let result = this.fillOutStats(smsStats,{
							date: null,
							sent:0,
							delivered:0,
							failed:0
						})
						this.campaignStats.smsStats.replace(result);
					}

					if(sentryStats.length){
						let result = this.fillOutStats(sentryStats,{
							date: null,
							replaced:0
						})
						this.campaignStats.sentryStats.replace(result);
						console.log(result);
					}
				}catch(err){
					console.log(err);
				}
			},

			fillOutStats(statsArray,defaultObject){
				let start = this.DateTime.fromISO(statsArray[0].date).startOf('day');
				let end = this.DateTime.fromISO(statsArray[statsArray.length-1].date).startOf('day');
				let result = [];

				while(start <= end){
					let date = start.toISODate();
					let stats = statsArray.find(s => s.date === date);
					if(stats){
						stats.date = start.toLocaleString({month:'short',day:'numeric'})
						result.push(stats);
					}else{
						let empty = JSON.parse(JSON.stringify(defaultObject));
						empty.date = start.toLocaleString({month:'short',day:'numeric'})
						result.push(empty);
					}
					start = start.plus({days:1});
				}

				return result;
			},

			async getCampaign() {
				try {
					let result = await this.campaignService.getCampaign(this.workspaceId, this.id);
					this.campaign = result.data;
					this.$store.state.eventBus.$on(this.getChannelName(), this.handleCampaignEvents);
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				}
			},

			async save(campaign) {
				try {
					this.$store.commit('startLoading');
					let result = await this.campaignService.updateCampaign(this.workspaceId, campaign.id, campaign);
					this.campaign = result.data;
					this.$store.commit('success','Campaign settings successfully saved');
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},

			async saveDataBoost(update){
				this.campaign.features = update.features;
				this.campaign.validationDataMapping = update.validationDataMapping;
				await this.save(this.campaign);
			},

			async saveWatchtower(update){
				this.campaign.features = update.features;
				await this.save(this.campaign);
			},

			async saveMessagingConfig(messagingConfig){
				this.campaign.messagingConfig = messagingConfig;
				await this.save(this.campaign);
			},

			async saveA2PCampaign(a2p){
				this.campaign.appToPersonCampaignId = a2p.appToPersonCampaignId;
				this.campaign.appToPersonCampaignName = a2p.appToPersonCampaignName;
				await this.save(this.campaign);
			},

			confirmDelete() {
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Please confirm campaign deactivation.  The numbers in the pool will be immediately disconnected. Type the campaign name (${this.campaign.name}) to confirm the action.`,
					confirmText: this.campaign.name,
					confirmHint: `Enter: "${this.campaign.name}" in the field above`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.campaignService.deactivateCampaign(this.workspaceId, this.campaign.id).then(() => {
							this.$router.push('/campaigns');
						});
					}
				});
			},

			getChannelName: function () {
				if(this.campaign) {
					return `o-${this.campaign.orgId}.w-${this.campaign.workspaceId}.c-${this.campaign.id}`;
				}else{
					return null;
				}
			},
		},

		watch: {
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#campaign-edit {
		.tab {
			background-color: rgb(var(--v-theme-gray_20));
			border: 1px solid rgb(var(--v-theme-gray_20));
			color: rgb(var(--v-theme-gray_70));
			border-radius: 18px;
			text-align: center;
			padding: 2px 12px;
			cursor: pointer;

			&:hover {
				background-color: rgb(var(--v-theme-gray_30));
				border: 1px solid rgb(var(--v-theme-gray_70));
				color: rgb(var(--v-theme-secondary));
			}
		}

		.tab.active {
			background-color: rgb(var(--v-theme-gray_30));
			border: 1px solid rgb(var(--v-theme-gray_70));
			color: rgb(var(--v-theme-secondary));
		}

		.event-box {
			border-top: 1px solid rgb(var(--v-theme-gray_50));
		}
	}
</style>
