import HttpClient from "@/services/HttpClient";

export default class ActiveProspectService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getConnectionList(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/activeProspect`);
    }

    async getConnection(workspaceId,id) {
        return await this.client.get(`/workspaces/${workspaceId}/activeProspect/${id}`);
    }

   async createConnection(workspaceId,companyName,apiKey) {
       const params = new URLSearchParams({
           companyName: companyName,
           apiKey: apiKey
       });
        return await this.client.post(`/workspaces/${workspaceId}/activeProspect?${params.toString()}`);
    }

    async updateConnection(workspaceId,id,companyName,apiKey) {
        const params = new URLSearchParams({
            companyName: companyName,
            apiKey: apiKey
        });
        return await this.client.put(`/workspaces/${workspaceId}/activeProspect/${id}?${params.toString()}`);
    }

    async deleteConnection(workspaceId,id) {
        return await this.client.delete(`/workspaces/${workspaceId}/activeProspect/${id}`);
    }

}