<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header name="Aegis call dashboard" :workspace-selector="true"> </module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="row-format gap-1">
				<v-select
						id="workflow-select"
						:items="workflows"
						v-model="workflowIds"
						style="max-width: 200px"
						item-value="id"
						item-title="name"
						density="compact"
						variant="outlined"
						placeholder="All workflows"
						hide-details
						:multiple="true"
						@update:modelValue="getDashboardData"
				>
					<template v-slot:selection="{ item, index }">
						<div v-if="workflowIds.length === 1" class="truncate-column">{{ item.title }}</div>
						<div v-else-if="index === workflowIds.length - 1" class="truncate-column">
							{{ workflowIds.length }} workflows
						</div>
					</template>
				</v-select>
				<scope-button
					v-for="(dateOption, index) in dateOptions"
					:scope="dateOption.value"
					:active-scope="datePeriod"
					:label="dateOption.text"
					@scope-update="scopeUpdated"
					:key="index"
				></scope-button>
			</v-col>
		</v-row>
		<v-row cols="12" class="row-format gap-4 px-4" style="flex-wrap: wrap">
			<div class="metric">
				<div class="label">Calls presented</div>
				<div class="value">{{ totalCalls.toLocaleString('en-US', {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				}) }}</div>
			</div>
			<div class="metric">
				<div class="label">Calls accepted</div>
				<div class="value success">{{ totalAccepted.toLocaleString('en-US', {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				}) }}</div>
			</div>
			<div class="metric">
				<div class="label">Calls rejected</div>
				<div class="value error">{{ totalRejected.toLocaleString('en-US', {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				}) }}</div>
			</div>
		</v-row>
		<v-row v-if="dashboardData" class="column-format gap-4 px-4 pt-4">
			<metric-block label="Top Outcomes" :counts="dashboardData.reasonCounts" key-field="reason"></metric-block>
			<metric-block label="Calls by state" :counts="dashboardData.stateCounts" key-field="state"></metric-block>
			<metric-block label="Calls by carrier" :counts="dashboardData.carrierCounts" key-field="carrier"></metric-block>
			<metric-block label="Calls by line type" :counts="dashboardData.lineTypeCounts" key-field="lineType"></metric-block>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import DashboardService from '@/modules/aegis/DashboardService';
	import FilterHelpers from '@/modules/utils/FilterHelpers';
	import ScopeButton from '@/components/ScopeButton';
	import ModuleHeader from '@/components/ModuleHeader';
	import WorkflowService from '@/modules/workflow/WorkflowService';
	import MetricBlock from "@/modules/aegis/MetricBlock";

	export default defineComponent({
		name: 'Dashboard',

		props: [],

		components: {MetricBlock, ScopeButton, ModuleHeader },

		data: function () {
			return {
				autoRefreshId: null,
				datePeriod: 'today',
				workflows: [],
				workflowIds: [],
				dashboardData: null,
				dashboardService: new DashboardService(),
				dateOptions: FilterHelpers.getDateOptions([
					'today',
					'yesterday',
					'this-week',
					'last-week',
					'this-month',
					'last-month',
					'this-year',
					'last-year',
				]),
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.initialize);
			this.initialize();
			this.autoRefreshId = setInterval(() => this.doAutoRefresh(),30*1000);
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.initialize);
			clearInterval(this.autoRefreshId);
		},

		methods: {
			initialize: function(){
				this.getWorkflows();
				this.getDashboardData();
			},

			getWorkflows: function () {
				new WorkflowService().getWorkflows(this.workspaceId).then((res) => {
					this.workflows.splice(0);
					res.data.forEach((d) => {
						this.workflows.push({ id: d.id, name: d.name });
					});
				});
			},

			scopeUpdated: function (scope) {
				this.datePeriod = scope;
				this.getDashboardData();
			},

			doAutoRefresh: function(){
				if(this.datePeriod === 'today'){
					this.getDashboardData(true);
				}
			},

			getDashboardData: function (autoRequest = false) {
				let searchRequest = {
					earliest: this.earliest,
					latest: this.latest,
					workflowId: this.workflowIds,
				};

				if(!autoRequest)this.$store.commit('startLoading');

				this.dashboardService
					.getDashboardMetrics(this.workspaceId, searchRequest)
					.then((res) => {
						this.dashboardData = res.data;
						this.dashboardData.stateCounts.sort((a, b) => b.count - a.count);
						this.dashboardData.carrierCounts.sort((a, b) => b.count - a.count);
						this.dashboardData.lineTypeCounts.sort((a, b) => b.count - a.count);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {
			totalCalls: function () {
				if (this.dashboardData) {
					return this.dashboardData.metrics.reduce((accumulator, currentObject) => {
						return accumulator + (currentObject.count ? currentObject.count : 0);
					}, 0);
				} else {
					return 0;
				}
			},

			totalAccepted: function () {
				if (this.dashboardData) {
					return this.dashboardData.metrics.reduce((accumulator, currentObject) => {
						return accumulator + (currentObject.accepted ? currentObject.accepted : 0);
					}, 0);
				} else {
					return 0;
				}
			},

			totalRejected: function () {
				if (this.dashboardData) {
					return this.dashboardData.metrics.reduce((accumulator, currentObject) => {
						return accumulator + (currentObject.rejected ? currentObject.rejected : 0);
					}, 0);
				} else {
					return 0;
				}
			},

			earliest: function () {
				return FilterHelpers.getEarliestAndLatest(this.datePeriod).earliest;
			},

			latest: function () {
				return FilterHelpers.getEarliestAndLatest(this.datePeriod).latest;
			},

			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	.metric {
		text-align: left;
		border: 1px solid rgb(var(--v-theme-gray_30));
		background-color: rgb(var(--v-theme-gray_10));
		padding: 18px;
		border-radius: 4px;
		min-width: 200px;
		flex: 1 1 auto;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		.label {
			font-weight: 500;
		}
		.value {
			font-size: 48px;
			font-weight: 500;
		}
		.error {
			color: red;
		}
		.success {
			color: green;
		}
	}

	.truncate-column {
		max-width: 150px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
