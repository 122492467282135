<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="row-format">
				<module-header name="Downloads" :workspace-selector="true"></module-header>
			</v-col>
		</v-row>
		<v-row style="max-height: calc(100vh - 130px); overflow-y: auto">
			<v-col cols="12">
				<v-data-table
					id="download-table"
					:items="downloads"
					:items-per-page="-1"
					:headers="headers"
					class="pointer"
					:sort-by="[{ key: 'requestedAt', order: 'desc' }]"
					fixed-header
				>
					<template v-slot:[`item.requestedBy`]="{ item }">
						{{ item.requestedBy?.firstName }} {{ item.requestedBy?.lastName }}
					</template>
					<template v-slot:[`item.requestedAt`]="{ item }">
						<span style="white-space: nowrap" v-if="item.requestedAt">{{
							DateTime.fromISO(item.requestedAt).toLocaleString(DateTime.DATETIME_SHORT)
						}}</span>
						<span v-else>--</span>
					</template>
					<template v-slot:[`item.expiresAt`]="{ item }">
						<span style="white-space: nowrap" v-if="item.expiresAt">{{
							DateTime.fromISO(item.expiresAt).toLocaleString(DateTime.DATETIME_SHORT)
						}}</span>
						<span v-else>--</span>
					</template>
					<template v-slot:[`item.action`]="{ item }">
						<div class="row-format gap-2">
							<div
								class="material-symbols-outlined primary"
								v-tippy
								content="Download file"
								@click="download(item)"
							>
								download
							</div>
							<div
								class="material-symbols-outlined gray_70"
								v-tippy
								content="Delete request"
								@click="confirmDelete(item)"
							>
								delete
							</div>
						</div>
					</template>
					<template v-slot:no-data>No reports ready to download</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import DownloadService from '@/modules/downloads/DownloadService';
	import { DateTime } from 'luxon';
	import ConfirmModal from '@/components/ConfirmModal';

	export default defineComponent({
		name: 'DownloadReports',

		props: [],

		components: { ModuleHeader },

		data: function () {
			return {
				DateTime: DateTime,
				downloadService: new DownloadService(),
				downloads: [],

				headers: [
					{ title: 'Report name', key: 'reportName' },
					{ title: 'Status', key: 'status' },
					{ title: 'Requested by', key: 'requestedBy' },
					{ title: 'Requested at', key: 'requestedAt' },
					{ title: 'Available until', key: 'expiresAt' },
					{ title: '', key: 'action' },
				],
			};
		},

		mounted() {
			this.getDownloads();
			this.$store.state.eventBus.$on(`o-${this.orgId}.w-${this.workspaceId}.report-download`,this.handleUpdate);
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off(`o-${this.orgId}.w-${this.workspaceId}.report-download`,this.handleUpdate);
		},

		methods: {
			handleUpdate: function(event){
				let download = event.message;
				let ix = this.downloads.findIndex(c => c.id === download.id);
				if(ix > -1){
					if(download.status === 'DELETED') {
						this.downloads.splice(ix, 1);
					}else{
						this.downloads.splice(ix, 1, download);
					}
				}else{
					this.downloads.push(download);
				}
			},

			async getDownloads() {
				let result = await this.downloadService.getDownloads(this.workspaceId);
				this.downloads.replace(result.data);
			},

			async download(item) {
				let result = await this.downloadService.getDownloadUrl(this.workspaceId, item.id);
				await this.downloadS3File(result.data,item.reportName);
			},

			async downloadS3File(signedUrl) {
				window.open(signedUrl,'_blank');
			},

			async confirmDelete(item) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this report file?',
				};
				let res = await this.$store.state.globalModalController.openModal(ConfirmModal, binding);
				if (res) {
					await this.downloadService.deleteDownload(this.workspaceId, item.id);
					let ix = this.downloads.findIndex((d) => d.id === item.id);
					if (ix > -1) {
						this.downloads.splice(ix, 1);
					}
				}
			},
		},

		watch: {},

		computed: {
			orgId: function(){
				return this.$store.getters.getOrgId;
			},
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#download-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
