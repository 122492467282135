import CampaignList from "@/modules/campaigns/CampaignList";
import BulkUploader from "@/modules/campaigns/BulkUploader";
import CampaignDetails from "@/modules/campaigns/detail/CampaignDetails";
import ListOverview from "@/modules/campaigns/ListOverview";

export default [
    {
        path: '/campaigns',
        name: 'campaigns',
        component: CampaignList,
        title: 'Campaigns',
        props: true
    },
    {
        path: '/campaigns/:id',
        name: 'campaignEdit',
        component: CampaignDetails,
        title: 'Campaign Edit',
        props: (route) => ({
            id: route.params.id,
        })
    },
    {
        path: '/bulk',
        name: 'bulk',
        component: BulkUploader,
        title: 'Bulk uploader',
        props: true
    },
    {
        path: '/lists',
        name: 'lists',
        component: ListOverview,
        title: 'List overview',
        props: true
    },
]