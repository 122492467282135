import WorkspaceList from "@/modules/workspaces/WorkspaceList";
import NiceOAuth from "@/modules/dialers/nice/NiceOAuth";

export default [
    {
        path: '/workspaces',
        name: 'workspaces',
        component: WorkspaceList,
        title: 'Workspaces',
        props: route => ({
            openWorkspace: route.query.openWorkspace
        })
    },

    {
        path: '/oauth/cxone',
        name: 'NiceOAuth',
        component: NiceOAuth,
        title: 'NiceOAuth',
        props: route => ({
            code: route.query.code
        })
    }
]