<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header
					name="Business Identities"
					add-label="Request identity"
					@add-new="openSupport()"
					:workspace-selector="true"
				>
					<template v-slot:filter>
						<v-text-field v-model="filter" density="compact" hide-details placeholder="Filter..." variant="outlined" style="min-width: 150px"></v-text-field>
					</template>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					id="identity-table"
					:items="filteredIdentities"
					v-model:items-per-page="itemsPerPage"
					:headers="headers"
					fixed-header
					height="calc(100vh - 150px)"
					:style="`max-width: calc(${width} - 40px)`"
					class="pointer"
				>

					<template v-slot:[`item.dateCreated`]="{ item }">{{ DateTime.fromISO(item.dateCreated).toLocaleString(DateTime.DATE_FULL) }}</template>
					<template v-slot:[`item.dateUpdated`]="{ item }">{{ DateTime.fromISO(item.dateUpdated).toLocaleString(DateTime.DATE_FULL) }}</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import IdentityService from "@/modules/identities/IdentityService";
	import PDateTime from "@/modules/utils/PDateTime";
	import { useDisplay } from 'vuetify';

	export default defineComponent({
		name: 'IdentityList',

		props: [],

		components: { ModuleHeader },

		data: function () {
			return {
				DateTime: PDateTime,
				identities: [],
				identityService: new IdentityService(),
				itemsPerPage: -1,
				filter: null,
				headers: [
					{ title: 'Name', key: 'name', align: 'start' },
					{ title: 'Status', key: 'resourceStatus', align: 'start' },
					{ title: 'Updated', key: 'dateUpdated', align: 'start' },
					{ title: 'Created', key: 'dateCreated', align: 'start' }
				],
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.getIdentities);
			this.getIdentities();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.getIdentities);
		},

		methods: {
			async getIdentities() {
				let result = await this.identityService.getIdentities(this.workspaceId);
				this.identities.replace(result.data);
			},

			openSupport: function () {
				window.open('https://support.purecallerid.com/hc/en-us/requests/new?ticket_form_id=16067641052187','_blank');
			},
		},

		computed: {
			width: () => {
				return `${useDisplay().width.value}px`;
			},
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			filteredIdentities: function(){
				if(this.filter){
					let result = [... this.identities];
					return result.filter(i => i.name.toLowerCase().includes(this.filter.toLowerCase()));
				}else{
					return this.identities;
				}
			}
		},
	});
</script>

<style scoped lang="scss">
	#identity-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
