import HttpClient from "@/services/HttpClient";

export default class FtcSanService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getFtcSanList(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/ftcSans`);
    }

    async getFtcSan(workspaceId,id) {
        return await this.client.get(`/workspaces/${workspaceId}/ftcSans/${id}`);
    }

   async createFtcSan(workspaceId,ftcSan) {
        return await this.client.post(`/workspaces/${workspaceId}/ftcSans`,ftcSan);
    }

    async updateFtcSan(workspaceId,id,ftcSan) {
        return await this.client.put(`/workspaces/${workspaceId}/ftcSans/${id}`,ftcSan);
    }

    async deleteFtcSan(workspaceId,id) {
        return await this.client.delete(`/workspaces/${workspaceId}/ftcSans/${id}`);
    }

}