<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="pb-8">
				<module-header
						name="Number Utility"
						:workspace-selector="false"
				>
				</module-header>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12">
					<v-select
							ref="type"
							:items="['CNAM', 'SPAM']"
							label="Type of search"
							v-model="filter"
							item-value="filter"
							item-title="name"
							density="compact"
							variant="outlined"
							hide-details
							onchange="result = []"
							persistent-placeholder
					></v-select>
			</v-col>
			<v-col cols="12">
					<maz-phone-number-input
							:translations="{ phoneInput: { placeholder: 'Phone', example: '' } }"
							:only-countries="['US','CA']"
							@update="handlePhoneInput" size="sm"
							v-model="phone"
					>
					</maz-phone-number-input>
			</v-col>
			<v-col cols="12">
				<v-btn block color="secondary" variant="flat" @click="getData">Search</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
						id="utility-table"
						:items="result"
						:headers="filter === 'CNAM' ? headersCnam : headersSpam"
						:hide-default-footer="true"
				>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import UtilityService from '@/modules/utilities/UtilityService';
	import PDateTime from '@/modules/utils/PDateTime';
	import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
	import ModuleHeader from "@/components/ModuleHeader";

	export default defineComponent({
		name: 'CnamSpam',

		props: [],

		components: { MazPhoneNumberInput, ModuleHeader },

		data: function () {
			return {
				DateTime: PDateTime,
				filter: 'CNAM',
				phone: null,
				result: [],
				utilityService: new UtilityService(),
				headersCnam: [
					{ title: 'Name', key: 'name', align: 'start' },
					{ title: 'Type', key: 'type', align: 'start' },
				],
				headersSpam: [
					{ title: 'Score', key: 'spamScore', align: 'start' },
					{ title: 'Risk', key: 'riskLevel', align: 'start' },
				],
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			handlePhoneInput: function (event) {
				this.phoneValid = event.isValid;
			},
			async getData() {
				this.$store.commit('startLoading');
				let result =
					this.filter === 'CNAM'
						? await this.utilityService.getCnam(this.phone)
						: await this.utilityService.getSpam(this.phone);
				this.result = [result.data];
				this.$store.commit('stopLoading');
			},
		},
	});
</script>

<style scoped lang="scss">
	#utility-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
