<template>
	<notification-layout :id="id" action-text="" :sent-at="sentAt" @action="tryNavigation()">
		<template v-slot:header>
			<p-icon color="primary" small class="mr-1">campaign</p-icon>
			<div>{{ notificationPayload.heading }}</div>
		</template>

		<template v-slot:content>
			<div class="font-12">
				<span> {{ notificationPayload.message }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>

	import NotificationLayout from "@/modules/notifications/NotificationLayout";

	export default {
		name: 'GenericNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { NotificationLayout },

		data: function() {
			return {};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			tryNavigation: function() {
				this.$store.commit('setSelectedWorkspace',this.notificationPayload.workspaceId);
				this.$router.push(`/campaigns/${this.notificationPayload.campaignId}`);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
</style>
