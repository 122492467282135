import HttpClient from '@/services/HttpClient';

export default class ProfileService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getProfile() {
		return this.client.get('/profile');

	}

	async updateProfile(user, file) {
        let formData = new FormData();
		formData.append('file', file);
		formData.append('user',JSON.stringify(user));
		return this.client.put(`/profile`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}
}
