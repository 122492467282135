<template>
	<div>
		<v-data-table :headers="headers" :items="dataPreview" :items-per-page="3" class="elevation-1">
			<template v-slot:headers>
				<tr>
					<th v-for="(header, index) in headersComputed" :key="header.value">
						<h4>
							<v-menu v-model="header.menuOpen" :close-on-content-click="true">
								<template v-slot:activator="{ props }">
									<div class="header-container row-format align-center" v-tippy="{ content: header.title }">
										<div
											:class="`pointer header-selector d-inline-block text-truncate ${
												header.mapped ? 'text-primary' : ''
											}`"
											v-bind="props"
										>
											{{ header.mapped ? header.columnId : header.title }}
										</div>
										<v-icon
											size="20"
											color="primary"
											class="pointer"
											v-if="header.mapped"
											@click.stop="deSelectField(index)"
											>clear</v-icon
										>
									</div>
								</template>
								<div
									class="column-format more-menu"
									style="max-height: calc(100vh - 100px); min-width: 200px; overflow-y: auto"
								>
									<div
										v-for="field in dialerFieldsFiltered"
										:key="field"
										class="more-menu-item"
										@click="() => selectField(field, header, index)"
									>
										{{ field.columnId }}
									</div>
								</div>
							</v-menu>
						</h4>
					</th>
				</tr>
			</template>
			<template #bottom></template>
		</v-data-table>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'MessagingListMapper',

		props: ['headers', 'dataPreview', 'tokens','isDialerType'],

		components: { },

		data: function () {
			return {
				messagingHeaders: [...this.headers],
				mapping: [],
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			selectField(field, header, index) {
				let mappedCol = {
					columnIndex: index,
					columnId: field.columnId,
				};
				let ix = this.mapping.findIndex((m) => m.columnIndex === index);
				if (ix > -1) {
					this.mapping.splice(ix, 1, mappedCol);
				} else {
					this.mapping.push(mappedCol);
				}
				header.menuOpen = false;
				this.$emit('change',this.mapping);
			},

			deSelectField(index) {
				let ix = this.mapping.findIndex((m) => m.columnIndex === index);
				if (ix > -1) {
					this.mapping.splice(ix, 1);
				}
				this.$emit('change',this.mapping);
			},
		},

		computed: {
			headersComputed: function () {
				let result = [...this.messagingHeaders];

				for (let i = 0; i < result.length; i++) {
					let mapping = this.mapping.find((m) => m.columnIndex === i);

					if (mapping) {
						result[i].columnId = mapping.columnId;
						result[i].mapped = true;
					} else {
						result[i].columnId = null;
						result[i].mapped = false;
					}
				}
				return result;
			},

			dialerFieldsFiltered: function () {
				let result = [...this.tokens.filter((t) => t !== 'CallerId')];

				if(this.isDialerType){
					result = result.filter((t) => t !== 'Phone' && t !== 'Zip');
				}

				result = result.map(r =>  ({columnId: r}))
						.filter((r) => this.mapping.findIndex((m) => m.columnId === r.columnId) === -1);

				result.sort((a,b) => a.columnId.localeCompare(b.columnId));
				return result
			},
		},
	});
</script>

<style scoped lang="scss"></style>
