<template>
  <div id="top-bar" class="mobile-nav">
    <div><a href="/"><img src="/images/logo.png" style="max-width: 150px" /></a></div>
    <div class="icons-container">
			<div class="pointer top-button" @click="toggleNotifications">
				<p-icon class="pointer">notifications_active</p-icon>
				<div class="top-umi" v-if="$store.getters.unreadNotificationCount"><div>{{$store.getters.unreadNotificationCount}}</div></div>
			</div>
        <ham-button :drawer="drawer" @click="drawer = !drawer"></ham-button>
    </div>
</div>
<v-navigation-drawer
        v-model="drawer"
        location="right"
        class="mobile-drawer"          
      >
      <v-list v-model:opened="open">
        <v-list-item prepend-icon="monitoring" title="Dashboard"  to="/dashboard"> </v-list-item>
				<v-list-item prepend-icon="campaign" title="Campaigns"  to="/campaigns"> </v-list-item>
        <v-list-item prepend-icon="fingerprint" title="Identities"  to="/identities"></v-list-item>
        <v-list-item prepend-icon="phone_in_talk" title="Pool numbers"  to="/pools">
        </v-list-item>
        <v-list-item prepend-icon="search" title="Number utility"  to="/utilities">
        </v-list-item>
        <v-list-group value="Settings">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="settings"
              title="Settings"
            ></v-list-item>
          </template>
              <v-list-item prepend-icon="corporate_fare" title="Organization" @click="editOrganization()"> </v-list-item>
              <v-list-item prepend-icon="workspaces" title="Workspaces" to="/workspaces"> </v-list-item>
              <v-list-item prepend-icon="supervisor_account" title="Team" to="/team"> </v-list-item>
							<v-list-item prepend-icon="verified_user" title="Watchtower settings" to="/watch-tower"> </v-list-item>
							<v-list-item prepend-icon="chart" title="Usage reports" to="/usage"> </v-list-item>
							<v-list-item prepend-icon="request_quote" title="Billing profile" to="/billing-profile"> </v-list-item>
          </v-list-group>
        <v-list-group value="Users">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="person"
              title="Profile"
            ></v-list-item>
          </template>
              <v-list-item prepend-icon="person" title="My Profile" to="/profile"> </v-list-item>
              <!-- <v-list-item prepend-icon="lock" title="Reset password" to="/profile"> </v-list-item> -->
        </v-list-group>
        <v-list-item prepend-icon="logout" title="Log Out" @click="logout()">
        </v-list-item>
      </v-list>
      </v-navigation-drawer>
</template>
<script>
import {defineComponent} from 'vue';
import HamButton from './HamButton.vue';
import AuthService from "@/modules/auth/AuthService";
import ConfirmModal from "@/components/ConfirmModal";
import Organization from "@/modules/organization/Organization";
export default defineComponent({
  data: () => ({
      drawer: false,
      group: null,
      open: ['Users'],
  }),
  watch: {
      group() {
          this.drawer = false;
      },
  },
  methods: {
    editOrganization: function(){
      this.drawer = !this.drawer;
      this.$store.state.globalModalController.openModal(Organization,{});
    },
    toggleNotifications: function(){
        this.$store.commit('toggleNotifications');
    },
    logout: function(){
      this.drawer = !this.drawer;
      let binding = {
        bodyText: 'Confirm log out?'
      }
      this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
        if(res){
          return AuthService.logout();
        }
      })
    }
  },
  components: { HamButton }
})
</script>
<style lang="scss">
.mobile-nav {
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 5px;
  background-color: var(--maz-color-bg-lighter);
  z-index: 10;
  .icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
  }
}
.mobile-drawer {
  height: 100% !important;
  margin-top: 55px;
}
.v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .icon {
    margin-right: 10px;
  }
}
.v-list-item__content {
  display: flex;
}
</style>