<template>
	<v-container fluid class="pl-0" style="max-width: 100%;">
		<v-row dense>
			<v-col md="6" cols="12">
				<div class="column-format gap-2 pr-4">
					<v-text-field
							ref="name"
							label="Campaign name"
							v-model="lCampaign.name"
							:rules="required"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							:spellcheck="true"
					></v-text-field>
					<maz-phone-number-input
							ref="forwardingNumber"
							:translations="{ phoneInput: { placeholder: 'Inbound forwarding', example: '' } }"
							:only-countries="['US', 'CA']"
							v-model="lCampaign.forwardingNumber"
					>
					</maz-phone-number-input>
					<v-autocomplete
							density="compact"
							hide-details
							variant="outlined"
							label="Default mapping template"
							:items="mappingTemplates"
							v-model="lCampaign.defaultMappingTemplate"
							item-value="id"
							item-title="name"
							persistent-placeholder
							clearable
					></v-autocomplete>
					<v-select
							v-if="workspace.dialerProviderType === 'PREM' && workspace.allowFullCoverageCampaigns"
							v-model="lCampaign.forceFullCoverageSentry"
							label="Force full coverage Sentry"
							:items="enableOptions"
							item-title="label"
							item-value="value"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
					>
					</v-select>
					<v-select
							v-model="lCampaign.enableFullSentryReplacement"
							label="Replace all numbers at 60 days"
							:items="enableOptions"
							item-title="label"
							item-value="value"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
					>
					</v-select>
					<v-select
							v-model="lCampaign.enableRealtimeNumberProvisioning"
							label="Enable real time number provisioning"
							:items="enableOptions"
							item-title="label"
							item-value="value"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
					>
					</v-select>
					<v-text-field
						v-if="campaign.isManaged"
						ref="name"
						label="Reimport Interval - Range: 0-45 days "
						v-model="lCampaign.reimportInterval"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						:spellcheck="true"
						@blur="reimportIntervalBounds"
					></v-text-field>
					<v-btn elevation="0" style="min-height: 40px" color="secondary" @click="save()">Save</v-btn>
				</div>
			</v-col>
			<v-col md="6" cols="12">
					<div class="column-format gap-2 text-left">
						<div class="row-format gap-2">
							<div class="text-no-wrap">Status:</div>
							<div class="font-weight-bold row-format align-center">
								<div>{{ campaign.status }}</div>
								<p-icon class="pointer pl-2" color="gray_70" @click="$emit('delete')" :size="20">delete</p-icon>
							</div>
						</div>
						<div class="row-format gap-2">
							<div class="text-no-wrap">ID:</div>
							<div class="font-weight-bold">{{ campaign.id }}</div>
						</div>
						<div class="row-format gap-2">
							<div class="text-no-wrap">CNAM identity:</div>
							<div class="font-weight-bold">{{ campaign.businessIdentityName }}</div>
						</div>
						<div class="row-format gap-2">
							<div class="text-no-wrap">Dialer campaign:</div>
							<div class="font-weight-bold">{{ campaign.dialerCampaignName }}</div>
						</div>
						<div class="row-format gap-2">
							<div class="text-no-wrap">Assigned lists:</div>
							<div class="font-weight-bold text-left text-no-wrap truncate" style="max-width: 300px" v-tippy="{content:dialerCampaignLists}">{{ dialerCampaignLists }}</div>
						</div>
						<div class="row-format gap-2">
							<div class="text-no-wrap">Max numbers per region:</div>
							<div class="font-weight-bold text-left text-no-wrap truncate" style="max-width: 300px">{{ campaign.fullCoverageNumberCount }}</div>
						</div>

					</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="column-format gap-2" style="width: 100%">
				<lead-chart style="max-height: 300px; max-width: 100%" :campaign-stats="campaignStats" v-if="campaignStats"></lead-chart>
				<sentry-chart style="max-height: 300px; max-width: 100%" :campaign-stats="campaignStats" v-if="campaignStats"></sentry-chart>
				<sms-chart style="max-height: 300px; max-width: 100%" :campaign-stats="campaignStats" v-if="campaignStats"></sms-chart>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import PDateTime from '@/modules/utils/PDateTime';
	import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
	import MappingTemplateService from "@/modules/campaigns/MappingTemplateService";
	import DialerService from "@/modules/dialers/DialerService";
	import LeadChart from "@/modules/campaigns/charts/LeadChart";
	import SentryChart from "@/modules/campaigns/charts/SentryChart";
	import PIcon from "@/components/PIcon";
	import SmsChart from "@/modules/campaigns/charts/SmsChart";

	export default defineComponent({
		name: 'CampaignOverview',

		props: ['workspaceId','campaign','campaignStats'],

		components: {SmsChart, PIcon, SentryChart, LeadChart, MazPhoneNumberInput},

		data: function () {
			return {
				dialerService: new DialerService(),
				mappingTemplateService: new MappingTemplateService(),
				mappingTemplates: [],
				dialerCampaign: null,
				DateTime: PDateTime,
				lCampaign: JSON.parse(JSON.stringify(this.campaign)),
				required: [(v) => !!v || 'Campaign name is required'],
				enableOptions: [
					{ label: 'Yes', value: true },
					{ label: 'No', value: false },
				],
			};
		},

		mounted() {
			this.getMappingTemplates();
			this.getDialerCampaign(this.campaign.dialerCampaignId);
		},


		beforeUnmount() {},

		methods: {
			getDialerCampaign(campaignId) {
				this.dialerService.getCampaign(this.workspaceId, campaignId).then((res) => {
					this.dialerCampaign = res.data;
				});
			},

			getMappingTemplates() {
				this.mappingTemplateService.getMappingTemplates(this.workspaceId).then((res) => {
					this.mappingTemplates.replace(res.data);
				});
			},

			async save() {
				if (!this.lCampaign.name) {
					this.$store.commit('error', 'Campaign name is required');
					this.$refs.name.focus();
					return false;
				} else if (!this.lCampaign.forwardingNumber) {
					this.$store.commit('error', 'Forwarding number is required');
					return false;
				}
				this.$emit('save', this.lCampaign);
			},

			reimportIntervalBounds: function() {
				if(this.lCampaign.reimportInterval > 45) {
					this.lCampaign.reimportInterval = 45
				}
				else if (this.lCampaign.reimportInterval < 0) {
					this.lCampaign.reimportInterval = 0
				}
			}
		},

		computed: {
			workspace: function(){
				return this.$store.state.workspaces.find(w => w.id === this.workspaceId);
			},

			dialerCampaignLists: function () {
				if (this.dialerCampaign) {
					return this.dialerCampaign.lists.map((d) => d.name).join(', ');
				} else {
					return null;
				}
			},
		},
	});
</script>

<style scoped lang="scss"></style>
