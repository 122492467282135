import Profile from "@/modules/profile/Profile";

export default [
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        title: 'Profile',
        props: true
    },
]