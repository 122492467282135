import HttpClient from "@/services/HttpClient";

export default class BusinessRecordService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getBusinessRecordList(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/businessRecords`);
    }

    async getBusinessRecord(workspaceId,id) {
        return await this.client.get(`/workspaces/${workspaceId}/businessRecords/${id}`);
    }

   async createBusinessRecord(workspaceId,businessRecord) {
        return await this.client.post(`/workspaces/${workspaceId}/businessRecords`,businessRecord);
    }

    async updateBusinessRecord(workspaceId,id,businessRecord) {
        return await this.client.put(`/workspaces/${workspaceId}/businessRecords/${id}`,businessRecord);
    }

    async deleteBusinessRecord(workspaceId,id) {
        return await this.client.delete(`/workspaces/${workspaceId}/businessRecords/${id}`);
    }

}