<template>
	<v-container class="ma-0 pb-3 pt-0">
		<v-row class="ma-0 pa-0">
			<v-col cols="2" align="left" class="row-format align-center ma-0 pa-0">
				<div class="text-left">{{ label }}</div>
			</v-col>
			<v-col cols="9" align="left" class="column-format gap-2 ma-0 pa-0">
				<div v-for="(period, index) in periods" :key="index">
					<div class="row-format gap-2 align-center">
						<v-autocomplete
							style="flex: 1 1 auto"
							:items="enabledTimeIncrements"
							v-model="period.start"
							hide-details
							density="compact"
							variant="outlined"
							item-value="value"
							item-title="text"
						></v-autocomplete>

						<v-autocomplete
							style="flex: 1 1 auto"
							:items="enabledTimeIncrements"
							v-model="period.end"
							hide-details
							density="compact"
							variant="outlined"
							item-value="value"
							item-title="text"
						></v-autocomplete>
						<p-icon class="pointer" @click="periods.splice(index, 1)" :size="24" color="gray_70">delete</p-icon>
					</div>
					<div v-if="period.start > period.end" style="color: rgb(var(--v-theme-error))" class="font-14">
						Choose an end time later than the start time.
					</div>
				</div>
				<div v-if="hasOverlaps" style="color: rgb(var(--v-theme-error))" class="font-14">
					Times currently overlap. Please correct.
				</div>
				<div v-if="!periods.length" class="mt-2" style="color: rgb(var(--v-gray_80)); text-align: center">No calls</div>
			</v-col>
			<v-col cols="1" align="right" class="column-format centered  ma-0 pa-0">
				<p-icon @click="addPeriod" class="pointer" :size="24" color="gray_70">add</p-icon>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import timeIncrements from '@/assets/data/time-increments.json';
	import timeIncrements24 from '@/assets/data/time-increments-24.json';
	import { DateTime } from 'luxon';
	import { defineComponent } from 'vue';
	import PIcon from '@/components/PIcon';

	export default defineComponent({
		name: 'PeriodConfiguration',

		props: ['label', 'modelValue'],

		components: { PIcon },

		data: function () {
			return {
				periods: this.modelValue,
				timeIncrements: timeIncrements,
				timeIncrements24: timeIncrements24,
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			addPeriod: function () {
				if (!this.periods.length) {
					this.periods.push({ start: 480, end: 1260 });
				} else {
					let last = this.periods[this.periods.length - 1];
					let start = last.end + 30;
					let end = start + 90;

					if (end >= 1440) {
						end = 1439;
					}

					if (start < 1440) {
						this.periods.push({ start: start, end: end });
					} else {
						this.$store.commit('error', 'Unable to add timeslot - current end time is too late.');
					}
				}
			},
		},

		watch: {
			periods: {
				deep: true,
				handler: function () {
					this.$emit('update:modelValue', this.periods);
				},
			},
		},

		computed: {
			enabledTimeIncrements: function () {
				let time = DateTime.now().toLocaleString(DateTime.TIME_SIMPLE);
				if (time.toUpperCase().includes('AM') || time.toUpperCase().includes('PM')) {
					return this.timeIncrements;
				} else {
					return this.timeIncrements24;
				}
			},

			hasOverlaps: function () {
				let previousEnd = 0;
				for (let p of this.periods) {
					if (p.start < previousEnd) {
						return true;
					} else {
						previousEnd = p.end;
					}
				}
				return false;
			},
		},
	});
</script>

<style scoped lang="scss"></style>
