import HttpClient from "@/services/HttpClient";

export default class WorkspaceService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getWorkspaces() {
        return await this.client.get(`/workspaces`);
    }

    async getWorkspace(id) {
        return await this.client.get(`/workspaces/${id}`);
    }

    async updateWorkspace(id,updateRequest){
        return await this.client.put(`/workspaces/${id}`,updateRequest);
    }

    async initializeWorkspace(name){
        return await this.client.post(`/workspaces?workspaceName=${encodeURIComponent(name)}`);
    }

    async deactivateWorkspace(id){
        return await this.client.delete(`/workspaces/${id}`);
    }
}