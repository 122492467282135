<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header
					name="Campaigns"
					add-label="Create campaign"
					@add-new="addCampaign()"
					:workspace-selector="true"
				>
					<template v-slot:filter>
						<v-text-field v-model="textFilter" density="compact" hide-details placeholder="Filter..." variant="outlined" style="min-width: 150px"></v-text-field>
						<v-select v-model="filter" :items="['Active','Inactive','All']" density="compact" hide-details variant="outlined"></v-select>
					</template>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					id="campaign-table"
					:items="filteredCampaigns"
					v-model:items-per-page="itemsPerPage"
					:headers="displayedHeaders"
					class="pointer"
					fixed-header
					height="calc(100vh - 150px)"
					:style="`max-width: calc(${width} - 40px)`"
					@click:row="editCampaign"
				>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import CampaignService from '@/modules/campaigns/CampaignService';
	import { useDisplay } from 'vuetify';
	import CreateCampaign from "@/modules/campaigns/detail/CreateCampaign";
	import WorkspaceService from '@/modules/workspaces/WorkspaceService';

	export default defineComponent({
		name: 'CampaignList',

		props: [],

		components: { ModuleHeader },

		data: function () {
			return {
				campaigns: [],
				campaignService: new CampaignService(),
				workspaceService: new WorkspaceService(),
				itemsPerPage: -1,
				filter: 'Active',
				textFilter: null,
				dialerProviderType: null,
				headers: [
					{ title: 'Name', key: 'name', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'Dialer campaign', key: 'dialerCampaignName', align: 'start' },
					{ title: 'CNAM Identity', key: 'businessIdentityName', align: 'start' },
					{ title: 'Forward to', key: 'forwardingNumber', align: 'start' },
				],
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.getCampaigns);
			this.$store.state.eventBus.$on(`o-${this.orgId}.w-${this.workspaceId}`,this.handleWorkspaceEvent);
			this.getCampaigns();
			this.getDialerProviderType();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.getCampaigns);
			this.$store.state.eventBus.$on(`o-${this.orgId}.w-${this.workspaceId}`,this.handleWorkspaceEvent);
		},

		methods: {
			async getCampaigns() {
				let result = await this.campaignService.getCampaigns(this.workspaceId);
				this.campaigns.replace(result.data);
			},

			async getDialerProviderType() {
				let result = await this.workspaceService.getWorkspace(this.workspaceId);
				this.dialerProviderType = result.data.dialerProviderType;
			},

			addCampaign: function () {
				this.$store.state.globalModalController.openModal(CreateCampaign).then((res) => {
					if(res){
						this.$router.push(`/campaigns/${res.id}`);
					}
				})
			},

			editCampaign: function (event, row) {
				this.$router.push(`/campaigns/${row.item.id}`);
			},

			handleWorkspaceEvent: function(event){
				if(event.userMetadata === 'Campaign'){
					let campaign = event.message;
					let ix = this.campaigns.findIndex(c => c.id === campaign.id);
					if(ix > -1){
						this.campaigns.splice(ix,1,campaign);
					}
				}
			}
		},

		computed: {
			width: () => {
				return `${useDisplay().width.value}px`;
			},
			orgId: function(){
				return this.$store.getters.getOrgId;
			},

			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			filteredCampaigns: function(){
				let result = [... this.campaigns];

				if(this.filter === 'Active'){
					result = result.filter(c => c.status !== 'DEACTIVATED' && c.status !== 'DELETED');
				}else if(this.filter === 'Inactive'){
					result =  result.filter(c => c.status === 'DEACTIVATED' || c.status === 'DELETED');
				}

				if(this.textFilter){
					return result.filter(c => c.name.toLowerCase().includes(this.textFilter.toLowerCase()));
				}else{
					return result;
				}
			},

			isPinpointDialer: function(){
				return this.dialerProviderType === 'CONNECT'
			},

			displayedHeaders: function() {
				if (this.isPinpointDialer) {
					return this.headers.filter(header => header.key !== 'dialerCampaignName');
				} else {
					return this.headers;
				}
			},
		},
	});
</script>

<style scoped lang="scss">
	#campaign-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
