import HttpClient from '@/services/HttpClient';

export default class CampaignListService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getAllLists(workspaceId,pageNumber=0,maxPerPage=50) {
		return await this.client.get(`/workspaces/${workspaceId}/campaigns/lists?pageNumber=${pageNumber}&maxPerPage=${maxPerPage}`);
	}

	async getCampaignLists(workspaceId, campaignId) {
		return await this.client.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/lists`);
	}

	async getCampaignList(workspaceId, campaignId, listId) {
		return await this.client.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/lists/${listId}`);
	}

	async deleteCampaignList(workspaceId, campaignId, listId) {
		return await this.client.delete(`/workspaces/${workspaceId}/campaigns/${campaignId}/lists/${listId}`);
	}

	async uploadCampaignList(workspaceId, campaignId, file) {
		let formData = new FormData();
		formData.append('file', file);

		return await this.client.post(`/workspaces/${workspaceId}/campaigns/${campaignId}/lists`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	async importList(workspaceId, campaignId, importRequest){
		return await this.client.post(`/workspaces/${workspaceId}/campaigns/${campaignId}/importList`, importRequest);
	}

	async downloadProcessedFile(workspaceId,campaignId,listId){
		return this.client
			.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/lists/${listId}/download`, {
				responseType: 'blob',
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	async uploadAndProcessFile(workspaceId,campaignId,file,listProcessRequest){
		let formData = new FormData();
		formData.append('file', file);
		formData.append('request',JSON.stringify(listProcessRequest));

		return await this.client.post(`/workspaces/${workspaceId}/campaigns/${campaignId}/lists/uploadAndProcess`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	async getCampaignListPreview(workspaceId, campaignId, listId) {
		return await this.client.get(`/workspaces/${workspaceId}/campaigns/${campaignId}/lists/${listId}/preview`);
	}

	async createCampaignListProcess(workspaceId, campaignId, listId, list) {
		return await this.client.post(`/workspaces/${workspaceId}/campaigns/${campaignId}/lists/${listId}/process`, list);
	}
}
