<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<module-header
					name="Pool numbers"
					add-label="Search numbers"
					@add-new="searchPoolNumbers()"
					:workspace-selector="true"
				>
					<template v-slot:filter>
						<v-text-field density="compact" placeholder="555-555-1212" hide-details variant="outlined" v-model="search" style="min-width: 200px"></v-text-field>
					</template>
					<template v-slot:additional>
						<v-btn @click="downloadPoolNumbers" icon elevation="0" style="max-height: 40px" color="gray_50" v-tippy="{content: 'Download all numbers in workspace'}"><v-icon>download</v-icon></v-btn>
					</template>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					id="pool-table"
					:items="fixedUpNumbers"
					v-model:items-per-page="itemsPerPage"
					:headers="headers"
					class="pointer"
					fixed-header
					:height="numbers.length ? 'calc(100vh - 150px)' : ''"
					:style="`max-width: calc(${width} - 40px)`"
				>
					<template v-slot:[`item.number`]="{ item }"><span :class="item.status === 'AGING' ? 'aging' : ''">{{item.number}}</span></template>
					<template v-slot:[`item.status`]="{ item }"><span :class="item.status === 'AGING' ? 'aging' : ''">{{item.status}}</span></template>
					<template v-slot:[`item.campaignName`]="{ item }"><span :class="item.status === 'AGING' ? 'aging' : ''" @click.stop="openCampaign(item)">{{item.campaignName}}</span></template>
					<template v-slot:[`item.usageCount`]="{ item }"><span :class="item.status === 'AGING' ? 'aging' : ''">{{item.usageCount}}</span></template>
					<template v-slot:[`item.riskLevel`]="{ item }"><span :class="item.status === 'AGING' ? 'aging' : ''">{{item.riskLevel}}</span></template>
					<template v-slot:[`item.created`]="{ item }"><span :class="item.status === 'AGING' ? 'aging' : ''">{{ DateTime.fromISO(item.created).toLocaleString(DateTime.DATE_FULL) }}</span></template>
				</v-data-table>

				<div class="row-format centered mt-5 font-14" v-if="numbers.length === 0">
					<div v-if="searchComplete">No numbers found starting with {{search}}</div>
					<div v-else>Please enter at least 3 digits of the number you are looking for.</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import ModuleHeader from '@/components/ModuleHeader';
	import PDateTime from "@/modules/utils/PDateTime";
	import CampaignPoolService from "@/modules/pools/CampaignPoolService";
	import CampaignService from "@/modules/campaigns/CampaignService";
	import { useDisplay } from 'vuetify';

	export default defineComponent({
		name: 'PoolNumberList',

		props: [],

		components: { ModuleHeader },

		data: function () {
			return {
				DateTime: PDateTime,
				numbers: [],
				campaigns: [],
				search: null,
				searchComplete: false,
				campaignService: new CampaignService(),
				campaignPoolService: new CampaignPoolService(),
				itemsPerPage: -1,
				headers: [
					{ title: 'Number', key: 'number', align: 'start' },
					{ title: 'Campaign', key: 'campaignName', align: 'start' },
					{ title: 'Status', key: 'status', align: 'start' },
					{ title: 'Created', key: 'created', align: 'start' },
					{ title: 'Usages', key: 'usageCount', align: 'start' },
					{ title: 'SPAM', key: 'riskLevel', align: 'start' },
				],
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workspace-changed', this.init);
			this.init();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workspace-changed', this.init);
		},

		methods: {
			openCampaign: function(item){
				this.$router.push(`/campaigns/${item.campaignId}`);
			},

			async init(){
				this.clearPoolNumbers();
				let result = await this.campaignService.getCampaigns(this.workspaceId);
				this.campaigns.replace(result.data);
			},

			clearPoolNumbers: function(){
				this.numbers.splice(0,this.numbers.length);
			},

			downloadPoolNumbers: function(){
				this.$store.commit('startLoading');
				this.campaignPoolService.downloadPoolNumbers(this.workspaceId).then((res) => {
					let fileURL = window.URL.createObjectURL(res.data);
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute(
							'download',
							'Pool-Download.csv'
					);
					document.body.appendChild(fileLink);
					fileLink.click();
				}).finally(() => {
					this.$store.commit('stopLoading');
				})
			},

			async searchPoolNumbers() {
				let result = await this.campaignPoolService.searchCampaignPool(this.workspaceId,this.search);
				this.numbers.replace(result.data);
				this.searchComplete = true;
			},
		},

		computed: {
			width: () => {
				return `${useDisplay().width.value}px`;
			},
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			fixedUpNumbers: function(){
				let result = [... this.numbers]

				result.forEach(n => {
					let campaign = this.campaigns.find(c => c.id === n.campaignId);
					if(campaign){
						n.campaignName = campaign.name;
					}
				});

				return result;
			}
		},
	});
</script>

<style lang="scss">
	.aging {
		opacity: 0.4;
	}
</style>

<style scoped lang="scss">
	#pool-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
