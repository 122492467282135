<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="row-format gap-2">
				<module-header name="Usage report"></module-header>
				<v-btn v-if="isSuperAdmin" class="super-action" style="min-height: 40px" @click="addAdjustment()"
					>+Add adjustment</v-btn
				>
				<v-select
					v-if="workspaces"
					class="mx-2"
					style="max-width: 250px"
					:items="workspaceList"
					v-model="workspaceId"
					item-value="value"
					item-title="text"
					hide-details
					variant="outlined"
					density="compact"
				></v-select>
				<v-select
					style="max-width: 250px"
					:items="dateOptions"
					v-model="datePeriod"
					item-value="value"
					item-title="text"
					hide-details
					variant="outlined"
					density="compact"
				></v-select>
			</v-col>
		</v-row>
		<v-row style="max-height: calc(100vh - 130px); overflow-y: auto">
			<v-col cols="12">
				<v-data-table
					v-if="usageReport"
					id="usage-table"
					:items="processedUsageItems"
					v-model:items-per-page="itemsPerPage"
					:headers="headers"
					class="pointer"
					fixed-header
				>
					<template v-slot:[`item.description`]="{ item }">
						<div class="row-format gap-2 description align-center">
							<div>{{ item.description }}</div>
							<p-icon
								@click="confirmDeleteAdjustment(item)"
								v-if="isSuperAdmin && item.type === 'Adjustment'"
								class="delete-icon material-symbols-outlined"
								color="gray_70"
								:size="20"
								>delete</p-icon
							>
						</div>
					</template>
					<template v-slot:[`item.qty`]="{ item }">{{ formatNumber(item.qty,0, false) }}</template>
					<template v-slot:[`item.rate`]="{ item }">{{ formatNumber(item.rate) }}</template>
					<template v-slot:[`item.total`]="{ item }">{{ formatNumber(item.qty * item.rate, 2) }}</template>
					<template v-slot:[`body.append`]>
						<tr>
							<td class="font-weight-bold text-right" colspan="3">Total:</td>
							<td class="font-weight-bold text-right">{{ formatNumber(totalPrice, 2) }}</td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
	<v-dialog v-model="showAddAdjustment" :persistent="false" style="max-width: 500px">
		<v-container style="background-color: rgb(var(--v-theme-white)); border-radius: 8px" class="pa-4">
			<v-row>
				<v-col cols="12" class="column-format gap-3">
					<div class="font-18">Adjustment for: {{ DateTime.fromISO(adjustment.period).toFormat('MMMM yyyy') }}</div>
					<v-text-field
						label="Description"
						v-model="adjustment.description"
						persistent-placeholder
						density="compact"
						variant="outlined"
						hide-details
					>
					</v-text-field>
					<div class="row-format gap-3">
						<v-text-field
							label="Quantity"
							v-model="adjustment.units"
							persistent-placeholder
							density="compact"
							variant="outlined"
							hide-details
							type="number"
						></v-text-field>
						<v-text-field
							label="Rate"
							prefix="$"
							v-model="adjustment.rate"
							persistent-placeholder
							density="compact"
							variant="outlined"
							hide-details
							type="number"
						>
						</v-text-field>
					</div>
					<div class="row-format">
						<v-btn class="secondary-action" @click="showAddAdjustment = false">Cancel</v-btn>
						<v-btn class="ml-auto primary-action" @click="createNewAdjustment">Add adjustment</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
import {defineComponent} from 'vue';
import ModuleHeader from '@/components/ModuleHeader';
import FilterHelpers from '@/modules/utils/FilterHelpers';
import OrganizationService from '@/modules/organization/OrganizationService';
import WorkspaceService from '@/modules/workspaces/WorkspaceService';
import {DateTime} from 'luxon';
import ConfirmModal from '@/components/ConfirmModal';

export default defineComponent({
		name: 'BillingUsageReport',

		props: [],

		components: { ModuleHeader },

		data: function () {
			return {
				workspaceService: new WorkspaceService(),
				workspaces: [],
				workspaceId: null,
				DateTime: DateTime,
				showAddAdjustment: false,
				adjustment: null,
				orgService: new OrganizationService(),
				usageReport: [],
				datePeriod: 'this-month',
				itemsPerPage: -1,
				dateOptions: FilterHelpers.getDateOptions([
					'this-month',
					'last-month',
					'minus-2-month',
					'minus-3-month',
					'minus-4-month',
					'minus-5-month',
					'minus-6-month',
				]),
				headers: [
					{ title: 'Description', key: 'description', align: 'start' },
					{ title: 'Quantity', key: 'qty', align: 'end' },
					{ title: 'Rate', key: 'rate', align: 'end' },
					{ title: 'Total', key: 'total', align: 'end' },
				],
			};
		},

		mounted() {
			this.getBillableUsage();
			this.getWorkspaces();
		},

		beforeUnmount() {},

		methods: {
			async getBillableUsage() {
				this.$store.commit('startLoading');
				this.usageReport.splice(0);

				let lineItems = await this.orgService.getBillableUsage(this.earliest.month, this.earliest.year);
				this.usageReport.replace(lineItems);
				this.$store.commit('stopLoading');
			},

			addAdjustment: function () {
				this.adjustment = {
					period: this.earliest,
					description: '',
					units: 1,
					rate: 0,
				};
				this.showAddAdjustment = true;
			},

			createNewAdjustment: function () {
				try {
					this.$store.commit('startLoading');
					this.orgService.addAdjustment(this.adjustment);
					this.showAddAdjustment = false;
					this.getBillableUsage();
				} catch (err) {
					console.log(err);
					this.$store.commit('stopLoading');
				}
			},

			confirmDeleteAdjustment: function (adjustment) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Do you want to delete this usage adjustment?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.orgService.deleteAdjustment(adjustment.id).then(() => this.getBillableUsage());
					}
				});
			},

			formatNumber(amount = 0, minimumFractionDigits = 4, currencySign = true, currency = 'USD') {
				try {
					amount = amount.toString().replace(/[^-0-9.]/g, '');
					if (isNaN(amount)) amount = 0;

					if (currencySign) {
						return new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: currency,
							currencySign: 'accounting',
							maximumFractionDigits: minimumFractionDigits,
						}).format(amount);
					} else {
						return new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: currency,
							maximumFractionDigits: minimumFractionDigits,
						})
							.format(amount)
							.replace(/[^0-9.,]/g, '');
					}
				} catch (err) {
					console.log('Error formatting currency', err);
					return amount;
				}
			},

			async getWorkspaces() {
				this.workspaces.splice(0);
				let workspaces = await this.workspaceService.getWorkspaces();
				this.workspaces.replace(workspaces.data);
			},
		},

		watch: {
			datePeriod: function () {
				this.getBillableUsage();
			},
		},

		computed: {
			processedUsageItems: function () {
				let result = [];

				if (this.workspaceId) {
					for (let i = 0; i < this.usageReport.length; i++) {
						let wsUsage = this.usageReport[i].workspaceUsage.find((w) => w.workspaceId === this.workspaceId);
						if (wsUsage) {
							let item = JSON.parse(JSON.stringify(this.usageReport[i]));
							item.qty = wsUsage.qty;
							item.description = wsUsage.description ? wsUsage.description : this.usageReport[i].description;
							result.push(item);
						}
					}
				} else {
					result.push(...this.usageReport);
				}

				return result;
			},

			isSuperAdmin: function () {
				return this.$store.state.user.userType === 'SuperAdmin';
			},

			earliest: function () {
				let helper = FilterHelpers.getEarliestAndLatest(this.datePeriod, false, true);
				return helper.earliest;
			},

			latest: function () {
				let helper = FilterHelpers.getEarliestAndLatest(this.datePeriod, false, true);
				return helper.latest;
			},

			workspaceList: function () {
				let ret = this.workspaces.filter((ix) => ix.status === 'ACTIVE');
				ret = ret.map((ix) => ({ text: ix.name, value: ix.id }));
				ret.unshift({ text: 'All Workspaces', value: null });
				return ret;
			},

			totalPrice: function () {
				return this.processedUsageItems.reduce((accumulator, item) => {
					return accumulator + (item.qty * item.rate);
				}, 0);
			},
		},
	});
</script>

<style scoped lang="scss">
	#usage-table {
		.v-data-table__tr {
			.delete-icon {
				visibility: hidden;
			}
			&:hover {
				.delete-icon {
					visibility: visible;
				}
			}
		}
	}

	#usage-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
