import HttpClient from "@/services/HttpClient";

export default class RndConnectionService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getRndConnectionList(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/rnd`);
    }

    async getRndConnection(workspaceId,id) {
        return await this.client.get(`/workspaces/${workspaceId}/rnd/${id}`);
    }

   async createRndConnection(workspaceId,rnd) {
        return await this.client.post(`/workspaces/${workspaceId}/rnd`,rnd);
    }

    async updateRndConnection(workspaceId,id,rnd) {
        return await this.client.put(`/workspaces/${workspaceId}/rnd/${id}`,rnd);
    }

    async deleteRndConnection(workspaceId,id) {
        return await this.client.delete(`/workspaces/${workspaceId}/rnd/${id}`);
    }

}