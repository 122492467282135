<template>
	<div class="mt-0" style="max-width: 95%">
		<v-container fluid class="ma-0 pa-0 text-left font-14">
			<v-row>
				<v-col cols="12" class="column-format gap-3">
					<div>
							Your auto response message will be sent to any lead who replies to your outbound calls with a text message.  The auto response message should be informational and direct the lead to call you to complete the transaction.
					</div>
				</v-col>
			</v-row>
			<!--v-row v-if="!messagingConfig.enabled">
				<v-col cols="12">
					<div class="column-format gap-2">
						<v-btn elevation="0" style="min-height: 40px" color="primary" @click="enableMessaging()"
							>Activate messaging services</v-btn
						>
					</div>
				</v-col>
			</v-row-->
			<v-row>
				<v-col cols="12" class="column-format gap-3 mt-3">
					<!--v-autocomplete
							density="compact"
							hide-details
							variant="outlined"
							label="A2P Campaign"
							:items="a2pCampaigns"
							v-model="messagingConfig.appToPersonCampaignId"
							item-value="id"
							item-title="name"
							persistent-placeholder
							clearable
					></v-autocomplete>
					<v-textarea
							ref="smsResponse"
							rows="3"
							label="Outgoing message"
							v-model="messagingConfig.outgoingMessage"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							:spellcheck="true"
					></v-textarea-->
					<v-textarea
							ref="smsResponse"
							rows="3"
							label="Auto response"
							v-model="messagingConfig.autoResponder"
							density="compact"
							variant="outlined"
							hide-details
							persistent-placeholder
							:spellcheck="true"
					></v-textarea>
					<!--div class="column-format pa-2" style="border: 1px solid rgb(var(--v-theme-gray_50)); border-radius: 4px">
						<div class="font-14 pb-2">Lead mapping tokens</div>
						<div class="row-format gap-1 flex-wrap">
							<v-chip v-for="token in tokens" :key="token" color="secondary">{{token}}</v-chip>
						</div>
					</div-->

					<v-btn elevation="0" style="min-height: 40px" color="secondary" @click="save()">Save</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import AppToPersonCampaignService from "@/modules/campaigns/AppToPersonCampaignService";

	export default defineComponent({
		name: 'CampaignMessaging',

		props: ['workspaceId', 'campaign'],

		components: {},

		data: function () {
			return {
				a2pCampaigns: [],
				appToPersonService: new AppToPersonCampaignService(),
				messagingConfig: { ...this.campaign.messagingConfig },
			};
		},

		mounted() {
			this.getA2PCampaigns();
		},

		beforeUnmount() {},

		methods: {
			getA2PCampaigns: function(){
				this.appToPersonService.getAppToPersonCampaigns(this.workspaceId).then((res) => {
					this.a2pCampaigns.replace(res.data);
				})
			},

			enableMessaging: function () {
				this.messagingConfig.enabled = true;
				this.$emit('save', this.messagingConfig);
			},

			save: function(){
				if(!this.messagingConfig.appToPersonCampaignId){
					this.messagingConfig.enabled = false;
				}
				this.$emit('save', this.messagingConfig);
			},

			getTags: function(searchValue){
				let result = [];
				const regex = /{{(.*?)}}/gm;

				let m;
				while ((m = regex.exec(searchValue)) !== null) {
					// This is necessary to avoid infinite loops with zero-width matches
					if (m.index === regex.lastIndex) {
						regex.lastIndex++;
					}

					// The result can be accessed through the `m`-variable.
					m.forEach((match, groupIndex) => {
						if(groupIndex ===1) {
							result.push(match);
						}
					});
				}

				return result;
			}
		},

		watch: {
			tokens: function(){
				this.messagingConfig.tokens.replace(this.tokens);
			},
		},

		computed: {
			appToPersonCampaignName: function(){
				if(this.messagingConfig.appToPersonCampaignId){
					let a2p = this.a2pCampaigns.find(c => c.id === this.messagingConfig.appToPersonCampaignId);
					return a2p ? a2p.name : null;
				}else{
					return null;
				}
			},

			tokens: function(){
				let tokens = ['Phone','Zip','CallerId'];
				tokens.push(... this.getTags(this.messagingConfig.outgoingMessage));
				tokens.push(... this.getTags(this.messagingConfig.autoResponder));
				return tokens.filter((item, index) => tokens.indexOf(item) === index);
			}
		},
	});
</script>

<style scoped lang="scss"></style>
