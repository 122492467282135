import CnamSpam from "@/modules/utilities/CnamSpam";

export default [
    {
        path: '/utilities',
        name: 'cnam',
        component: CnamSpam,
        title: 'Cname',
        props: true
    },
]