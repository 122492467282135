import HttpClient from '@/services/HttpClient';

export default class MappingTemplateService {
	client;

	constructor() {
		this.client = new HttpClient();
	}

	async getMappingTemplates(workspaceId) {
		return await this.client.get(`/workspaces/${workspaceId}/mappingTemplates`);
	}

	async getMappingTemplate(workspaceId,id) {
		return await this.client.get(`/workspaces/${workspaceId}/mappingTemplates/${id}`);
	}

	async deleteMappingTemplate(workspaceId,id) {
		return await this.client.delete(`/workspaces/${workspaceId}/mappingTemplates/${id}`);
	}

}
