import HttpClient from "@/services/HttpClient";

export default class CampaignService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getCampaigns(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/campaigns`);
    }

    async getCampaign(workspaceId,id) {
        return await this.client.get(`/workspaces/${workspaceId}/campaigns/${id}`);
    }

    async getCampaignStats(workspaceId,id,timeZone = "America/New_York") {
        return await this.client.get(`/workspaces/${workspaceId}/campaigns/${id}/stats?timeZone=${timeZone}`);
    }

    async getAllCampaignStats(workspaceId,timeZone = "America/New_York") {
        return await this.client.get(`/workspaces/${workspaceId}/campaigns/stats?timeZone=${timeZone}`);
    }

    async createCampaign(workspaceId,campaign){
        return await this.client.post(`/workspaces/${workspaceId}/campaigns`,campaign);
    }

    async updateCampaign(workspaceId,id,campaign){
        return await this.client.post(`/workspaces/${workspaceId}/campaigns/${id}`,campaign);
    }

    async deactivateCampaign(workspaceId,id){
        return await this.client.delete(`/workspaces/${workspaceId}/campaigns/${id}`);
    }
}