import HttpClient from "@/services/HttpClient";

export default class AppToPersonCampaignService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getAppToPersonCampaigns(workspaceId) {
        return await this.client.get(`/workspaces/${workspaceId}/appToPersonCampaigns`);
    }
}