<template>
	<div id="ring-settings">
		<div class="column-format gap-3">
			<div><img class="ring-logo" alt="RingCentral logo" src="/images/vendors/ringCentral.png" /></div>
			<v-text-field
				label="Permanent Authentication Token"
				v-model="ringSettings.permanentToken"
				density="compact"
				variant="outlined"
				hide-details
				persistent-placeholder
			></v-text-field>
			<v-text-field
				label="Sub Account ID"
				v-model.number="ringSettings.subAccountId"
				type="number"
				density="compact"
				variant="outlined"
				hide-details
				persistent-placeholder
			></v-text-field>

			<div v-if="ringSettings.connected && !error">
				<v-autocomplete class="mb-2" :items="mappingFields" label="Reserved Unique ID Field" v-model="ringSettings.uniqueIdFieldName" item-value="columnId" item-title="columnName"  density="compact" variant="outlined" hide-details persistent-placeholder></v-autocomplete>
				<v-icon color="success">check</v-icon> Successfully connected
			</div>
			<div v-else class="font-gray_70">Please update authentication token and sub account ID to continue.</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import DialerService from '@/modules/dialers/DialerService';
	import RingService from '@/modules/dialers/ring/RingService';

	export default defineComponent({
		name: 'RingSettings',

		props: ['workspace'],

		components: {},

		data: function () {
			return {
				dialerService: new DialerService(),
				ringService: new RingService(),
				ringSettings: { ...this.workspace.dialerProviderSettings },
				campaigns: [],
				error: false,
				mappingFields: [
					'externId',
					'gateKeeper',
					'auxData1',
					'auxData2',
					'auxData3',
					'auxData4',
					'auxData5',
				],
			};
		},

		mounted() {
			this.checkSettings();
		},

		beforeUnmount() {},

		methods: {
			checkSettings: function () {
				if (this.ringSettings.connected) {
					this.getCampaigns();
				}
			},

			async save() {
				try {
					if(!this.ringSettings.uniqueIdFieldName) {
						this.$store.commit('error','Reserved Unique ID mapping is required');
						return false;
					}
					let result = await this.ringService.connect(this.workspace.id, this.ringSettings);
					return result.data;
				}catch(err){
					this.error = true;
					console.log(err);
					this.$store.commit('error','Error connecting RING account');
				}
			},

			async getCampaigns() {
				try {
					let result = await this.dialerService.getCampaigns(this.workspace.id, false);
					this.campaigns.replace(result.data);
				}catch(err){
					this.error = true;
					console.log(err);
					this.$store.commit('error','Error connecting RING account');
				}
			},
		},

		computed: {},

		watch: {
			workspace: function (val) {
				this.ringSettings = { ...val.dialerProviderSettings };
				this.checkSettings();
			},
		},
	});
</script>

<style scoped lang="scss">
	#ring-settings {
		text-align: left;
		.ring-logo {
			max-width: 200px;
		}
	}
</style>
