<template>
	<div class="column-format gap-3">
		<v-select
			v-model="item.connectionId"
			:items="computedList"
			item-value="id"
			item-title="label"
			hide-details
			density="compact"
			variant="outlined"
			placeholder="RND Connection"
			@update:modelValue="updated()"
		></v-select>
		<div class="row-format gap-2">
			<v-select
					style="flex: 1 1 0"
					v-model="item.dateTokenScope"
					variant="outlined"
					density="compact"
					hide-details
					persistent-placeholder
					label="Contact date scope"
					:items="scopesAndProps"
					:return-object="false"
					item-value="value"
					item-title="label"
					@update:modelValue="resetProperty(); updated()"
			>
			</v-select>
			<v-text-field
					style="flex: 1 1 0"
					v-if="item.dateTokenScope && getPropsForScope(item.dateTokenScope) == null"
					v-model="item.dateTokenProp"
					variant="outlined"
					density="compact"
					hide-details
					persistent-placeholder
					@update:modelValue="updated()"
					label="Contact date prop"
			></v-text-field>
			<v-select
					style="flex: 1 1 0"
					v-else
					v-model="item.dateTokenProp"
					variant="outlined"
					density="compact"
					hide-details
					persistent-placeholder
					label="Contact date prop"
					@update:modelValue="updated()"
					:items="getPropsForScope(item.dateTokenScope)"
			></v-select>
		</div>
		<div class="row-format gap-2 align-center">
		<v-text-field
				v-model="item.defaultLookBackDays"
				variant="outlined"
				density="compact"
				number
				hide-details
				persistent-placeholder
				@update:modelValue="updated()"
				label="Default look back days"
		></v-text-field>
			<v-icon color="secondary" v-tippy content="If no valid date is found in the token, a relative date will be passed to the RND endpoint">info</v-icon>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import RndConnectionService from "@/modules/watchTower/RndService";
	import RecordSetService from "@/modules/recordSets/RecordSetService";

	export default defineComponent({
		name: 'ReassignedNumberDatabase',

		props: ['node'],

		components: {

		},

		data: function () {
			return {
				item: { ...this.node },
				rndConnectionService: new RndConnectionService(),
				recordSetService: new RecordSetService(),
				connections: [],
				recordSets: [],
			};
		},

		mounted() {
			this.getRndConnections();
			this.getRecordSets();
		},

		beforeUnmount() {},

		methods: {
			async getRecordSets() {
				let result = await this.recordSetService.getRecordSets(this.workspaceId);
				this.recordSets.replace(result.data.filter((r) => r.status === 'ACTIVE'));
			},

			getRndConnections: function(){
				this.rndConnectionService.getRndConnectionList(this.workspaceId).then((res) => {
					this.connections.replace(res.data);
				})
			},

			updated: function () {
				if(this.item.connectionId){
					let rndConnection = this.connections.find(c => c.id === this.item.connectionId);
					this.item.label = rndConnection.companyNumber + ' - ' + rndConnection.companyName;
				}else{
					this.item.label = '';
				}
				this.$emit('updated', this.item);
			},

			getPropsForScope: function (scopeValue) {
				let scope = this.scopesAndProps.find((s) => s.value === scopeValue);
				if (scope) {
					return scope.properties;
				} else {
					return [];
				}
			},

			resetProperty: function () {
				this.item.dateTokenProp = null;
			},
		},

		computed: {
			workspaceId: function(){
				return this.$store.state.selectedWorkspace;
			},

			scopesAndProps: function () {
				let result = [];


				result.push({ label: 'Meta data', value: 'Meta', properties: null });

				this.recordSets.forEach((r) => {
					result.push({
						label: r.name,
						value: `RecordSet:${r.id}`,
						properties: r.attributes.map((a) => a.name),
					});
				});

				return result;
			},

			computedList: function(){
				let result = [];
				this.connections.forEach(c => {
					result.push({
						id: c.id,
						label: c.companyNumber + ' - ' + c.companyName
					})
				});
				return result;
			}
		},
	});
</script>

<style scoped lang="scss"></style>
