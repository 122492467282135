import Team from "@/modules/team/Team";

export default [
    {
        path: '/team',
        name: 'team',
        component: Team,
        title: 'Team',
        props: true
    },
]