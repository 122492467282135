<template>
	<div>
		<div v-if="rate.tiers.length === 1"  class="row-format align-center">
			<div class="text-left font-14" style="width: 150px; max-width: 200px; min-width: 200px">{{label}}</div>
			<v-text-field
					number
					hide-details
					variant="outlined"
					density="compact"
					persistent-placeholder
					prefix="$"
					:disabled="disabled"
					v-model="rate.tiers[0].rate"
					@change="handleChange"
					oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
			></v-text-field>
		</div>
		<div v-else class="column-format gap-2 text-left">
			<div class="brand-medium" v-if="label">{{label}}</div>
			<div  class="row-format align-center" v-if="enableMinimum">
				<div class="text-left font-14" style="width: 200px; max-width: 200px; min-width: 200px">Minimum spend</div>
				<v-text-field
						number
						hide-details
						variant="outlined"
						density="compact"
						persistent-placeholder
						prefix="$"
						v-model="rate.minimum"
						@change="handleChange"
						:disabled="disabled"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
				></v-text-field>
			</div>
			<div v-for="(tier,index) in rate.tiers" :key="index" class="row-format align-center">
				<div class="text-left font-14" style="width: 200px; max-width: 200px; min-width: 200px">{{getLabel(tier.min)}} - {{getLabel(tier.max)}}</div>
				<v-text-field
						number
						hide-details
						variant="outlined"
						density="compact"
						persistent-placeholder
						prefix="$"
						v-model="tier.rate"
						@change="handleChange"
						:disabled="disabled"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
				></v-text-field>
			</div>
		</div>
	</div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'Rate',

	props: ['label','modelValue','disabled','enableMinimum'],

	components: {},

	data: function () {
		return {
			rate: this.modelValue
		};
	},

	mounted() {
	},

	beforeUnmount() {
	},

	methods: {
		handleChange: function(){
			this.$emit('update:modelValue',this.rate);
		},

		getLabel: function(val){
			if(val === 2147483647){ //Java Integer.MAX_VALUE
				return 'Max'
			}else{
				return val.toLocaleString();
			}
		}
	},

	computed: {},
});
</script>

<style scoped lang="scss"></style>
